var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "rounded-lg bg-boxed px-8 py-10",
      class: {
        "flex items-start flex-col md:flex-row": _vm.backgroundImage[0],
      },
    },
    [
      _vm.backgroundImage[0]
        ? _c("img", {
            directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
            staticClass:
              "md:mr-12 mx-auto md:mx-0 mb-5 md:mb-0 rounded-full w-56 h-56",
            attrs: {
              "data-src": _vm.backgroundImage[0].publicUrl,
              alt: _vm.author,
              width: _vm.backgroundImage[0].properties.dimensions.width,
              height: _vm.backgroundImage[0].properties.dimensions.height,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", [
        _c(
          "p",
          {
            staticClass:
              "mt-10 font-bold italic relative w-5/6 mb-8 after:content-['\\201E'] after:not-italic after:text-[450px] after:font-quote after:block after:absolute after:bottom-10 after:-right-28 lg:after:-right-36 after:w-60 after:h-60 after:opacity-10 after:text-white after:pointer-events-none",
            class: _vm.smallText === 1 ? "text-2xl" : "text-2xl lg:text-4xl",
            attrs: { id: "text-primary" },
          },
          [_vm._v(_vm._s(_vm.bodytext))]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "text-lg text-white" }, [
          _vm._v(_vm._s(_vm.author)),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "text-quinary" }, [
          _vm._v(_vm._s(_vm.authorPosition)),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }