var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.categories.length > 1
        ? [
            _c("div", { staticClass: "text-lg" }, [
              _c("strong", [
                _vm._v(" " + _vm._s(_vm.$t("events.filter")) + " "),
              ]),
            ]),
            _vm._v(" "),
            _c("NewsCategories", {
              attrs: {
                categories: _vm.categories,
                store: "events",
                "show-all-link": true,
              },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "client-only",
        [
          _c("masonry-wall", {
            attrs: {
              items: _vm.filteredItemsArray,
              "ssr-columns": 4,
              "column-width": 300,
              gap: 16,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ item }) {
                  return [
                    _c("event-item", {
                      staticClass: "transition-all",
                      attrs: { content: item },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.areThereMore
        ? _c(
            "button",
            {
              staticClass: "btn-loadmore block md:max-w-[25%] mx-auto mt-10",
              on: {
                click: function ($event) {
                  return _vm.loadMore()
                },
              },
            },
            [_vm._v("\n    " + _vm._s(_vm.$t("loadMore")) + "\n  ")]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }