var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.head
        ? _c("t3-renderer", {
            attrs: { content: _vm.head, "in-container": true },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "lg:grid grid-cols-12 gap-10" }, [
        _c(
          "div",
          { staticClass: "col-span-4" },
          [
            _vm.left
              ? _c("t3-renderer", {
                  attrs: { content: _vm.left, "in-container": true },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-span-8" },
          [
            _vm.right
              ? _c("t3-renderer", {
                  attrs: { content: _vm.right, "in-container": true },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }