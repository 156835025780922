<template>
  <div>
    <t3-ce-header v-bind="$props" />
    <t3-html-parser :content="bodytext" />
    <div v-if="technoStackItems" class="mt-10 grid gap-3 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gab-4">
      <div
        v-for="(item, Key) in technoStackItems"
        :key="Key"
        class="col-span-1 m-auto w-full h-full"  
      >        
        <component
          :is="item.properties.link ? 't3-nav-link' : 'div'"
          :to="item.properties.link"
          class="effect p-5 sm:p-10 rounded-md h-full flex items-center justify-center"
          :class="{ 'lighten' : item.properties.lightenBackground == 1}"
        >
          <figure>
            <img
              v-lazy-load :data-src="item.publicUrl"
              :height="item.properties.dimensions.height"
              :width="item.properties.dimensions.width"
              :alt="item.properties.alternative || false"
              :title="item.properties.title || ''"
              class="max-h-24 w-auto"
            >
            <figcaption v-if="item.properties.description">
              {{ item.properties.description }}
            </figcaption>
          </figure>
        </component>        
      </div>
    </div>
  </div>
</template>
<script>
import baseCe from '../mixins/baseCe'
export default {
  name: 'T3CeSuresecureTechnoStack',
  extends: baseCe,
  props: {
    technoStackItems: {
      type: Array,
      required: true
    },
    enlargeImageOnClick: {
      type: Boolean,
      default: false
    },
    bodytext: {
      type: String,
      default: ''
    },
    lightenBackground: {
      type: Number,
      default: 0
    },
  }
}
</script>
<style lang="postcss" scoped>
  .effect {
    @apply transition-all duration-300 bg-white bg-opacity-10;       
    &.lighten {
      @apply bg-opacity-30;
    }
    &:hover {
      @apply bg-opacity-5;            
      &.lighten {
        @apply bg-opacity-80;
      }
    }
  }
</style>
