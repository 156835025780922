var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "lg:grid grid-cols-2 gap-x-10 mb-20",
      attrs: { id: "podcast-" + _vm.content.uid },
    },
    [
      _c("div", { staticClass: "col-span-1 h-[200px] mb-10" }, [
        _c("iframe", {
          directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
          attrs: {
            "data-src": _vm.content.podcast_link,
            width: "100%",
            height: "100%",
            frameborder: "0",
            scrolling: "no",
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "col-span-1",
          class: {
            "mt-[13px]": !_vm.content.teaserImage[0] && !_vm.content.media[0],
          },
        },
        [
          _vm.content.podcast_episode
            ? _c("ContentPartialsNewsCategoryBadge", {
                attrs: { label: _vm.content.podcast_episode },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("p", { staticClass: "text-grey text-xs" }, [
            _vm._v(
              _vm._s(
                _vm.$moment.unix(_vm.content.starttime).format("DD/MM/YYYY")
              )
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "h3 font-bold" }, [
            _vm._v(
              _vm._s(
                _vm.content.teaser_title
                  ? _vm.content.teaser_title
                  : _vm.content.title
              )
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "mb-5 text-grey" }, [
            _vm._v("\n      " + _vm._s(_vm.content.teaser_text) + "\n    "),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }