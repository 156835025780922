<template>
  <div>
    <p class="h6 font-medium">{{ $t('podcast') }}</p>
    <div class="grid md:flex grid-cols-1 sm:grid-cols-2 gap-5 mb-20">
      <a href="https://podcasts.apple.com/us/podcast/der-security-horst-it-security-to-go/id1567043544" target="_blank" class="shrink-0 h-12 border-2 border-quinary rounded-[60px] px-4 text-boxed flex items-center font-medium before:content-podcast-apple before:block before:h-7 before:w-7 before:mr-2 transition-all hover:text-white hover:border-primary hover:scale-105 hover:bg-primary">Apple Podcasts</a>
      <a href="https://open.spotify.com/show/38ro5pDX7ToVMA9w6RVyNM" target="_blank" class="shrink-0 h-12 border-2 border-quinary rounded-[60px] pl-3 pr-4 text-boxed flex items-center font-medium before:content-podcast-spotify before:block before:h-7 before:w-7 before:mr-2 transition-all hover:text-white hover:border-primary hover:scale-105 hover:bg-primary">Spotify</a>
      <a href="https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xNzc5NDI2LnJzcw" target="_blank" class="shrink-0 h-12 border-2 border-quinary rounded-[60px] px-4 text-boxed flex items-center font-medium before:content-podcast-google before:block before:h-7 before:w-7 before:mr-2 transition-all hover:text-white hover:border-primary hover:scale-105 hover:bg-primary">Google Podcasts</a>
      <a href="https://feeds.buzzsprout.com/1779426.rss" target="_blank" class="shrink-0 h-12 border-2 border-quinary rounded-[60px] px-4 text-boxed flex items-center font-medium before:content-podcast-rss before:block before:h-7 before:w-7 before:mr-2 transition-all hover:text-white hover:border-primary hover:scale-105 hover:bg-primary">RSS feed</a>
    </div>
    <p class="h2">{{ $t('all') }}&nbsp;<span class="text-primary">{{ $t('episodes') }}</span></p>
    <div v-for="(item, key) in items" :key="key">
      <podcast-item :content="item" />
    </div>
  </div>
</template>
<script>
import baseCe from '../mixins/baseCe'
import baseNews from '../mixins/baseNews'
import PodcastItem from '../partials/News/PodcastItem'
export default {
  name: 'T3CeSuresecureEventList',
  components: {
    PodcastItem
  },
  extends: baseCe,
  mixins: baseNews,
  props: {
    items: {
      type: Array,
      default: () => []
    },
    itemsCount: {
      type: Number,
      default: 0
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$route.hash) {
        setTimeout(() => {
          const el = document.querySelector(this.$route.hash);
          el && this.$gsap.to(window, 1, {scrollTo:{y:el, offsetY: () => {
            return  innerWidth < 1280 ?
                    innerWidth < 1024 ? 0 : 190
                    : 150
          }}});
        }, 100)
      }
    })
  }
}
</script>