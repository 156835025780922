var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "t3-ce-image", class: _vm.layout },
    [
      _vm.layout == "layout-1"
        ? _c(
            "h4",
            {
              staticClass:
                "relative py-3 text-xs font-medium uppercase cursor-pointer mb-0 sm:mb-5 lg:cursor-text",
            },
            [
              _c(
                "span",
                {
                  staticClass:
                    "flex flex-row justify-between items-center after:mt-2 after:mr-2 sm:block",
                },
                [_vm._v("\n        " + _vm._s(_vm.skyline) + "\n        ")]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("t3-ce-header", _vm._b({}, "t3-ce-header", _vm.$props, false)),
      _vm._v(" "),
      _c("t3-media-gallery", {
        attrs: {
          gallery: _vm.gallery,
          "enlarge-image-on-click": _vm.enlargeImageOnClick,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }