<template>
  <div class="relative" @mouseleave="item.children ? hideChildren() : ''">
    <nuxt-link
      class="block p-3 lg:px-2.5 xl:px-5 font-medium lg:text-xxs xl:text-xs text-white transition-all cursor-pointer hover:text-primary"
      :to="item.link"
      active-class="text-primary"
      :class="[
        isShowChildren ? 'open' : '',
        item.children ? 'btn-dropdown-toggle' : '',
      ]"
      @mouseenter.native="item.children ? showChildren() : ''"
    >
      <span>{{ item.title }}</span>
    </nuxt-link>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-class="opacity-0 transform scale-95"
      enter-to-class="opacity-100 transform scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-class="opacity-100 transform scale-100"
      leave-to-class="opacity-0 transform scale-95"
    >
      <div
        v-show="isShowChildren && item.children"
        aria-haspopup="true"
        class="absolute before:absolute before:inset-x-[-9999px] -right-10 -left-1 z-20 before:z-20 before:h-[3px] text-sm text-white before:content-[''] origin-top-left before:bg-primary lg:min-w-[calc(100%+60px)]"
      >
        <layout-nested-menu :source="item" />
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: [Array, Object],
      required: true,
    },
  },
  data() {
    return {
      isShowChildren: false,
    }
  },
  created() {
    this.$nuxt.$on('open-nav', () => {
      this.showChildren()
    })
    this.$nuxt.$on('close-nav', () => {
      this.hideChildren()
    })
  },
  beforeDestroy() {
    this.$nuxt.$off('open-nav')
    this.$nuxt.$off('close-nav')
  },
  methods: {
    showChildren() {
      this.isShowChildren = true
      this.$nuxt.$emit('layout-blur-content')
    },
    hideChildren() {
      this.isShowChildren = false
      this.$nuxt.$emit('layout-unblur-content')
    },
  },
}
</script>
