import Vue from 'vue'

// Backend Layouts
import T3BlDefault from '~/layouts/backend/T3BlDefault'
import T3BlBlog from '~/layouts/backend/T3BlBlog'
import T3BlModal from '~/layouts/backend/T3BlModal'
import T3BlEvent from '~/layouts/backend/T3BlEvent'

const components = {
  T3BlDefault,
  T3BlBlog,
  T3BlModal,
  T3BlEvent
}

export default ({ app }) => {
  Object.keys(components).forEach((key) => {
    Vue.component(key, components[key])
  })
}
