<template>
  <div>
    <t3-ce-header v-bind="$props" />
    <div class="text-white">
      <template v-if="data.filterOptions.categories.length > 1">
        <div class="text-lg">
          <strong> {{ $t('joboffers.filter') }} </strong>
        </div>
        <Categories
          :categories="data.filterOptions.categories"
          store="joboffers"
          :show-all-link="true"
        />
      </template>
      <div class="frame-width80">
        <div class="frame-container">
          <div class="mt-16">
            <strong>{{ filteredItemsArray.length }}</strong>
            {{ $t(`result${filteredItemsArray.length !== 1 ? 's' : ''}`) }}
          </div>
          <div
            v-for="(item, key) in filteredItemsArray"
            :key="key"
            class="rounded-md p-6 px-8 my-4 mb-5 text-white bg-boxed md:flex md:flex-row md:justify-between md:items-center"
          >
            <div class="w-full max-w-3xl">
              <h4>
                <nuxt-link :to="data.jobOfferDetails + '/' + item.slug">
                  {{ item.title }} {{ item.gender }}
                </nuxt-link>
              </h4>
              <div class="md:flex md:flex-row md:gap-8">
                <div v-if="item.experience" class="flex flex-row items-center mb-3 mt-6 md:my-0">
                  <div
                    class="bg-white/20 rounded-full w-8 h-8 p-1.5 mr-2"
                  >
                    <IconCalendar class="mx-auto" />
                  </div>
                  <strong class="text-xs">{{ item.experience }}</strong>
                </div>
                <div class="flex flex-row items-center mb-3 md:mb-0">
                  <div
                    class="bg-white/20 rounded-full w-8 h-8 p-1.5 mr-2"
                  >
                    <IconMapMarker class="mx-auto" />
                  </div>
                  <strong class="text-xs">{{ item.location.name }}</strong>
                </div>
                <div class="flex flex-row items-center mb-6 md:mb-0">
                  <div
                    class="bg-white/20 rounded-full w-8 h-8 p-1.5 mr-2"
                  >
                    <IconClock class="mx-auto" />
                  </div>
                  <strong class="text-xs">{{ item.employment.name }}</strong>
                </div>
              </div>
            </div>
            <div>
              <nuxt-link
                :to="data.jobOfferDetails + '/' + item.slug"
                class="link-arrow text-white text-sm whitespace-nowrap md:ml-6 hover:text-primary"
              >
                {{ $t('joboffers.applyNow') }}
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import baseCe from '../mixins/baseCe'
import Categories from '../partials/Joboffers/Categories'
import IconCalendar from '~/assets/icons/calendar.svg?inline'
import IconMapMarker from '~/assets/icons/map-marker.svg?inline'
import IconClock from '~/assets/icons/clock.svg?inline'
export default {
  name: 'T3CeSuresecurecareerJobofferlist',
  components: {
    Categories,
    IconCalendar,
    IconMapMarker,
    IconClock
  },
  extends: baseCe,
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      store: 'joboffers',
      filteredItemsArray: []
    }
  },
  computed: {
    filter() {
      return this.$store.state.categories[this.store].filter
    }
  },
  watch: {
    filter() {
      this.filteredItemsArray = this.filterItems()
    },
  },
  created() {
    this.filteredItemsArray = this.data.jobOffers
  },
  methods: {
    filterItems () {
      const tempArray = []
      const filter = this.filter

      if (filter.length === 0) {
        return this.data.jobOffers
      }

      this.data.jobOffers.forEach(function (item, key) {
        item.categories.forEach(function (category) {
          if (!tempArray.includes(item) && filter.includes(category.uid)) {
            tempArray.push(item)
          }
        })
      })
      return tempArray
    },
  }
}
</script>
