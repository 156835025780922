var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "button",
      {
        staticClass:
          "group text-white rounded-full hover:shadow-lg hover:shadow-neutral-600 transition-shadow duration-300 bg-primary",
        on: {
          click: function ($event) {
            return _vm.scrollToTop()
          },
        },
      },
      [
        _c("arrow-top-icon", {
          staticClass:
            "m-2 w-7 h-7 transition-all duration-300 group-hover:-translate-y-1 lg:w-9 lg:h-9",
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }