var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "overflow-hidden" }, [
    _c("div", { staticClass: "relative my-12" }, [
      _c(
        "span",
        {
          staticClass:
            "block w-16 h-16 rounded-full bg-tertiary absolute z-10 -top-8 overflow-hidden",
        },
        [
          _c("img", {
            directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
            staticClass: "absolute bottom-0 left-2.5 w-12 h-12 z-10",
            attrs: { "data-src": require("assets/images/flow-start.svg") },
          }),
        ]
      ),
      _vm._v(" "),
      _c("canvas", {
        ref: "canvas",
        staticClass: "absolute mx-auto z-0 pointer-events-none transition-all",
        attrs: { id: `flowCanvas${_vm.id}` },
      }),
      _vm._v(" "),
      _c("div", { ref: "effect", staticClass: "effect" }, [
        _c("div", { ref: "magic" }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { ref: "flowContainer", staticClass: "mb-10" },
        _vm._l(_vm.flowItems, function (flowItem, key) {
          return _c(
            "div",
            {
              key: key,
              ref: "items",
              refInFor: true,
              staticClass: "relative z-20 flow-item mx-auto transition-all",
              class: [
                flowItem.content.variant,
                flowItem.content.imageAsIcon === 1 ? "icon" : "",
              ],
            },
            [
              _c(
                "span",
                {
                  staticClass:
                    "bg-primary rounded-md p-3 pr-7 pl-3 text-white text-xs uppercase absolute -top-6 left-1/2 -translate-x-1/2 inline-flex items-center font-medium",
                },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "text-[10px] inline-block h-5 w-5 center text-center rounded-full bg-black/30 mr-3",
                    },
                    [_vm._v(_vm._s(key + 1))]
                  ),
                  _vm._v(
                    "\n          " +
                      _vm._s(flowItem.content.header) +
                      "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  ref: "fade",
                  refInFor: true,
                  staticClass: "content lg:flex items-center gap-4",
                  class: key % 2 ? "lg:flex-row" : "lg:flex-row-reverse",
                },
                [
                  flowItem.content.imageAsIcon === 0
                    ? _c(
                        "div",
                        { staticClass: "image w-full md:w-[300px] shrink-0" },
                        [
                          flowItem.content.image.length > 0
                            ? _c("img", {
                                directives: [
                                  { name: "lazy-load", rawName: "v-lazy-load" },
                                ],
                                ref: "images",
                                refInFor: true,
                                staticClass:
                                  "w-full mb-4 md:mb-0 transition-all",
                                attrs: {
                                  "data-not-lazy": "",
                                  "data-src":
                                    flowItem.content.image[0].publicUrl,
                                },
                                on: {
                                  load: function ($event) {
                                    return _vm.drawLine()
                                  },
                                },
                              })
                            : _vm._e(),
                        ]
                      )
                    : _c("div", { staticClass: "mx-auto lg:m-0 my-4 image" }, [
                        flowItem.content.image.length > 0
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "bg-tertiary w-20 h-20 mx-auto lg:mx-0 rounded-full relative",
                              },
                              [
                                _c("InlineSvg", {
                                  staticClass:
                                    "absolute z-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full max-w-[60px]",
                                  attrs: {
                                    image: flowItem.content.image[0],
                                    title: flowItem.content.header,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                  _vm._v(" "),
                  _c("t3-html-parser", {
                    staticClass:
                      "text-white text-base font-normal bodytext transition-all duration-300",
                    attrs: { content: flowItem.content.bodytext },
                  }),
                ],
                1
              ),
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.link
        ? _c(
            "a",
            {
              staticClass:
                "btn-primary btn-lg absolute bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 md:min-w-[500px] text-center z-20",
              attrs: { href: _vm.link },
            },
            [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.buttonText || _vm.$t("readMore")) +
                  "\n      "
              ),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }