import Vue from 'vue'

// Content Elements
import T3Dynamic from '~/layouts/backend/T3Dynamic'
import T3HtmlParser from '~/components/utilities/T3HtmlParser.vue'
import T3Frame from '~/components/content/T3Frame'
import T3MediaFile from '~/components/content/T3MediaFile'
import T3Renderer from '~/components/content/T3Renderer'
import T3NavLink from '~typo3/components/T3NavLink/T3NavLink.vue'
import T3CeDebug from '~typo3/components/T3Debug/T3Debug.vue'
import T3CeDefault from '~typo3/components/T3CeDefault/T3CeDefault.js'
import T3CeHeader from '~/components/content/elements/T3CeHeader'
import T3CeHtml from '~/components/content/elements/T3CeHtml'
import T3CeImage from '~/components/content/elements/T3CeImage'
import T3CeMenuPages from '~/components/content/elements/T3CeMenuPages'
import T3CeText from '~/components/content/elements/T3CeText'
import T3CeTextmedia from '~/components/content/elements/T3CeTextmedia'
import T3CeTextpic from '~/components/content/elements/T3CeTextpic'
import T3CeSuresecureSocialIcon from '~/components/content/elements/T3CeSuresecureSocialIcon'
import T3CeUploads from '~/components/content/elements/T3CeUploads'
import T3CeSuresecureAccordion from '~/components/content/elements/T3CeSuresecureAccordion'
import T3CeSuresecureBlogList from '~/components/content/elements/T3CeSuresecureBlogList'
import T3CeSuresecurecareerJobofferdetails from '~/components/content/elements/T3CeSuresecurecareerJobofferdetails'
import T3CeSuresecurecareerJoboffercontact from '~/components/content/elements/T3CeSuresecurecareerJoboffercontact'
import T3CeSuresecurecareerJobofferlist from '~/components/content/elements/T3CeSuresecurecareerJobofferlist'
import T3CeSuresecureCarousel from '~/components/content/elements/T3CeSuresecureCarousel'
import T3CeSuresecureColsEightyTwenty from '~/components/content/elements/T3CeSuresecureColsEightyTwenty'
import T3CeSuresecureColsTwentyEighty from '~/components/content/elements/T3CeSuresecureColsTwentyEighty'
import T3CeSuresecureEventList from '~/components/content/elements/T3CeSuresecureEventList'
import T3CeSuresecureFlow from '~/components/content/elements/T3CeSuresecureFlow'
import T3CeSuresecureNewsList from '~/components/content/elements/T3CeSuresecureNewsList'
import T3CeSuresecurePodcastList from '~/components/content/elements/T3CeSuresecurePodcastList'
import T3CeSuresecurePriceBox from '~/components/content/elements/T3CeSuresecurePriceBox'
import T3CeSuresecureTeaserBox from '~/components/content/elements/T3CeSuresecureTeaserBox'
import T3CeSuresecureQuote from '~/components/content/elements/T3CeSuresecureQuote'
import T3CeSuresecureEmergency from '~/components/content/elements/T3CeSuresecureEmergency'
import T3CeSuresecureForms from '~/components/content/elements/T3CeSuresecureForms'
import T3CeSuresecureContactPerson from '~/components/content/elements/T3CeSuresecureContactPerson'
import T3CeSuresecureBenefitCommunication from '~/components/content/elements/T3CeSuresecureBenefitCommunication'
import T3CeSuresecureTab from '~/components/content/elements/T3CeSuresecureTab'
import T3CeSuresecureHero from '~/components/content/elements/T3CeSuresecureHero'
import T3CeSuresecurePdfDownload from '~/components/content/elements/T3CeSuresecurePdfDownload'
import T3CeSuresecureTeaserSelectedPages from '~/components/content/elements/T3CeSuresecureTeaserSelectedPages'
import T3CeSuresecureTeaserSubpages from '~/components/content/elements/T3CeSuresecureTeaserSubpages'
import T3CeSuresecureTechnoStack from '~/components/content/elements/T3CeSuresecureTechnoStack'
import T3CeSuresecurequestionaryCatalogdetails from '~/components/content/elements/T3CeSuresecurequestionaryCatalogdetails'

const components = {
  T3Debug: T3CeDebug,
  T3HtmlParser,
  T3Dynamic,
  T3Frame,
  T3MediaFile,
  T3Renderer,
  T3NavLink,
  T3CeDebug,
  T3CeDefault,
  T3CeHeader,
  T3CeHtml,
  T3CeImage,
  T3CeMenuPages,
  T3CeMenuSubpages: T3CeMenuPages,
  T3CeText,
  T3CeTextmedia,
  T3CeTextpic,
  T3CeSuresecureSocialIcon,
  T3CeUploads,
  T3CeSuresecureAccordion,
  T3CeSuresecureBlogList,
  T3CeSuresecurecareerJobofferdetails,
  T3CeSuresecurecareerJoboffercontact,
  T3CeSuresecurecareerJobofferlist,
  T3CeSuresecureCarousel,
  T3CeSuresecureColsEightyTwenty,
  T3CeSuresecureColsTwentyEighty,
  T3CeSuresecureEventList,
  T3CeSuresecureFlow,
  T3CeSuresecureNewsList,
  T3CeSuresecurePodcastList,
  T3CeSuresecurePriceBox,
  T3CeSuresecureQuote,
  T3CeSuresecureEmergency,
  T3CeSuresecureForms,
  T3CeSuresecureContactPerson,
  T3CeSuresecureBenefitCommunication,
  T3CeSuresecureTab,
  T3CeSuresecureHero,
  T3CeSuresecurePdfDownload,
  T3CeSuresecureTeaserSelectedPages,
  T3CeSuresecureTeaserSubpages,
  T3CeSuresecureTechnoStack,
  T3CeSuresecurequestionaryCatalogdetails,
  T3CeSuresecureTeaserBox
}

export default ({ app }) => {
  Object.keys(components).forEach((key) => {
    Vue.component(key, components[key])
  })
}
