<template>
  <div class="bg-boxed relative px-7 pt-16 pb-7 rounded-t-lg">
    <div v-if="backgroundImage[0]">
      <img
        v-lazy-load :data-src="backgroundImage[0].publicUrl"
        :width="backgroundImage[0].properties.dimensions.width"
        :height="backgroundImage[0].properties.dimensions.height"
        class="absolute -top-16 left-2/4 z-10 -translate-x-1/2 max-w-[100px]"
      >
      <span class="w-28 h-28 rounded-full bg-boxed block absolute -top-12 left-2/4 -translate-x-1/2"></span>
    </div>
    <t3-html-parser :content="bodytext" />
    <nuxt-link v-if="link" :to="link" class="btn btn-lg btn-primary w-full text-center">
      <template v-if="buttonText">
        {{ buttonText }}
      </template>
      <template v-else>
        {{ $t('readMore') }}
      </template>
    </nuxt-link>
  </div>
</template>
<script>
import baseCe from '../mixins/baseCe'
export default {
  name: 'T3CeSuresecureBenefitCommunication',
  extends: baseCe,
  props: {
    backgroundImage: {
      type: Array,
      default: () => []
    },
    bodytext: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: ''
    }
  }
}
</script>