var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "t3-ce-textpic" },
    [
      _c(
        "t3-media-gallery",
        {
          class: {
            "decor !gap-16": _vm.applyDecor == 1,
            overflow: _vm.imageOverflow == 1,
          },
          attrs: {
            gallery: _vm.gallery,
            "enlarge-image-on-click": _vm.enlargeImageOnClick,
          },
        },
        [
          _c("t3-ce-header", _vm._b({}, "t3-ce-header", _vm.$props, false)),
          _vm._v(" "),
          _c("t3-html-parser", { attrs: { content: _vm.bodytext } }),
          _vm._v(" "),
          _vm.noticeText
            ? _c(
                "div",
                {
                  staticClass:
                    "absolute top-0 -left-5 -translate-x-full text-[14px] font-medium rounded-t-[5px] rounded-bl-[5px] bg-primary px-3 before:content-[''] before:bg-primary before:w-[9px] before:h-[9px] before:absolute before:bottom-[-9px] before:right-0 after:content-[''] after:bg-secondary after:rounded-tr-full after:w-[10px] after:h-[10px] after:absolute after:bottom-[-10px] after:right-0",
                },
                [_vm._v(_vm._s(_vm.noticeText))]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }