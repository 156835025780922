<template>
  <div>
    <div id="content-wrap" class="min-h-screen content-wrap overflow-x-hidden">
      <t3-renderer :content="content.colPos0" />
    </div>
    <layout-footer-content
      class="mb-5"
      :col90="content.colPos90"
      :col91="content.colPos91"
      :col92="content.colPos92"
      :col93="content.colPos93"
      :col94="content.colPos94"
      :col95="content.colPos95"
      :col101="content.colPos101"
      :col102="content.colPos102"
    />
  </div>
</template>
<script>
export default {
  name: 'T3BlDefault',
  props: {
    content: {
      type: [Object, Array],
      required: true,
    },
  },
}
</script>
