<template>
  <div class="relative rounded-md bg-boxed border-t-[5px] border-t-danger px-10 py-12 md:pt-16">
    <div class="absolute -top-7 md:-top-10 h-14 md:h-20 w-14 md:w-20 rounded-full text-center bg-danger">
      <ExtinguisherIcon class="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 inline-block h-8 md:h-12" />
    </div>
    <t3-ce-header v-bind="$props" />
    <t3-html-parser :content="bodytext" />
    <div class="text-danger font-bold text-2xl mt-6 md:mt-8 flex items-center">
      <div class="relative h-7 lg:h-10 w-7 lg:w-10 rounded-full text-center bg-danger mr-3 shrink-0">
        <PhoneIcon class="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 inline-block h-4 lg:h-6" />
      </div>
      {{ phone }}
    </div>
  </div>
</template>
<script>
import baseCe from '../mixins/baseCe'
import ExtinguisherIcon from '~/assets/icons/extinguisher-outline-white.svg?inline'
import PhoneIcon from '~/assets/icons/phone-outline-white.svg?inline'
export default {
  name: 'T3CeSuresecureEmergency',
  components: {
    ExtinguisherIcon,
    PhoneIcon
  },
  extends: baseCe,
  props: {    
    bodytext: {
      type: String,
      default: ''
    },
    phone: {
      type: String,
      default: ''
    }
  }
}
</script>