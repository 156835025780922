var render = function render(_c, _vm) {
  return _vm.props.file.publicUrl
    ? _c(
        "iframe",
        _vm._b(
          {
            directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
            staticClass: "t3-ce-media-video t3-ce-media-youtube",
            class: [_vm.data.class, _vm.data.staticClass],
            attrs: {
              id: "ytplayer",
              width: _vm.props.file.properties.dimensions.width || 640,
              height: _vm.props.file.properties.dimensions.height || 360,
              "data-src": _vm.props.file.publicUrl,
              type: "text/html",
              frameborder: "0",
            },
          },
          "iframe",
          _vm.data.attrs,
          false
        )
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }