var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "rounded-lg bg-boxed px-8 py-10 when-nested",
      class: { "flex items-start flex-col md:flex-row": _vm.image },
    },
    [
      _vm.image
        ? _c("img", {
            directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
            staticClass:
              "md:mr-12 mx-auto md:mx-0 mb-5 md:mb-0 rounded-full w-56 h-56",
            attrs: {
              "data-src": _vm.image.publicUrl,
              alt: [_vm.firstName, _vm.lastName],
              width: _vm.image.properties.dimensions.width,
              height: _vm.image.properties.dimensions.height,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", [
        _c(
          "p",
          { staticClass: "h3 uppercase text-white" },
          [
            _vm.contacts[0].salutation == "mr"
              ? [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("yourContactPerson.mr")) +
                      "\n      "
                  ),
                ]
              : [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("yourContactPerson.ms")) +
                      "\n      "
                  ),
                ],
          ],
          2
        ),
        _vm._v(" "),
        _c("p", { staticClass: "h4 text-primary mb-0" }, [
          _vm._v(_vm._s(_vm.firstName) + " " + _vm._s(_vm.lastName)),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "text-white mb-8" }, [
          _vm._v(_vm._s(_vm.contacts[0].position)),
        ]),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "flex flex-col lg:flex-row gap-10 text-white" },
          [
            _c("li", { staticClass: "flex gap-5 items-center font-medium" }, [
              _c(
                "div",
                {
                  staticClass:
                    "bg-primary rounded-full w-12 h-12 p-2 text-center",
                },
                [_c("IconPhone")],
                1
              ),
              _vm._v(_vm._s(_vm.contacts[0].phone)),
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "flex gap-5 items-center font-medium" }, [
              _c(
                "div",
                {
                  staticClass:
                    "bg-primary rounded-full w-12 h-12 p-2 text-center",
                },
                [_c("IconMail")],
                1
              ),
              _vm._v(_vm._s(_vm.contacts[0].email)),
            ]),
            _vm._v(" "),
            _vm.contacts[0].link
              ? _c(
                  "li",
                  { staticClass: "flex gap-5 items-center font-medium" },
                  [
                    _c(
                      "a",
                      {
                        staticClass:
                          "btn btn-primary btn-lg block w-auto text-center",
                        attrs: { href: _vm.contacts[0].link },
                      },
                      [
                        _vm.contacts[0].button_text
                          ? [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.contacts[0].button_text) +
                                  "\n                "
                              ),
                            ]
                          : [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.$t("makeappointment")) +
                                  "\n                "
                              ),
                            ],
                      ],
                      2
                    ),
                  ]
                )
              : _vm._e(),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }