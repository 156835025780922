<template>
  <div class="relative">
    <div class="lg:w-3/5 lg:pt-40 pb-12 lg:pb-24 relative z-10">
      <t3-ce-header v-bind="carouselItem.content" style="hyphens:auto;" />
      <t3-html-parser :content="carouselItem.content.bodytext" class="mb-9 lg:mb-16" />
      <nuxt-link v-if="carouselItem.content.link" :to="carouselItem.content.link" class="btn btn-lg" :class="carouselItem.content.buttonStyle">
        <template v-if="carouselItem.content.buttonText">
          {{ carouselItem.content.buttonText }}
        </template>
        <template v-else>
          {{ $t('readMore') }}
        </template>
      </nuxt-link>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CarouselItem',
  props: {
    carouselItem: {
      type: Object,
      required: true,
      default: () => {}
    }
  }
}
</script>
