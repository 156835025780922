<template>
  <div class="relative">
    <div class="sm:float-right text-right">
      <span class="cursor-pointer w-10 h-10 inline-block rotate-180 hover:bg-grey/20 rounded-full" @click="swipeLeft"><IconArrowForward class="text-zinc-400 hover:text-zinc-100" /></span>
      <span class="cursor-pointer w-10 h-10 inline-block hover:bg-grey/20 rounded-full" @click="swipeRight"><IconArrowForward class="text-zinc-400 hover:text-zinc-100" /></span>
    </div>
    <t3-ce-header v-bind="$props" class="after:clear-both after:block" />
    <div ref="snap" class="lg:mr-[calc((var(--screen-width)-var(--current-screen))/-2+7.5px)] overflow-x-auto snap-x snap-mandatory hide-scrollbar">
      <div class="flex flex-col flex-wrap content-between h-[440px] lg:h-[860px] gap-x-5 gap-y-4 p-5 ">
        <div v-for="(item, key) in items" :key="key" ref="items" class="snap-start break-inside-avoid transition-transform hover-style" :class="key === 0 ? 'w-[332px] lg:w-[482px] break-after-column' : 'w-[332px]'">
          <blog-item v-if="item.doktype === 116" :first="key" :content="item" label="Blog" />
          <event-item v-if="item.doktype === 117" :first="key" :content="item" label="Event" />
          <podcast-news-list-item v-if="item.doktype === 118" :first="key" :content="item" :list-page="podcastList" label="Podcast" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import baseCe from '../mixins/baseCe'
import BlogItem from '../partials/News/BlogItem'
import EventItem from '../partials/News/EventItem'
import PodcastNewsListItem from '../partials/News/PodcastNewsListItem'
import IconArrowForward from '~/assets/icons/arrow-forward-outline.svg?inline'
export default {
  name: 'T3CeSuresecureNewsList',
  components: {
    BlogItem,
    EventItem,
    PodcastNewsListItem,
    IconArrowForward
  },
  extends: baseCe,
  props: {
    items: {
      type: Array,
      default: () => []
    },
    itemsCount: {
      type: Number,
      default: 0
    },
    podcastList: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      swipePosition: 0
    }
  },
  methods: {
    swipeLeft() {
      if (this.swipePosition > 0) {
        this.swipePosition -= (this.$refs.items[0].clientWidth + 16)
        this.$refs.snap.scroll({
          left: this.swipePosition,
          behavior: 'smooth'
        });
      }
    },
    swipeRight() {
      if ((this.swipePosition + (this.$refs.items[0].clientWidth + 16)*3) < this.$refs.snap.scrollWidth) {
        this.swipePosition += (this.$refs.items[0].clientWidth + 16)
        this.$refs.snap.scroll({
          left: this.swipePosition,
          behavior: 'smooth'
        });
      }
    }
  }
}
</script>
<style lang="postcss" scoped>
  .hover-style {
    &:hover {
      @apply scale-[104%];
      /deep/
      figure {
        img,
        div {
          @apply scale-[104%];
        }
      }
    }
    /deep/
    figure {
      @apply bottom-[-1px];
      img,
      div {
        @apply transition-transform;
      }
    }
  }
</style>