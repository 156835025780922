var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: _vm.backgroundClass,
      style: { "padding-top": _vm.footerTopHeight / 2 + "px" },
    },
    [
      _c("div", { staticClass: "bg-[#242C2B]" }, [
        _c("section", { staticClass: "xl:container" }, [
          _vm.col90.length
            ? _c(
                "div",
                {
                  ref: "footerTop",
                  staticClass: "-translate-y-1/2",
                  style: {
                    "margin-bottom": "-" + _vm.footerTopHeight / 2 - 20 + "px",
                  },
                },
                [_c("t3-renderer", { attrs: { content: _vm.col90 } })],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "xs:gap-4 sm:grid sm:grid-cols-2 md:grid-cols-4 md:mx-3 lg:grid-cols-5 pt-10",
            },
            [
              _c(
                "div",
                [_c("layout-footer-block", { attrs: { content: _vm.col91 } })],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [_c("layout-footer-block", { attrs: { content: _vm.col92 } })],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [_c("layout-footer-block", { attrs: { content: _vm.col93 } })],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [_c("layout-footer-block", { attrs: { content: _vm.col94 } })],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [_c("layout-footer-block", { attrs: { content: _vm.col95 } })],
                1
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("section", { staticClass: "xl:container mt-10 sm:mt-0" }, [
          _c(
            "div",
            { staticClass: "mx-3" },
            [
              _c(
                "h4",
                {
                  staticClass:
                    "relative py-3 text-xs font-medium uppercase cursor-pointer mb-0 sm:mb-5 text-quinary",
                },
                [_vm._v(_vm._s(_vm.$t("ourAwards")))]
              ),
              _vm._v(" "),
              _c("t3-renderer", { attrs: { content: _vm.col101 } }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("section", { staticClass: "xl:container mb-10" }, [
          _c("div", { staticClass: "mx-3" }, [
            _c(
              "h4",
              {
                staticClass:
                  "relative py-3 text-xs font-medium uppercase cursor-pointer mb-0 sm:mb-5 text-quinary",
              },
              [_vm._v(_vm._s(_vm.$t("followUs")))]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex justify-start gap-1 mt-5" },
              [_c("t3-renderer", { attrs: { content: _vm.col102 } })],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }