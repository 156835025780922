<template>
  <div class="relative">
    <div class="flex flex-row justify-between">
      <t3-ce-header v-bind="$props" />
      <div class="ml-auto whitespace-nowrap" :class="[!makeItCarousel ? 'hidden' : 'block']">
        <span class="cursor-pointer w-10 h-10 inline-block rotate-180 hover:bg-grey/20 rounded-full" @click="swipeLeft"><IconArrowForward /></span>
        <span class="cursor-pointer w-10 h-10 inline-block hover:bg-grey/20 rounded-full" @click="swipeRight"><IconArrowForward /></span>
      </div>
    </div>
    <ul v-if="menu" ref="snap" class="gap-4 py-2 -mx-3 px-3 flex flex-row flex-nowrap snap-mandatory hide-scrollbar" :class="makeItCarousel ? 'xl:-mr-container-margin overflow-x-auto snap-x' : 'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3'">
      <li v-for="(item, key) in menu" :key="key" ref="items" class="w-full shrink-0 hover:scale-105 transition-all duration-300" :class="{'flex-shrink-0 snap-start md:w-[calc(((var(--screen-width))/3)-(theme(gap.4)*2)/theme(columns.3)-theme(gap.1))] xl:w-[calc(((var(--current-screen))/3)-(theme(gap.4)*2)/theme(columns.3))]' : makeItCarousel}">
        <t3-nav-link
          :to="item.link"
          :target="item.target || false"
          :title="item.teaser_title"
          class="h-full"
        >
          <div class="bg-boxed rounded-lg h-full px-4 sm:px-6 py-6 sm:py-10 flex hover:pr-5 duration-300">
            <div v-if="item.teaser_icon[0]" class="relative">
              <div class="bg-white/10 w-11 h-11 lg:w-20 lg:h-20 rounded-full relative">
                <InlineSvg :image="item.teaser_icon[0]" :title="item.teaser_title" class="absolute z-10 left-1/2 -translate-x-1/2  top-1/2 -translate-y-1/2 w-full max-w-[30px] lg:max-w-[60px] lg:max-h-[60px]" />
              </div>
            </div>
            <div class="ml-6 pt-2 sm:pt-3 w-full">
              <IconArrowForward class="w-6 h-6 float-right" />
              <p class="text-base sm:text-lg font-bold mb-2 sm:mb-4 mr-5">{{ item.teaser_title }}</p>
              <p class="text-[14px] leading-[25px] sm:text-[16px] text-quinary">{{ item.teaser_text }}</p>
            </div>
          </div>
        </t3-nav-link>
      </li>
    </ul>
  </div>
</template>
<script>
import baseCe from '../mixins/baseCe'
import InlineSvg from '../partials/InlineSvg'
import IconArrowForward from '~/assets/icons/arrow-forward-outline-quinary.svg?inline'
export default {
  name: 'T3CeSuresecureTeaserSelectedPages',
  components: {
    IconArrowForward,
    InlineSvg
  },
  extends: baseCe,
  props: {
    menu: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      swipePosition: 0,
    }
  },
  methods: {    
    swipeLeft() {
      if (this.swipePosition > 1) {
        this.swipePosition -= (this.$refs.items[0].clientWidth + 5.6)
        this.$refs.snap.scroll({
          left: this.swipePosition,
          behavior: 'smooth'
        });
      }
    },
    swipeRight() {
      const screen = window.innerWidth
      if (screen < 768) {
        if ((this.swipePosition + (this.$refs.items[0].clientWidth + 5.6)*2) < this.$refs.snap.scrollWidth) {
          this.swipePosition += (this.$refs.items[0].clientWidth + 5.6)
          this.$refs.snap.scroll({
            left: this.swipePosition,
            behavior: 'smooth'
          });
      }
      } else if ((this.swipePosition + (this.$refs.items[0].clientWidth + 5.6)*3) < this.$refs.snap.scrollWidth) {         
        this.swipePosition += (this.$refs.items[0].clientWidth + 5.6)
        this.$refs.snap.scroll({
          left: this.swipePosition,
          behavior: 'smooth'
        });        
      }      
    }
  },
}
</script>