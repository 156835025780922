var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "t3-nav-link",
    {
      staticClass: "block transition-all duration-300 hover:shadow-lg",
      attrs: { to: _vm.content.slug, title: _vm.content.teaser_title },
    },
    [
      _vm.content.teaserImage[0] || _vm.content.media[0]
        ? _c("figure", { staticClass: "img-wrapper" }, [
            _vm.content.teaserImage[0]
              ? _c("img", {
                  directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
                  staticClass: "rounded-t-md w-full",
                  attrs: {
                    "data-src": _vm.content.teaserImage[0].publicUrl,
                    alt: _vm.content.header,
                    width:
                      _vm.content.teaserImage[0].properties.dimensions.width,
                    height:
                      _vm.content.teaserImage[0].properties.dimensions.height,
                  },
                })
              : _vm.content.media.length > 0
              ? _c("img", {
                  directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
                  staticClass: "rounded-t-md w-full",
                  attrs: {
                    "data-src": _vm.content.media[0].publicUrl,
                    alt: _vm.content.header,
                    width: _vm.content.media[0].properties.dimensions.width,
                    height: _vm.content.media[0].properties.dimensions.height,
                  },
                })
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "px-5 pb-5 bg-white rounded-b-md",
          class: {
            "mt-[13px]": !_vm.content.teaserImage[0] && !_vm.content.media[0],
          },
        },
        [
          _vm.label || _vm.content.categories.length
            ? _c("ContentPartialsNewsCategoryBadge", {
                attrs: {
                  label: _vm.label,
                  category:
                    _vm.content.categories.length > 0
                      ? _vm.content.categories[0]
                      : [],
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("p", { staticClass: "text-quinary text-xs" }, [
            _vm._v(
              _vm._s(
                _vm.$moment
                  .unix(
                    _vm.content.starttime
                      ? _vm.content.starttime
                      : _vm.content.crdate
                  )
                  .format("DD/MM/YYYY")
              )
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "h6 font-bold" }, [
            _vm._v(
              _vm._s(
                _vm.content.teaser_title
                  ? _vm.content.teaser_title
                  : _vm.content.title
              )
            ),
          ]),
          _vm._v(" "),
          _vm.first == 0
            ? _c("p", { staticClass: "mb-5 text-md" }, [
                _vm._v("\n      " + _vm._s(_vm.content.teaser_text) + "\n    "),
              ])
            : _c("p", { staticClass: "mb-2 text-md" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm._f("truncate")(_vm.content.teaser_text, 75)) +
                    "\n    "
                ),
              ]),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "link link-arrow text-sm hover:text-boxed" },
            [_vm._v("\n      " + _vm._s(_vm.$t("continueReading")) + "\n    ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }