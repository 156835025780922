var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "mx-3 text-white/50 border-b border-b-white/50 sm:border-b-0 md:mx-0 footer-block-content-item",
      class: _vm.isBlockOpen ? "open" : "",
    },
    [
      _vm.contentElement.content && _vm.contentElement.content.header
        ? _c(
            "h4",
            {
              staticClass:
                "relative py-3 text-xs font-medium uppercase cursor-pointer mb-0 sm:mb-5 lg:cursor-text btn-dropdown-toggle",
              class: _vm.isBlockOpen ? "open" : "",
              on: {
                click: function ($event) {
                  _vm.isBlockOpen = !_vm.isBlockOpen
                },
              },
            },
            [
              _vm.contentElement.content.headerLink.href
                ? _c(
                    "nuxt-link",
                    {
                      attrs: { to: _vm.contentElement.content.headerLink.href },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "flex flex-row justify-between items-center after:mt-2 after:mr-2 sm:block",
                        },
                        [_vm._v(_vm._s(_vm.contentElement.content.header))]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "overflow-hidden sm:overflow-visible text-sm font-light transition-all duration-200 sm:pb-5 sm:max-h-fit",
          class: _vm.isBlockOpen ? "max-h-32 mb-3" : "max-h-0",
        },
        [
          _c(
            "div",
            { staticClass: "pb-3" },
            [_c("t3-renderer", { attrs: { content: _vm.contentForRenderer } })],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }