var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "t3-ce-uploads" },
    [
      _c("t3-ce-header", _vm._b({}, "t3-ce-header", _vm.$props, false)),
      _vm._v(" "),
      _vm.media
        ? _c(
            "ul",
            _vm._l(_vm.media, function (file, key) {
              return _c(
                "li",
                { key: key, staticClass: "download" },
                [
                  _c(
                    "t3-nav-link",
                    {
                      staticClass: "flex items-center gap-5",
                      attrs: {
                        to:
                          file.properties.modalLink !== ""
                            ? file.properties.modalLink.href
                            : file.publicUrl,
                        target: _vm.target,
                      },
                    },
                    [
                      _vm.displayInformation == 1
                        ? _c(
                            "span",
                            { staticClass: "t3-ce-loads__icon" },
                            [
                              _c("IconDownload", {
                                staticClass:
                                  "w-10 h-10 absolute top-1/2 left-1/2 translate-x-[-57%] -translate-y-1/2",
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayInformation === 2 &&
                      file.properties.type === "image"
                        ? _c(
                            "span",
                            { staticClass: "t3-ce-uploads__thumb" },
                            [_c("t3-ce-media-file", { attrs: { file: file } })],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "t3-ce-uploads__name text-lg" },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(file.properties.title || file.publicUrl) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.displayFileSizeInformation == 1
                        ? _c(
                            "span",
                            { staticClass: "t3-ce-uploads__size text-lg" },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(file.properties.size) +
                                  "\n        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "ml-auto text-sm" },
                        [
                          _c("IconArrowForward", {
                            staticClass: "arrow-right",
                          }),
                          _vm._v("\n          DOWNLOAD            \n        "),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.displayDescription && file.properties.description
                    ? _c("p", { staticClass: "t3-ce-uploads__desc" }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(file.properties.description) +
                            "\n      "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }