<template>
  <div class="rounded-lg bg-boxed px-8 py-10" :class="{ 'flex items-start flex-col md:flex-row' : backgroundImage[0]}">
    <img
      v-if="backgroundImage[0]"
      v-lazy-load :data-src="backgroundImage[0].publicUrl"
      :alt="author"
      :width="backgroundImage[0].properties.dimensions.width"
      :height="backgroundImage[0].properties.dimensions.height"
      class="md:mr-12 mx-auto md:mx-0 mb-5 md:mb-0 rounded-full w-56 h-56"
    >
    <div>
      <p id="text-primary" class="mt-10 font-bold italic relative w-5/6 mb-8 after:content-['\201E'] after:not-italic after:text-[450px] after:font-quote after:block after:absolute after:bottom-10 after:-right-28 lg:after:-right-36 after:w-60 after:h-60 after:opacity-10 after:text-white after:pointer-events-none" :class="smallText === 1 ? 'text-2xl' : 'text-2xl lg:text-4xl'">{{ bodytext }}</p>
      <p class="text-lg text-white">{{ author }}</p>
      <p class="text-quinary">{{ authorPosition }}</p>
    </div>
  </div>
</template>
<script>
import baseCe from '../mixins/baseCe'
export default {
  name: 'T3CeSuresecureQuote',
  extends: baseCe,
  props: {
    backgroundImage: {
      type: Array,
      default: () => []
    },
    bodytext: {
      type: String,
      default: ''
    },
    author: {
      type: String,
      default: ''
    },
    authorPosition: {
      type: String,
      default: ''
    },
    smallText: {
      type: Number,
      default: 0,
    },
  }
}
</script>
<style lang="postcss" scoped>
#text-primary {
  @apply !text-primary;
}
</style>