<template>
  <div>
    <t3-ce-header v-bind="$props" class="mb-20" />
    <div v-for="(item, key) in accordionItems" :key="key">
      <AccordionItem :accordion-item="accordionItems[key]" />
    </div>
  </div>
</template>
<script>
import AccordionItem from '../partials/AccordionItem'
import baseCe from '../mixins/baseCe'
export default {
  name: 'T3CeSureseccureAccordion',
  components: {
    AccordionItem
  },
  extends: baseCe,
  provide() {
    return { Accordion: this.Accordion };
  },
  props: {
    accordionItems: {
      type: Array,
      required: true,
      default: () => []
    },
    jsonld: {
      type: String,
      required: true,
      default: '0'
    }
  },
  data() {
    return {
      Accordion: {
        count: 0,
        active: null
      }
    }
  },
  jsonld () {
    const faqItems = this.accordionItems.map((item, index) => ({
      '@type': 'Question',
      name: item.content.header,
      acceptedAnswer: {
        '@type': 'Answer',
        text: item.content.bodytext.replace(/<\/?[^>]+(>|$)/g, "")
      }
    }))    
    if (this.jsonld === 0) {
      return null
    }
    if (this.jsonld !== 0) {
      return {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: faqItems
      }
    }   
  }
}
</script>