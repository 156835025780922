var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "relative p-4 rounded-lg transition-colors mb-5 cursor-pointer",
      class: { "bg-white": _vm.visible, "bg-boxed": !_vm.visible },
    },
    [
      _c(
        "div",
        {
          class: { accordion__trigger_active: _vm.visible },
          on: { click: _vm.open },
        },
        [
          _vm._t("accordion-trigger", function () {
            return [
              _c("div", { staticClass: "flex items-center" }, [
                _vm.accordionItem.content.backgroundImage[0]
                  ? _c("div", { staticClass: "relative" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "bg-white/10 w-20 h-20 block rounded-full relative",
                        },
                        [
                          _c("InlineSvg", {
                            staticClass:
                              "absolute z-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full max-w-[60px]",
                            attrs: {
                              image:
                                _vm.accordionItem.content.backgroundImage[0],
                              title: _vm.accordionItem.content.header,
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "h4",
                  {
                    staticClass: "text-lg font-bold ml-4 mb-0 mr-8",
                    class: _vm.visible ? "text-black" : "text-white",
                  },
                  [_vm._v(_vm._s(_vm.accordionItem.content.header))]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "ml-auto relative mr-4" }, [
                  _c("div", {
                    staticClass:
                      "h-[3px] w-[23px] bg-primary absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2",
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass:
                      "h-[23px] w-[3px] bg-primary transition-transform duration-300",
                    class: { "rotate-90": _vm.visible },
                  }),
                ]),
              ]),
            ]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "transition",
        {
          attrs: { name: "accordion" },
          on: {
            enter: _vm.start,
            "after-enter": _vm.end,
            "before-leave": _vm.start,
            "after-leave": _vm.end,
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.visible,
                  expression: "visible",
                },
              ],
            },
            [
              _vm._t("accordion-content", function () {
                return [
                  _c("t3-html-parser", {
                    class:
                      { "text-black": _vm.visible } &&
                      _vm.accordionItem.content.backgroundImage[0]
                        ? "ml-24 mr-8 sm:mx-24"
                        : "ml-4 mt-5 mr-8 sm:mr-24",
                    attrs: { content: _vm.accordionItem.content.bodytext },
                  }),
                ]
              }),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }