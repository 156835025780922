var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.t3page
        ? _c("t3-dynamic", {
            attrs: {
              data: _vm.t3page.content,
              type: _vm.t3page.appearance.backendLayout,
              layout: "",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }