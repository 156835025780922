<template>
  <div class="-mx-10 flex flex-col h-full overflow-x-hidden">
    <div class="px-10 header">
      <t3-ce-header v-bind="$props" />
    </div>
    <div v-show="!formular" class="w-full mb-[-1.5rem] md:mb-[-2rem] lg:mb-[-3.3rem] relative z-10">
      <div class="flex">
        <div
          v-for="(question, key) in data.catalog.questions"
          :key="key"
          :class="['w-1/' + data.catalog.questions.length]"
        >
          <div class="relative mb-2">
            <div
              v-if="currentStep > key"
              class="absolute flex align-center items-center align-middle content-center w-full top-1/2 -translate-y-1/2 -mt-px"
              :class="
                currentStep > data.catalog.questions.length
                  ? ''
                  : '-translate-x-1/2'
              "
            >
              <div
                class="bg-gray-200 items-center align-middle align-center flex-1"
                :class="
                  currentStep > data.catalog.questions.length
                    ? 'w-full'
                    : 'w-1/2'
                "
              >
                <div class="bg-primary h-[3px] rounded w-full"></div>
              </div>
            </div>

            <div
              class="w-8 md:w-12 h-8 md:h-12 mx-auto rounded-full text-lg text-white flex items-center relative z-10 transition-all duration-150"
              :class="
                currentStep > key + 1
                  ? 'bg-primary'
                  : currentStep === key + 1
                  ? 'bg-grey'
                  : 'bg-gray-300'
              "
            >
              <span class="text-center text-white w-full">
                {{ key + 1 }}
              </span>
            </div>
          </div>
          <div
            class="hidden lg:block text-xs text-center uppercase font-medium transition-all duration-150"
            :class="
              currentStep > key + 1
                ? 'text-primary'
                : currentStep === key + 1
                ? 'text-grey'
                : 'text-gray-300'
            "
          >
            {{ question.stepLabel }}
          </div>
        </div>
      </div>
    </div>
    <div v-show="!formular" class="flex-1 h-full bg-zinc-100 pt-20 pb-10 pb-10-safe content">
      <div class="mx-auto max-w-5xl px-6">
        <div
          v-for="(question, key) in data.catalog.questions"
          v-show="currentStep === key + 1"
          :key="key"
        >
          <h3>{{ question.label }}</h3>
          <p class="mb-8">{{ question.description }}</p>

          <div class="mb-8">
            <div
              v-for="(answer, answerKey) in question.answers"
              :key="answerKey"
              class="flex items-center mb-4 bg-white p-4"
            >
              <input
                :id="`answers${key}-${answerKey}`"
                type="radio"
                :name="`answers${key}`"
                :value="answer.points"
                class="opacity-0 absolute h-7 w-7"
                @click="setQuestionPoints(key, answer.points, question.label, answer.label)"
              />
              <div
                class="bg-white border rounded-md border-gray-200 w-6 h-6 flex shrink-0 justify-center items-center mr-2 focus-within:border-primary"
              >
                <IconCheckmark
                  class="fill-current hidden w-3 h-3 text-primary pointer-events-none"
                />
              </div>
              <label :for="`answers${key}-${answerKey}`" class="cursor-pointer">{{
                answer.label
              }}</label>
            </div>
          </div>

          <button
            v-if="currentStep > 1"
            class="btn-quinary mr-3"
            @click="goPreviousStep"
          >
            {{ $t('previous') }}
          </button>
          <button
            :class="nextStep >= key + 1 ? 'btn-primary' : 'btn-secondary'"
            @click="nextStep >= key + 1 ? goNextStep() : null"
          >
            {{ $t('next') }}
          </button>
        </div>

        <div v-show="currentStep === data.catalog.questions.length + 1" class="text-center md:text-left">
          <div class="md:flex md:justify-center md:items-center mt-8">
            <div ref="container" class="mb-8 mx-auto md:mr-14 max-w-full">
              <client-only>
                <vue-ellipse-progress
                  :progress="Math.floor((currentPoints / totalPoints) * 100)"
                  :legend-value="currentPoints"
                  :size="300"
                  :color="progressColor"
                  class="mx-auto lg:scale-110"
                >
                  <span slot="legend-value">/{{ totalPoints }}</span>
                  <p slot="legend-caption" class="uppercase">{{ $t('points') }}</p>
                </vue-ellipse-progress>
              </client-only>
            </div>
            <div>
              <h3> {{ data.catalog.results[resultKey].title }} </h3>
              <p class="mb-8"> {{ data.catalog.results[resultKey].description }} </p>
              <p class="btn-primary" @click="showFormular">{{ $t('getInTouch') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="formular" class="pt-4 pb-10 pb-10-safe">
      <div class="mx-auto max-w-5xl px-10">
        <h3 class="mb-12">{{ $t('form.headline') }}</h3>
        <form 
          action="https://go.suresecure.de/l/859003/2022-06-29/3gb6w5"
          method="POST"
          @submit="checkForm"        
        >
          <input type="hidden" name="LeadSource" value="Web">
          <input type="hidden" name="Confirmed_Opt_In_Source__c" value="Website_Form_Online_Check">
          <input type="hidden" name="Interest___c" value="Online-Check">
          <input type="hidden" name="UTM_Campaign_Name__c" :value="campaign">
          <input type="hidden" name="UTM_Campaign_Source__c" :value="source">
          <input type="hidden" name="UTM_Campaign_Medium__c" :value="medium">
          <input type="hidden" name="Newsletter_Opt_In_Timestamp__c" :value="fullDateTime">
          <input type="hidden" name="Online_Check_Output__c" :value="selectedAnswers">                 

          <div class="form-group">
            <select id="salutation" name="Salutation" required>
              <option hidden></option>
              <option value="Mr.">{{ $t('form.salutations.mr') }}</option>
              <option value="Mrs.">{{ $t('form.salutations.mrs') }}</option>
            </select>
            <label for="salutation">{{ $t('form.salutation') }}</label>
          </div>
          <div class="form-group">
            <input
              maxlength="40"
              name="FirstName"
              size="20"
              type="text"
              placeholder="first_name"
              required
            />
            <label for="first_name">{{ $t('form.first_name') }}</label>
          </div>
          <div class="form-group">
            <input
              maxlength="80"
              name="LastName"
              size="20"
              type="text"
              placeholder="last_name"
              required
            />
            <label for="last_name">{{ $t('form.last_name') }}</label>
          </div>
          <div class="form-group">
            <input              
              maxlength="80"
              name="email"
              size="20"
              type="email"
              placeholder="email"
              required
            />
            <label for="email">{{ $t('form.email') }}</label>
          </div>
          <div class="form-group">
            <input              
              ref="phone"
              maxlength="40"
              name="Phone"
              size="20"
              type="text"
              placeholder="phone"
            />
            <label for="phone">{{ $t('form.phone') }}</label>
          </div>
          <div class="form-group">
            <input             
              maxlength="40"
              name="Company"
              size="20"
              type="text"
              placeholder="company"
              required
            />
            <label for="company">{{ $t('form.company') }}</label>
          </div>
          <div class="form-group">
            <select id="interest" name="Interest___c" required>
              <option hidden></option>
              <option value="Initial interview">{{ $t('form.initialInterview') }}</option>
              <option value="Urgent incident">{{ $t('form.urgentIncident') }}</option>
            </select>
            <label for="interest">{{ $t('form.interest') }}</label>
          </div>
          <div class="form-group">
            <textarea               
              name="Description" 
              placeholder="description" 
              required>
            </textarea>
            <label for="description">{{ $t('form.description') }}</label>
          </div>

          <div class="form-group !mb-0 checkbox">            
            <input
              :id="[`privacy_policy-${id}`]"
              name="GDPR_Data_Processing_Accepted__c"
              type="checkbox"
              value="true" 
              placeholder="privacy_policy" 
              required
            />             
            <i18n path="form.privacyPolicy" tag="label" :for="[`privacy_policy-${id}`]">
              <template #link>
                <a class="text-primary font-medium" href="/datenschutzerklaerung">{{ $t('form.link') }}</a>
              </template>
            </i18n>      
          </div>
          <div class="form-group checkbox">
            <input
              :id="[`newsletter-${id}`]"
              ref="newsletter"
              name="Newsletter_Opt_In__c"
              type="checkbox"
              value="" 
              placeholder="newsletter"
              @click="printFullDate()"          
            />                         
            <i18n path="form.newsletter" tag="label" :for="[`newsletter-${id}`]" @click="printFullDate()">
              {{ $t('form.newsletter') }}
            </i18n>      
          </div>          
          <input
            type="submit"
            name="submit"
            class="btn btn-primary btn-lg w-full"
          />
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import baseCe from '../mixins/baseCe'
import T3CeHeader from './T3CeHeader.vue'
import IconCheckmark from '~/assets/icons/checkmark.svg?inline'
export default {
  name: 'T3CeSuresecurequestionaryCatalogdetails',
  components: {
    T3CeHeader,
    IconCheckmark,
  },
  extends: baseCe,
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      currentStep: 1,
      nextStep: 0,
      currentPoints: 0,
      questionPoints: [],
      selectedAnswers: [],
      resultKey: 0,
      totalPoints: 0,
      fullDateTime: '',
      formular: false,
      progressColor: {
        radial: false,
        colors: [
          {
            color: '#FFE200',
            offset: '0',
            opacity: '1',
          },
          {
            color: '#89BD27',
            offset: '100',
            opacity: '1',
          },
        ],
      },
      containerWidth: 0,
      campaign: this.$route.query.utm_campaign,
      medium: this.$route.query.utm_medium,
      source: this.$route.query.utm_source
    }
  },
  computed: {
    ...mapState({
      contactLink: (state) => state.typo3.initial.contactLink || [],
    }),
  },
  mounted() {
    this.setTotalPoints()   
  },
  destroyed() {
    this.currentPoints = 0
    this.questionPoints = []
    this.currentStep = 1
  },
  methods: {
    printFullDate() {
      if(this.$refs.newsletter.value === '') {
        this.$refs.newsletter.value = true
      } else {
        this.$refs.newsletter.value = ''
      }
      const time = new Date()
      this.fullDateTime = time
    },
    addCountryCode() {      
      if (this.$refs.phone.value[0] === '0') {
        this.$refs.phone.value.replace(this.$refs.phone.value[0], '+49')
      }
    },
    setTotalPoints() {
      let totalPoints = 0
      let lastAnswer = 0
      this.data.catalog.questions.forEach(function (question) {
        question.answers.forEach(function (answer) {
          if (answer.points < lastAnswer.points) {
            return
          }
          totalPoints += answer.points
          lastAnswer = answer
        })
      })
      this.totalPoints = totalPoints
    },
    calculateQuestionPoints() {
      this.currentPoints = 0

      if (!this.questionPoints) {
        this.currentPoints = 0
      }
      const that = this
      this.questionPoints.forEach(function (question) {
        that.currentPoints += question
      })
      this.getResultKey()
    },
    getResultKey() {
      const that = this
      that.resultKey = 0
      this.data.catalog.results.forEach(function(result, key) {
    if (that.resultKey === 0 && (that.totalPoints / 100) * result.threshold >= that.currentPoints) {
          that.resultKey = key
        }
      })
    },
    setQuestionPoints(question, points, step, selected) {
      this.nextStep =
        this.nextStep > this.currentStep ? this.nextStep : question + 1
      this.questionPoints[question] = points
      this.selectedAnswers[question] = step+' : '+selected+'\n'
    },
    goNextStep() {
      this.calculateQuestionPoints()
      this.currentStep < this.data.catalog.questions.length + 1
        ? this.currentStep++
        : (this.currentStep = this.data.catalog.questions.length + 1)
    },
    goPreviousStep() {
      this.calculateQuestionPoints()
      this.currentStep > 0 ? this.currentStep-- : (this.currentStep = 0)
    },
    showFormular() {
      this.currentStep++
      this.formular = true
    },
    checkForm() {
      this.addCountryCode()      
    },
  },
}
</script>
<style lang="postcss" scoped>
/deep/ {
  h1 {
    @apply mb-3;
  }
  h2 {
    @apply text-md font-normal;
  }
  .ep-legend--container {
    @apply text-xl font-medium;
    .ep-legend--value {
      @apply text-4xl font-bold;
    }
  }
}
.content {
  @apply text-[#627373];
  h3 {
    @apply text-boxed;
  }
}

input:checked + div {
  @apply border-gray-100 bg-gray-200;
}
input:checked + div svg {
  @apply block;
}
</style>
