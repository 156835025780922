var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "relative" }, [
    _c(
      "div",
      { staticClass: "lg:w-3/5 lg:pt-40 pb-12 lg:pb-24 relative z-10" },
      [
        _c(
          "t3-ce-header",
          _vm._b(
            { staticStyle: { hyphens: "auto" } },
            "t3-ce-header",
            _vm.carouselItem.content,
            false
          )
        ),
        _vm._v(" "),
        _c("t3-html-parser", {
          staticClass: "mb-9 lg:mb-16",
          attrs: { content: _vm.carouselItem.content.bodytext },
        }),
        _vm._v(" "),
        _vm.carouselItem.content.link
          ? _c(
              "nuxt-link",
              {
                staticClass: "btn btn-lg",
                class: _vm.carouselItem.content.buttonStyle,
                attrs: { to: _vm.carouselItem.content.link },
              },
              [
                _vm.carouselItem.content.buttonText
                  ? [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.carouselItem.content.buttonText) +
                          "\n      "
                      ),
                    ]
                  : [
                      _vm._v(
                        "\n        " + _vm._s(_vm.$t("readMore")) + "\n      "
                      ),
                    ],
              ],
              2
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }