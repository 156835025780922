var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "page-wrap" } },
    [
      _c(
        "div",
        { staticClass: "bg-secondary" },
        [_c("layout-quick-menu"), _vm._v(" "), _c("layout-main-header")],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "lg:flex-1 lg:transition-all",
          class: _vm.isBlurContent
            ? "md:blur md:after:block md:after:content-[''] md:after:fixed md:after:inset-0 md:after:bg-white/5 md:after:z-50"
            : "",
        },
        [
          _c("nuxt"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "relative bg-white" },
            [
              _c("layout-to-top", {
                staticClass:
                  "absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2",
              }),
              _vm._v(" "),
              _c("layout-footer-meta-nav"),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("layout-incident-response", {
        staticClass: "fixed bottom-4 right-4 z-[999]",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }