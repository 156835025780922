<template>
  <div :class="galleryClass" class="t3-ce-gallery">
    <slot name="before" />
    <div v-if="gallery.position.vertical === 'below'" class="t3-ce-gallery__text">
      <slot />
    </div>
    <div v-if="gallery.count.files" class="t3-ce-gallery__container relative">
      <div
        v-for="(row, rowKey) in gallery.rows"
        :key="rowKey"
        class="t3-ce-gallery__row" 
        :class="rowKey > 1 ? 'mt-8' : ''"              
      >
        <component
          :is="enlargeImageOnClick ? 'a' : 'div'"
          v-for="(col, colKey) in row.columns"
          :key="colKey"
          class="t3-ce-gallery__col"                    
          v-bind="{
            ...(enlargeImageOnClick && {
              target: '_blank',
              href: col.publicUrl
            })
          }"
        >
          <t3-media-file :file="col" />
        </component>
      </div>
    </div>
    <div
      v-if="
        gallery.position.vertical === 'above' ||
          gallery.position.vertical === 'intext'
      "
      class="t3-ce-gallery__text"
    >
      <slot />      
    </div>
    <slot name="after" />
  </div>
</template>
<script>
export default {
  name: 'T3MediaGallery',
  props: {
    gallery: {
      type: Object,
      required: true
    },
    enlargeImageOnClick: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    galleryClass () {
      return [
        `t3-ce-gallery--horizontal-${this.gallery.position.horizontal}`,
        `t3-ce-gallery--vertical-${this.gallery.position.vertical}`,
        { 't3-ce-gallery--no-wrap': this.gallery.position.noWrap },
        { 't3-ce-gallery--border': this.gallery.border.enabled }
      ]
    }
  }
}
</script>
<style lang="postcss" scoped>
  .t3-ce-gallery {
    &--horizontal {
      &-right {
        @apply flex-row-reverse;
      }
    }
    &--no-wrap {
      @apply md:flex gap-10 items-center;
      &>div {
        @apply md:w-1/2;
      }
      &[class*='overflow'] {
        &[class*='-right'] {
          /deep/
          figure {
            @apply md:absolute left-0 top-0 -translate-y-1/2;
            img {
              @apply h-full md:max-w-none;
            }
          }
        }
        &[class*='-left'] {
          /deep/
          figure {
            @apply md:absolute right-0 top-0 -translate-y-1/2;
            img {
              @apply h-full md:max-w-none;
            }
          }
        }
      }
    }
    &[class*='-intext']&:not([class*='--no-wrap']) {
      .t3-ce-gallery__container {
        @apply md:w-1/2 md:float-right md:ml-5 md:mb-5 md:pl-5 md:pb-5;
      }
      &[class*='-left'] {
        .t3-ce-gallery__container {
          @apply md:float-left md:mr-5 md:pr-5 md:ml-0 md:pl-0;
        }
      }
    }
    &--vertical {
      &-above {
        [class*='__container'] {
          @apply mb-16;
        }
      }
      &-below {
        [class*='__container'] {
          @apply mt-16;
        }
      }
      &-above,
      &-below {
        &[class*='-center'] {
          /deep/
          img {
            @apply mx-auto;
          }
        }
        &[class*='-right'] {
          /deep/
          img {
            @apply ml-auto;
          }
        }
      }
    }
    &__container {
      @apply my-10 md:my-0;
    }
    /deep/
    figure {          
      img {
        @apply rounded-md;
      }
    }
  }
  .no-image-decoration {
    .t3-ce-gallery {
      &[class*="--horizontal"] {
        &[class*="--no-wrap"] {
          @apply items-start;
          /deep/
          figure {  
            @apply before:hidden after:hidden;            
          }
        }
      }
    }
  }
</style>
