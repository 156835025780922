var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "no-frame-margin" }, [
    _vm.tab.content.tabItemContentAside.length > 0
      ? _c(
          "div",
          [
            _vm.tab.content.contentOrientation === "80/20"
              ? _c("t3-ce-suresecure-cols-eighty-twenty", {
                  attrs: {
                    left: _vm.cleanTabContent,
                    right: _vm.cleanTabContentAside,
                    "fake-container": false,
                  },
                })
              : _c("t3-ce-suresecure-cols-twenty-eighty", {
                  attrs: {
                    left: _vm.cleanTabContent,
                    right: _vm.cleanTabContentAside,
                    "fake-container": false,
                  },
                }),
          ],
          1
        )
      : _c(
          "div",
          [_c("t3-renderer", { attrs: { content: _vm.cleanTabContent } })],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }