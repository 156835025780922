var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "bg-boxed relative px-7 pt-16 pb-7 rounded-t-lg" },
    [
      _vm.backgroundImage[0]
        ? _c("div", [
            _c("img", {
              directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
              staticClass:
                "absolute -top-16 left-2/4 z-10 -translate-x-1/2 max-w-[100px]",
              attrs: {
                "data-src": _vm.backgroundImage[0].publicUrl,
                width: _vm.backgroundImage[0].properties.dimensions.width,
                height: _vm.backgroundImage[0].properties.dimensions.height,
              },
            }),
            _vm._v(" "),
            _c("span", {
              staticClass:
                "w-28 h-28 rounded-full bg-boxed block absolute -top-12 left-2/4 -translate-x-1/2",
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("t3-html-parser", { attrs: { content: _vm.bodytext } }),
      _vm._v(" "),
      _vm.link
        ? _c(
            "nuxt-link",
            {
              staticClass: "btn btn-lg btn-primary w-full text-center",
              attrs: { to: _vm.link },
            },
            [
              _vm.buttonText
                ? [_vm._v("\n      " + _vm._s(_vm.buttonText) + "\n    ")]
                : [_vm._v("\n      " + _vm._s(_vm.$t("readMore")) + "\n    ")],
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }