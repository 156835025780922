var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "t3-ce-menu" },
    [
      _c("t3-ce-header", _vm._b({}, "t3-ce-header", _vm.$props, false)),
      _vm._v(" "),
      _vm.menu
        ? _c(
            "ul",
            _vm._l(_vm.menu, function (item, key) {
              return _c(
                "li",
                { key: key },
                [
                  _c(
                    "t3-nav-link",
                    {
                      attrs: {
                        to: item.link,
                        target: item.target || false,
                        title: item.title,
                      },
                    },
                    [_vm._v("\n        " + _vm._s(item.title) + "\n      ")]
                  ),
                  _vm._v(" "),
                  _vm._t("link", null, { link: item }),
                  _vm._v(" "),
                  item.children
                    ? _c("t3-ce-menu-pages-list", {
                        attrs: { children: item.children },
                      })
                    : _vm._e(),
                ],
                2
              )
            }),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }