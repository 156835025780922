<template>
  <div class="rounded-lg bg-boxed px-8 py-10 when-nested" :class="{ 'flex items-start flex-col md:flex-row' : image}">
    <img
      v-if="image"
      v-lazy-load :data-src="image.publicUrl"
      :alt="[firstName, lastName]"
      :width="image.properties.dimensions.width"
      :height="image.properties.dimensions.height"
      class="md:mr-12 mx-auto md:mx-0 mb-5 md:mb-0 rounded-full w-56 h-56"
    >
    <div>
      <p class="h3 uppercase text-white">
        <template v-if="contacts[0].salutation == 'mr'">
          {{ $t('yourContactPerson.mr') }}
        </template>
        <template v-else>
          {{ $t('yourContactPerson.ms') }}
        </template>
      </p>
      <p class="h4 text-primary mb-0">{{ firstName }} {{ lastName }}</p>
      <p class="text-white mb-8">{{ contacts[0].position }}</p>
      <ul class="flex flex-col lg:flex-row gap-10 text-white">
        <li class="flex gap-5 items-center font-medium">
          <div class="bg-primary rounded-full w-12 h-12 p-2 text-center">
            <IconPhone />
          </div>{{ contacts[0].phone }}</li>
        <li class="flex gap-5 items-center font-medium">
          <div class="bg-primary rounded-full w-12 h-12 p-2 text-center">
            <IconMail />
          </div>{{ contacts[0].email }}</li>
          <li v-if="contacts[0].link" class="flex gap-5 items-center font-medium">
              <a :href="contacts[0].link" class="btn btn-primary btn-lg block w-auto text-center">
                  <template v-if="contacts[0].button_text">
                      {{ contacts[0].button_text }}
                  </template>
                  <template v-else>
                      {{ $t('makeappointment') }}
                  </template>
              </a>
          </li>
      </ul>
    </div>
  </div>
</template>
<script>
import baseCe from '../mixins/baseCe'
import IconPhone from '~/assets/icons/phone-outline.svg?inline'
import IconMail from '~/assets/icons/mail-outline.svg?inline'
export default {
  name: 'T3CeSuresecureContactPerson',
  components: {
    IconPhone,
    IconMail,
  },
  extends: baseCe,
  props: {
    contacts: {
      type: Array,
      default: () => []
    },
  },
  computed: {
    image() {
      if (this.contacts[0].image === null || this.contacts[0].image.length === 0) {
        return null
      }
      return this.contacts[0].image.properties ? this.contacts[0].image : this.contacts[0].image[0]
    },
    firstName() {
      return this.contacts[0].firstName || this.contacts[0].first_name
    },
    lastName() {
      return this.contacts[0].lastName || this.contacts[0].last_name
    }
  }
}
</script>
<style lang="postcss" scoped>
  .col-span-4 {
    .when-nested {
      @apply !flex-col;
      word-break: break-word;
      img {
        @apply !mx-auto !mb-5;
      }
      ul {
        @apply !flex-col;
      }
    }
  }
</style>