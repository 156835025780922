var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "relative" }, [
    _c(
      "div",
      { staticClass: "flex flex-row justify-between" },
      [
        _c("t3-ce-header", _vm._b({}, "t3-ce-header", _vm.$props, false)),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "ml-auto whitespace-nowrap",
            class: [!_vm.makeItCarousel ? "hidden" : "block"],
          },
          [
            _c(
              "span",
              {
                staticClass:
                  "cursor-pointer w-10 h-10 inline-block rotate-180 hover:bg-grey/20 rounded-full",
                on: { click: _vm.swipeLeft },
              },
              [_c("IconArrowForward")],
              1
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass:
                  "cursor-pointer w-10 h-10 inline-block hover:bg-grey/20 rounded-full",
                on: { click: _vm.swipeRight },
              },
              [_c("IconArrowForward")],
              1
            ),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _vm.menu
      ? _c(
          "ul",
          {
            ref: "snap",
            staticClass:
              "gap-4 py-2 -mx-3 px-3 flex flex-row flex-nowrap snap-mandatory hide-scrollbar",
            class: _vm.makeItCarousel
              ? "xl:-mr-container-margin overflow-x-auto snap-x"
              : "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3",
          },
          _vm._l(_vm.menu, function (item, key) {
            return _c(
              "li",
              {
                key: key,
                ref: "items",
                refInFor: true,
                staticClass:
                  "w-full shrink-0 hover:scale-105 transition-all duration-300",
                class: {
                  "flex-shrink-0 snap-start md:w-[calc(((var(--screen-width))/3)-(theme(gap.4)*2)/theme(columns.3)-theme(gap.1))] xl:w-[calc(((var(--current-screen))/3)-(theme(gap.4)*2)/theme(columns.3))]":
                    _vm.makeItCarousel,
                },
              },
              [
                _c(
                  "t3-nav-link",
                  {
                    staticClass: "h-full",
                    attrs: {
                      to: item.link,
                      target: item.target || false,
                      title: item.teaser_title,
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "bg-boxed rounded-lg h-full px-4 sm:px-6 py-6 sm:py-10 flex hover:pr-5 duration-300",
                      },
                      [
                        item.teaser_icon[0]
                          ? _c("div", { staticClass: "relative" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "bg-white/10 w-11 h-11 lg:w-20 lg:h-20 rounded-full relative",
                                },
                                [
                                  _c("InlineSvg", {
                                    staticClass:
                                      "absolute z-10 left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 w-full max-w-[30px] lg:max-w-[60px] lg:max-h-[60px]",
                                    attrs: {
                                      image: item.teaser_icon[0],
                                      title: item.teaser_title,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "ml-6 pt-2 sm:pt-3 w-full" },
                          [
                            _c("IconArrowForward", {
                              staticClass: "w-6 h-6 float-right",
                            }),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "text-base sm:text-lg font-bold mb-2 sm:mb-4 mr-5",
                              },
                              [_vm._v(_vm._s(item.teaser_title))]
                            ),
                            _vm._v(" "),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "text-[14px] leading-[25px] sm:text-[16px] text-quinary",
                              },
                              [_vm._v(_vm._s(item.teaser_text))]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
              ],
              1
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }