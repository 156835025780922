<template>
  <div>
    <div class="relative">
      <t3-ce-header v-bind="$props" class="mb-20" />
      <a v-if="link" :to="link" class="link link-arrow text-primary absolute lg:right-0 -bottom-8 lg:bottom-0">
        <template v-if="buttonText">
          {{ buttonText }}
        </template>
        <template v-else>
          {{ $t('readMore') }}
        </template>
      </a>
    </div>
    <template v-if="window.width < '1024'">
      <div class="text-right mb-3">
        <span class="cursor-pointer w-10 h-10 inline-block rotate-180 hover:bg-grey/20 rounded-full" @click="swipeLeft"><IconArrowForward /></span>
        <span class="cursor-pointer w-10 h-10 inline-block hover:bg-grey/20 rounded-full" @click="swipeRight"><IconArrowForward /></span>
      </div>
        <div ref="snap" role="tablist" class="flex gap-5 md:gap-10 pb-12 overflow-x-auto snap-x snap-mandatory hide-scrollbar" :class="tabLayout">          
          <div
              v-for="(tab, index) in tabItems"
              ref="items"
              :key="index"
              class="rounded-lg bg-boxed flex items-stretch snap-start shrink-0"
          >
            <a
              href="javascript:void(0);"
              class="relative text-center px-3 sm:px-5 py-2 sm:py-3 horizontal flex items-center"
              role="tab"
              :class="active_tab === index ? 'is-active' : ''"
              :aria-selected="active_tab === index"
              :aria-controls="'tab-' + id + '-' + index"
              @click="changeTab(index)"
            >
              <img
                  v-if="tab.content.backgroundImage[0]"
                  v-lazy-load :data-src="tab.content.backgroundImage[0].publicUrl"
                  :alt="tab.content.header"
                  :width="tab.content.backgroundImage[0].properties.dimensions.width"
                  :height="tab.content.backgroundImage[0].properties.dimensions.height"
                  class="transition-all duration-300 max-h-[60px] w-auto"
                  :class="{ 'grayscale-0 opacity-100' : (active_tab === index), 'grayscale opacity-60': !(active_tab === index) }"
              >
              <t3-html-parser v-else :content="tab.content.header" class="mx-auto font-bold text-[14px] leading-[25px] uppercase" />
            </a>
          </div>
        </div>
        <TabItem
            v-for="(tab, index) in tabItems"
            v-show="active_tab === index"
            :id="'tab-' + id + '-' + index"
            :key="index"
            :aria-current="active_tab === index"
            class="bg-white mx-[calc((var(--screen-width)-var(--current-screen))/-2-1rem)] px-[calc((var(--screen-width)-var(--current-screen))/2+.75rem)]"
            :tab="tab"
        >
        </TabItem>
    </template>
    <template v-else-if="tabLayout === 'horizontal'">
        <div role="tablist" class="flex gap-5 md:gap-10 mb-12" :class="tabLayout">
            <div
                v-for="(tab, index) in tabItems"
                :key="index"
                class="rounded-lg bg-boxed flex items-stretch"
            >
                <a
                    href="javascript:void(0);"
                    class="relative text-center px-3 sm:px-5 py-2 sm:py-3 horizontal flex items-center"
                    role="tab"
                    :class="active_tab === index ? 'is-active' : ''"
                    :aria-selected="active_tab === index"
                    :aria-controls="'tab-' + id + '-' + index"
                    @click="changeTab(index)"
                >
                    <img
                        v-if="tab.content.backgroundImage[0]"
                        v-lazy-load :data-src="tab.content.backgroundImage[0].publicUrl"
                        :alt="tab.content.header"
                        :width="tab.content.backgroundImage[0].properties.dimensions.width"
                        :height="tab.content.backgroundImage[0].properties.dimensions.height"
                        class="transition-all duration-300 max-h-[100px] w-auto"
                        :class="{ 'grayscale-0 opacity-100' : (active_tab === index), 'grayscale opacity-60': !(active_tab === index) }"
                    >
                    <t3-html-parser v-else :content="tab.content.header" class="mx-auto font-bold text-[14px] leading-[25px] uppercase" />
                </a>
            </div>
        </div>
        <TabItem
            v-for="(tab, index) in tabItems"
            v-show="active_tab === index"
            :id="'tab-' + id + '-' + index"
            :key="index"
            :aria-current="active_tab === index"
            class="bg-white mx-[calc((var(--screen-width)-var(--current-screen))/-2-1rem)] px-[calc((var(--screen-width)-var(--current-screen))/2+.75rem)]"
            :tab="tab"
        >
        </TabItem>
    </template>
    <template v-else>
      <div role="tablist" class="flex mb-12 gap-4 items-stretch" :class="tabLayout">
        <div class="shrink-0 max-w-[120px] lg:max-w-[240px]">
            <div
                v-for="(tab, index) in tabItems"
                :key="index"
                class="flex items-center mb-4 last:mb-0 rounded-lg bg-boxed"
            >
                <a
                    href="javascript:void(0);"
                    class="relative text-center px-3 sm:px-5 py-2 sm:py-3 block vertical w-full"
                    role="tab"
                    :class="active_tab === index ? 'is-active' : ''"
                    :aria-selected="active_tab === index"
                    :aria-controls="'tab-' + id + '-' + index"
                    @click="changeTab(index)"
                >
                    <img
                        v-if="tab.content.backgroundImage[0]"
                        v-lazy-load :data-src="tab.content.backgroundImage[0].publicUrl"
                        :alt="tab.content.header"
                        :width="tab.content.backgroundImage[0].properties.dimensions.width"
                        :height="tab.content.backgroundImage[0].properties.dimensions.height"
                        class="transition-all duration-300 max-h-[100px]"
                        :class="{ 'grayscale-0 opacity-100' : (active_tab === index), 'grayscale opacity-60': !(active_tab === index) }"
                    >
                    <t3-html-parser v-else :content="tab.content.header" class="font-bold text-[14px] leading-[25px] uppercase" />
                </a>                
            </div>
        </div>
        <div class="w-full">
            <div
                v-for="(tab, index) in tabItems"
                :key="index"
                :class="active_tab === index ? 'h-full' : ''"
            >                
                <TabItem
                    v-show="active_tab === index"
                    :id="'tab-' + id + '-' + index"
                    :key="index"
                    :aria-current="active_tab === index"
                    class="bg-white px-8 py-4 rounded-lg h-full"
                    :tab="tab"
                >
                </TabItem>
            </div>
        </div>
      </div>
    </template>

  </div>


</template>
<script>
import baseCe from '../mixins/baseCe'
import TabItem from '../partials/TabItem'
import IconArrowForward from '~/assets/icons/arrow-forward-outline-quinary.svg?inline'
export default {
  name: 'T3CeSureseccureTab',
  components: {
    TabItem,    
    IconArrowForward,
  },
  extends: baseCe,
  props: {
    tabItems: {
      type: Array,
      required: true,
      default: () => []
    },
    link: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: ''
    },
    tabLayout: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      active_tab: 0,
      window: {
        width: 0,
        height: 0
      },
      swipePosition: 0,
    }
  },
  mounted() {
      window.addEventListener('resize', this.handleResize);
      this.handleResize()
    },
    destroyed() {
      window.removeEventListener('resize', this.handleResize);
    },
  methods: {
    changeTab(tabIndexValue) {
      this.active_tab = tabIndexValue
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    swipeLeft() {
      if (this.swipePosition > 1) {
        this.swipePosition -= (this.$refs.items[0].clientWidth + 5.6)
        this.$refs.snap.scroll({
          left: this.swipePosition,
          behavior: 'smooth'
        });        
      }
      if (this.active_tab > 0) {        
        this.active_tab = this.active_tab-1       
      } 
    },
    swipeRight() {      
      if ((this.swipePosition + (this.$refs.items[0].clientWidth + 5.6)*2) < this.$refs.snap.scrollWidth) {
        this.swipePosition += (this.$refs.items[0].clientWidth + 5.6)
        this.$refs.snap.scroll({
          left: this.swipePosition,
          behavior: 'smooth'
        });        
      }   
      if (this.$refs.items.length > this.active_tab+1) {        
        this.active_tab = this.active_tab+1       
      } 
    }
  }
}
</script>
<style lang="postcss" scoped>
  a.is-active {
    @apply after:bg-white after:absolute after:content-[''] after:block after:w-4 after:h-4 after:rotate-45;
    &.horizontal {
      @apply after:-bottom-14 after:left-1/2 after:-translate-x-1/2;
    }
    &.vertical {
        @apply after:top-1/2 after:-right-6 after:-translate-y-1/2;
    }
  }
</style>
