<template>
  <div class="suresecure-carousel relative">
    <img
      v-if="backgroundImage[0]"
      v-lazy-load :data-src="backgroundImage[0].publicUrl" class="img-fluid lg:absolute top-1/2 right-0 lg:translate-x-1/2 lg:-translate-y-1/2 lg:max-w-screen-xl xl:max-w-screen-2xl"
      :width="backgroundImage[0].properties.dimensions.width"
      :height="backgroundImage[0].properties.dimensions.height"
      :alt="backgroundImage[0].properties.alternative"
    >
    <vue-glide
      v-if="carouselItems.length > 1"
      v-bind="glideOptions"
    >
      <vue-glide-slide
        v-for="(carouselItem, key) in carouselItems"
        :key="key"
      >
        <CarouselItem :carousel-item="carouselItem" />
      </vue-glide-slide>
    </vue-glide>
    <div v-else class="glide">
      <div
        class="glide__slide"
      >
        <CarouselItem :carousel-item="carouselItems[0]" />
      </div>
    </div>
  </div>
</template>
<script>
import { Glide, GlideSlide } from 'vue-glide-js'
import CarouselItem from '../partials/CarouselItem'
import 'vue-glide-js/dist/vue-glide.css'
import baseCe from '../mixins/baseCe'
export default {
  name: 'T3CeSureseccureCarousel',
  components: {
    CarouselItem,
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide
  },
  extends: baseCe,
  props: {
    carouselItems: {
      type: Array,
      required: true,
      default: () => []
    },
    backgroundImage: {
      type: Array,
      default: () => []
    },
  },
  data () {
    return {
      glideOptions: {
        type: 'carousel',
        autoplay: 6000,
        perView: 1,
        hoverpause: true,
        bullet: true
      }
    }
  }
}
</script>
<style lang="postcss" scoped>
/deep/
  .glide {
    &__track {
      @apply overflow-visible;
    }
    &__slides {
      @apply overflow-visible !transform-none !w-auto grid;
      grid-template-areas: 'slide';
      .glide__slide {
        @apply opacity-0;
        &--active {
          @apply opacity-100 z-10;
        }
      }
    }
    &__slide {
      @apply relative transition-opacity duration-500;
      grid-area: slide;
    }
    &__bullets {
      @apply hidden lg:flex flex-col gap-6 absolute top-[168px] lg:-left-16 xl:-left-[calc((var(--screen-width)-var(--current-screen))/2)];
      counter-reset: bullet;
    }
    &__bullet {
      @apply h-[20px] w-8 opacity-40 transition-all duration-300 relative border-b-2 border-b-white;
      &:after {
        @apply absolute top-2 -right-4 text-primary opacity-0;
        counter-increment: bullet;
        content: counter(bullet);
      }
      &--active {
        @apply border-b-primary w-12 opacity-100;
        &:after {
          @apply opacity-100;
        }
      }
    }
  }
</style>