// Common props for TYPO3 Content Elements
export default {
  props: {
    /**
     * ID of CE / TYPO3 UID
     */
    id: {
      type: Number,
      default: 0
    },
    /**
     * Type of CE / TYPO3 CType
     */
    type: {
      type: String,
      default: 'inline'
    },
    /**
     * Column position
     */
    colPos: {
      type: Number,
      default: 0
    },
    /**
     * Background Color Class
     */
    bgColorClass: {
      type: String,
      default: ''
    },
    /**
     * Skyline
     */
    skyline: {
      type: String,
      default: ''
    },
    /**
     * Header content / TYPO3 header
     */
    header: {
      type: String,
      default: ''
    },
    /**
     * Header Break / TYPO3 suresecure_header_break
     */
     headerBreak: {
      type: Number,
      default: 0
    },
    /**
     * Header Highlight / TYPO3 suresecure_header_highlight
     */
     headerHighlight: {
      type: Number,
      default: 0
    },
    /**
     * Header Add One / TYPO3 suresecure_header_add_1
     */
     headerAddOne: {
      type: String,
      default: ''
    },
    /**
     * Header Add One Break / TYPO3 suresecure_header_add_1_break
     */
     headerAddOneBreak: {
      type: Number,
      default: 0
    },
    /**
     * Header Add One Highlight / TYPO3 suresecure_header_add_1_highlight
     */
     headerAddOneHighlight: {
      type: Number,
      default: 0
    },
    /**
     * Header Add Two / TYPO3 suresecure_header_add_2
     */
     headerAddTwo: {
      type: String,
      default: ''
    },
    /**
     * Header Add Two Break / TYPO3 suresecure_header_add_2_break
     */
     headerAddTwoBreak: {
      type: Number,
      default: 0
    },
    /**
     * Header Add Two Highlight / TYPO3 suresecure_header_add_2_highlight
     */
     headerAddTwoHighlight: {
      type: Number,
      default: 0
    },
    /**
     * Header Add Three / TYPO3 suresecure_header_add_3
     */
     headerAddThree: {
      type: String,
      default: ''
    },
    /**
     * Header Add Three Break / TYPO3 suresecure_header_add_3_break
     */
     headerAddThreeBreak: {
      type: Number,
      default: 0
    },
    /**
     * Header Add Three Highlight / TYPO3 suresecure_header_add_3_highlight
     */
     headerAddThreeHighlight: {
      type: Number,
      default: 0
    },
    /**
     * Header Add Four / TYPO3 suresecure_header_add_4
     */
     headerAddFour: {
      type: String,
      default: ''
    },
    /**
     * Header Add Four Break / TYPO3 suresecure_header_add_4_break
     */
     headerAddFourBreak: {
      type: Number,
      default: 0
    },
    /**
     * Header Add Four Highlight / TYPO3 suresecure_header_add_4_highlight
     */
     headerAddFourHighlight: {
      type: Number,
      default: 0
    },
    /**
     * Header Add Five / TYPO3 suresecure_header_add_5
     */
     headerAddFive: {
      type: String,
      default: ''
    },
    /**
     * Header Add Five Highlight / TYPO3 suresecure_header_add_5_highlight
     */
    headerAddFiveHighlight: {
      type: Number,
      default: 0
    },
    /**
     * Header Type / TYPO3 header_layout
     */
    headerLayout: {
      type: Number,
      default: 0
    },
    /**
     * Header Type / TYPO3 header_class
     */
    headerClass: {
      type: Number,
      default: 0
    },
    /**
     * Header alignment / TYPO3 header_position
     */
    headerPosition: {
      type: String,
      default: ''
    },
    /**
     * Header link / TYPO3 header_link
     */
    headerLink: {
      type: [String, Object],
      default: ''
    },
    /**
     * Subheader / TYPO3 subheader
     */
    subheader: {
      type: String,
      default: ''
    },
    /**
     * Header Type / TYPO3 make_it_carousel
     */
    makeItCarousel: {
      type: Number,
      default: 0
    },
  }
}
