export default {
  props: {
    baseUrl: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemsCount: {
      type: Number,
      default: 0,
    },
    itemsPerPage: {
      type: Number,
      default: 0,
    },
    categories: {
      type: Array,
      default: () => [],
    },
    selectedCategories: {
      type: Number,
    }
  },
  data() {
    return {
      store: 'blogposts',
      itemsArray: [],
      itemsFetched: 0,
      threshold: this.itemsPerPage / 2,
      filteredItemsArray: [],
      offset: 0,
    }
  },
  computed: {
    areThereMore() {
      return this.itemsArray.length < this.itemsCount
    },
    filter() {
      return this.$store.state.categories[this.store].filter
    }
  },
  watch: {
    itemsArray() {
      const oldFilteredItemsArray = this.filteredItemsArray
      this.filteredItemsArray = this.filterItems()
      this.itemsFetched -=
        this.filteredItemsArray.length - oldFilteredItemsArray.length
      if (this.areThereMore) {
        if (this.itemsFetched >= this.threshold && this.filter !== '*') {
          this.loadMore()
        }
      }
    },
    filter() {
      this.filteredItemsArray = this.filterItems()
    },
  },
  created() {
    this.itemsArray = this.itemsArray.concat(this.items)
  },
  methods: {
    filterItems () {
      const tempArray = []
      const filter = this.filter

      if (filter.length === 0) {
        return this.itemsArray
      }

      this.itemsArray.forEach(function (item) {
        item.categories.forEach(function (category) {
          if (!tempArray.includes(item) && category && (filter.includes(category.title))) {
            tempArray.push(item)
          }
        })
      })
      return tempArray
    },
    async loadMore() {
      if (this.areThereMore) {
        if (this.itemsFetched <= 0) {
          this.itemsFetched = this.itemsPerPage
        }
        this.offset += this.itemsPerPage
        const request = await this.$axios.get(this.$route.path, {
          params: { type: '836', offset: this.offset, no_cache: 1 },
        })
        const result = request.data
        this.itemsArray = this.itemsArray.concat(result.items)
      }
    },
  },
}
