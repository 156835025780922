var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "t3-ce-header",
        _vm._b({ staticClass: "mb-20" }, "t3-ce-header", _vm.$props, false)
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grid grid-cols-1 lg:grid-cols-3 gap-7" },
        _vm._l(_vm.priceboxItems, function (item, key) {
          return _c(
            "div",
            { key: key, staticClass: "col-span-1 mb-20 lg:mb-0" },
            [
              _c("PriceBoxItem", {
                attrs: { "pricebox-item": _vm.priceboxItems[key] },
              }),
            ],
            1
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }