var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "overflow-hidden absolute top-0 left-0 z-[9999] min-w-full h-screen transition-[left] duration-1500",
        class: { "left-0": _vm.isActive },
      },
      [
        _c("layout-nested-menu-panel", {
          attrs: {
            "nav-claim": _vm.claimFromParent
              ? _vm.navClaimFromParent
              : _vm.source.navClaim,
            "nav-claim-hide": _vm.navClaimHide,
            desktop: _vm.desktop,
            list: _vm.content_prevItem,
            "position-style": _vm.panel_prevPositionStyle,
            "is-translating": _vm.isTranslating,
            "transition-style": _vm.style_transitionStyle,
            "show-header-arrow": _vm.prevItemHasParent,
          },
        }),
        _vm._v(" "),
        _c("layout-nested-menu-panel", {
          attrs: {
            "nav-claim": _vm.claimFromParent
              ? _vm.navClaimFromParent
              : _vm.source.navClaim,
            "nav-claim-hide": _vm.navClaimHide,
            desktop: _vm.desktop,
            list: _vm.content_currentItem,
            "position-style": _vm.panel_stagingPositionStyle,
            "is-translating": _vm.isTranslating,
            "transition-style": _vm.style_transitionStyle,
            "show-header-arrow": _vm.currentItemHasParent,
            "handle-header-clicked": _vm.clickPrevItem,
            "handle-item-clicked": _vm.clickNextItem,
          },
        }),
        _vm._v(" "),
        _c("layout-nested-menu-panel", {
          attrs: {
            "nav-claim": _vm.claimFromParent
              ? _vm.navClaimFromParent
              : _vm.source.navClaim,
            "nav-claim-hide": _vm.navClaimHide,
            desktop: _vm.desktop,
            list: _vm.content_nextItem,
            "position-style": _vm.panel_nextPositionStyle,
            "is-translating": _vm.isTranslating,
            "transition-style": _vm.style_transitionStyle,
            "show-header-arrow": true,
          },
        }),
        _vm._v(" "),
        _c(
          "nuxt-link",
          {
            staticClass:
              "fixed inset-x-3 bottom-4 py-3 text-sm font-medium text-white uppercase rounded-md bg-primary lg:hidden",
            attrs: { to: _vm.contactLink },
          },
          [_vm._v(_vm._s(_vm.$t("contact")))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }