// Component to render content elements loop
export default {
  name: 'T3Renderer',
  functional: true,
  props: {
    /**
     * Array of content elements - colPos[x] from contentData
     */
    content: {
      type: Array,
      default: () => []
    },
    inContainer: {
      type: Boolean,
      default: false
    },
    /**
     * Control frame component displaying
     */
    frame: {
      type: Boolean,
      default: true
    }
  },
  render (createElement, ctx) {
    // render standalone component
    function renderComponent (element, index) {
      return createElement('t3-dynamic', {
        props: {
          data: element,
          type: element.type,
          index
        }
      })
    }
    // render component with frame wrapper
    function renderFrame (element, index) {
      return createElement(
        't3-frame',
        {
          props: {
            appearance: element.appearance,
            type: element.type,
            id: element.id
          }
        },
        [renderComponent(element, index)]
      )
    }
    if (ctx.props.inContainer) {
      return ctx.props.content.map((element, index) =>
        ctx.props.frame && element.renderedContent && element.renderedContent.appearance.frameClass !== 'none'
          ? renderFrame(element.renderedContent, index)
          : renderComponent(element.renderedContent, index)
      )
    } else {
      return ctx.props.content.map((element, index) =>
        ctx.props.frame && element.appearance.frameClass !== 'none'
          ? renderFrame(element, index)
          : renderComponent(element, index)
      )
    }
  }
}
