var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "-mx-10 flex flex-col h-full overflow-x-hidden" },
    [
      _c(
        "div",
        { staticClass: "px-10 header" },
        [_c("t3-ce-header", _vm._b({}, "t3-ce-header", _vm.$props, false))],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.formular,
              expression: "!formular",
            },
          ],
          staticClass:
            "w-full mb-[-1.5rem] md:mb-[-2rem] lg:mb-[-3.3rem] relative z-10",
        },
        [
          _c(
            "div",
            { staticClass: "flex" },
            _vm._l(_vm.data.catalog.questions, function (question, key) {
              return _c(
                "div",
                {
                  key: key,
                  class: ["w-1/" + _vm.data.catalog.questions.length],
                },
                [
                  _c("div", { staticClass: "relative mb-2" }, [
                    _vm.currentStep > key
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "absolute flex align-center items-center align-middle content-center w-full top-1/2 -translate-y-1/2 -mt-px",
                            class:
                              _vm.currentStep >
                              _vm.data.catalog.questions.length
                                ? ""
                                : "-translate-x-1/2",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "bg-gray-200 items-center align-middle align-center flex-1",
                                class:
                                  _vm.currentStep >
                                  _vm.data.catalog.questions.length
                                    ? "w-full"
                                    : "w-1/2",
                              },
                              [
                                _c("div", {
                                  staticClass:
                                    "bg-primary h-[3px] rounded w-full",
                                }),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "w-8 md:w-12 h-8 md:h-12 mx-auto rounded-full text-lg text-white flex items-center relative z-10 transition-all duration-150",
                        class:
                          _vm.currentStep > key + 1
                            ? "bg-primary"
                            : _vm.currentStep === key + 1
                            ? "bg-grey"
                            : "bg-gray-300",
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "text-center text-white w-full" },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(key + 1) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "hidden lg:block text-xs text-center uppercase font-medium transition-all duration-150",
                      class:
                        _vm.currentStep > key + 1
                          ? "text-primary"
                          : _vm.currentStep === key + 1
                          ? "text-grey"
                          : "text-gray-300",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(question.stepLabel) +
                          "\n        "
                      ),
                    ]
                  ),
                ]
              )
            }),
            0
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.formular,
              expression: "!formular",
            },
          ],
          staticClass:
            "flex-1 h-full bg-zinc-100 pt-20 pb-10 pb-10-safe content",
        },
        [
          _c(
            "div",
            { staticClass: "mx-auto max-w-5xl px-6" },
            [
              _vm._l(_vm.data.catalog.questions, function (question, key) {
                return _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.currentStep === key + 1,
                        expression: "currentStep === key + 1",
                      },
                    ],
                    key: key,
                  },
                  [
                    _c("h3", [_vm._v(_vm._s(question.label))]),
                    _vm._v(" "),
                    _c("p", { staticClass: "mb-8" }, [
                      _vm._v(_vm._s(question.description)),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "mb-8" },
                      _vm._l(question.answers, function (answer, answerKey) {
                        return _c(
                          "div",
                          {
                            key: answerKey,
                            staticClass: "flex items-center mb-4 bg-white p-4",
                          },
                          [
                            _c("input", {
                              staticClass: "opacity-0 absolute h-7 w-7",
                              attrs: {
                                id: `answers${key}-${answerKey}`,
                                type: "radio",
                                name: `answers${key}`,
                              },
                              domProps: { value: answer.points },
                              on: {
                                click: function ($event) {
                                  return _vm.setQuestionPoints(
                                    key,
                                    answer.points,
                                    question.label,
                                    answer.label
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "bg-white border rounded-md border-gray-200 w-6 h-6 flex shrink-0 justify-center items-center mr-2 focus-within:border-primary",
                              },
                              [
                                _c("IconCheckmark", {
                                  staticClass:
                                    "fill-current hidden w-3 h-3 text-primary pointer-events-none",
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass: "cursor-pointer",
                                attrs: { for: `answers${key}-${answerKey}` },
                              },
                              [_vm._v(_vm._s(answer.label))]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _vm.currentStep > 1
                      ? _c(
                          "button",
                          {
                            staticClass: "btn-quinary mr-3",
                            on: { click: _vm.goPreviousStep },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("previous")) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        class:
                          _vm.nextStep >= key + 1
                            ? "btn-primary"
                            : "btn-secondary",
                        on: {
                          click: function ($event) {
                            _vm.nextStep >= key + 1 ? _vm.goNextStep() : null
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(_vm.$t("next")) + "\n        "
                        ),
                      ]
                    ),
                  ]
                )
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.currentStep ===
                        _vm.data.catalog.questions.length + 1,
                      expression:
                        "currentStep === data.catalog.questions.length + 1",
                    },
                  ],
                  staticClass: "text-center md:text-left",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "md:flex md:justify-center md:items-center mt-8",
                    },
                    [
                      _c(
                        "div",
                        {
                          ref: "container",
                          staticClass: "mb-8 mx-auto md:mr-14 max-w-full",
                        },
                        [
                          _c(
                            "client-only",
                            [
                              _c(
                                "vue-ellipse-progress",
                                {
                                  staticClass: "mx-auto lg:scale-110",
                                  attrs: {
                                    progress: Math.floor(
                                      (_vm.currentPoints / _vm.totalPoints) *
                                        100
                                    ),
                                    "legend-value": _vm.currentPoints,
                                    size: 300,
                                    color: _vm.progressColor,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "legend-value" },
                                      slot: "legend-value",
                                    },
                                    [_vm._v("/" + _vm._s(_vm.totalPoints))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "p",
                                    {
                                      staticClass: "uppercase",
                                      attrs: { slot: "legend-caption" },
                                      slot: "legend-caption",
                                    },
                                    [_vm._v(_vm._s(_vm.$t("points")))]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _c("h3", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.data.catalog.results[_vm.resultKey].title
                              ) +
                              " "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "mb-8" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.data.catalog.results[_vm.resultKey]
                                  .description
                              ) +
                              " "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass: "btn-primary",
                            on: { click: _vm.showFormular },
                          },
                          [_vm._v(_vm._s(_vm.$t("getInTouch")))]
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.formular,
              expression: "formular",
            },
          ],
          staticClass: "pt-4 pb-10 pb-10-safe",
        },
        [
          _c("div", { staticClass: "mx-auto max-w-5xl px-10" }, [
            _c("h3", { staticClass: "mb-12" }, [
              _vm._v(_vm._s(_vm.$t("form.headline"))),
            ]),
            _vm._v(" "),
            _c(
              "form",
              {
                attrs: {
                  action: "https://go.suresecure.de/l/859003/2022-06-29/3gb6w5",
                  method: "POST",
                },
                on: { submit: _vm.checkForm },
              },
              [
                _c("input", {
                  attrs: { type: "hidden", name: "LeadSource", value: "Web" },
                }),
                _vm._v(" "),
                _c("input", {
                  attrs: {
                    type: "hidden",
                    name: "Confirmed_Opt_In_Source__c",
                    value: "Website_Form_Online_Check",
                  },
                }),
                _vm._v(" "),
                _c("input", {
                  attrs: {
                    type: "hidden",
                    name: "Interest___c",
                    value: "Online-Check",
                  },
                }),
                _vm._v(" "),
                _c("input", {
                  attrs: { type: "hidden", name: "UTM_Campaign_Name__c" },
                  domProps: { value: _vm.campaign },
                }),
                _vm._v(" "),
                _c("input", {
                  attrs: { type: "hidden", name: "UTM_Campaign_Source__c" },
                  domProps: { value: _vm.source },
                }),
                _vm._v(" "),
                _c("input", {
                  attrs: { type: "hidden", name: "UTM_Campaign_Medium__c" },
                  domProps: { value: _vm.medium },
                }),
                _vm._v(" "),
                _c("input", {
                  attrs: {
                    type: "hidden",
                    name: "Newsletter_Opt_In_Timestamp__c",
                  },
                  domProps: { value: _vm.fullDateTime },
                }),
                _vm._v(" "),
                _c("input", {
                  attrs: { type: "hidden", name: "Online_Check_Output__c" },
                  domProps: { value: _vm.selectedAnswers },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "select",
                    {
                      attrs: {
                        id: "salutation",
                        name: "Salutation",
                        required: "",
                      },
                    },
                    [
                      _c("option", { attrs: { hidden: "" } }),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "Mr." } }, [
                        _vm._v(_vm._s(_vm.$t("form.salutations.mr"))),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "Mrs." } }, [
                        _vm._v(_vm._s(_vm.$t("form.salutations.mrs"))),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "salutation" } }, [
                    _vm._v(_vm._s(_vm.$t("form.salutation"))),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("input", {
                    attrs: {
                      maxlength: "40",
                      name: "FirstName",
                      size: "20",
                      type: "text",
                      placeholder: "first_name",
                      required: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "first_name" } }, [
                    _vm._v(_vm._s(_vm.$t("form.first_name"))),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("input", {
                    attrs: {
                      maxlength: "80",
                      name: "LastName",
                      size: "20",
                      type: "text",
                      placeholder: "last_name",
                      required: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "last_name" } }, [
                    _vm._v(_vm._s(_vm.$t("form.last_name"))),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("input", {
                    attrs: {
                      maxlength: "80",
                      name: "email",
                      size: "20",
                      type: "email",
                      placeholder: "email",
                      required: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "email" } }, [
                    _vm._v(_vm._s(_vm.$t("form.email"))),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("input", {
                    ref: "phone",
                    attrs: {
                      maxlength: "40",
                      name: "Phone",
                      size: "20",
                      type: "text",
                      placeholder: "phone",
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "phone" } }, [
                    _vm._v(_vm._s(_vm.$t("form.phone"))),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("input", {
                    attrs: {
                      maxlength: "40",
                      name: "Company",
                      size: "20",
                      type: "text",
                      placeholder: "company",
                      required: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "company" } }, [
                    _vm._v(_vm._s(_vm.$t("form.company"))),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "select",
                    {
                      attrs: {
                        id: "interest",
                        name: "Interest___c",
                        required: "",
                      },
                    },
                    [
                      _c("option", { attrs: { hidden: "" } }),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "Initial interview" } }, [
                        _vm._v(_vm._s(_vm.$t("form.initialInterview"))),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "Urgent incident" } }, [
                        _vm._v(_vm._s(_vm.$t("form.urgentIncident"))),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "interest" } }, [
                    _vm._v(_vm._s(_vm.$t("form.interest"))),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("textarea", {
                    attrs: {
                      name: "Description",
                      placeholder: "description",
                      required: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "description" } }, [
                    _vm._v(_vm._s(_vm.$t("form.description"))),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group !mb-0 checkbox" },
                  [
                    _c("input", {
                      attrs: {
                        id: [`privacy_policy-${_vm.id}`],
                        name: "GDPR_Data_Processing_Accepted__c",
                        type: "checkbox",
                        value: "true",
                        placeholder: "privacy_policy",
                        required: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("i18n", {
                      attrs: {
                        path: "form.privacyPolicy",
                        tag: "label",
                        for: [`privacy_policy-${_vm.id}`],
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "link",
                          fn: function () {
                            return [
                              _c(
                                "a",
                                {
                                  staticClass: "text-primary font-medium",
                                  attrs: { href: "/datenschutzerklaerung" },
                                },
                                [_vm._v(_vm._s(_vm.$t("form.link")))]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group checkbox" },
                  [
                    _c("input", {
                      ref: "newsletter",
                      attrs: {
                        id: [`newsletter-${_vm.id}`],
                        name: "Newsletter_Opt_In__c",
                        type: "checkbox",
                        value: "",
                        placeholder: "newsletter",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.printFullDate()
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "i18n",
                      {
                        attrs: {
                          path: "form.newsletter",
                          tag: "label",
                          for: [`newsletter-${_vm.id}`],
                        },
                        on: {
                          click: function ($event) {
                            return _vm.printFullDate()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("form.newsletter")) +
                            "\n          "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("input", {
                  staticClass: "btn btn-primary btn-lg w-full",
                  attrs: { type: "submit", name: "submit" },
                }),
              ]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }