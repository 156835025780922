<template>
  <div>
    <button
      class="group text-white rounded-full hover:shadow-lg hover:shadow-neutral-600 transition-shadow duration-300 bg-primary"
      @click="scrollToTop()"
    >
      <arrow-top-icon
        class="m-2 w-7 h-7 transition-all duration-300 group-hover:-translate-y-1 lg:w-9 lg:h-9"
      />
    </button>
  </div>
</template>
<script>
import ArrowTopIcon from '@/assets/icons/arrow-top-outline.svg?inline'
export default {
  components: {
    ArrowTopIcon,
  },
  methods: {
    scrollToTop() {
      this.$gsap.to(window, { duration: 1, scrollTo: { X: 0, y: 0 } })
    },
  },
}
</script>
