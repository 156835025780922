<template>
  <t3-nav-link
    :to="content.slug"
    :title="content.teaser_title"
    class="block transition-all duration-300 hover:shadow-lg"
  >
    <figure v-if="(content.teaserImage[0] || content.media[0])" class="img-wrapper">
      <img
        v-if="content.teaserImage[0]"
        v-lazy-load :data-src="content.teaserImage[0].publicUrl"
        :alt="content.header"
        :width="content.teaserImage[0].properties.dimensions.width"
        :height="content.teaserImage[0].properties.dimensions.height"
        class="rounded-t-md w-full"
      >
      <img
        v-else-if="content.media.length > 0"
        v-lazy-load :data-src="content.media[0].publicUrl"
        :alt="content.header"
        :width="content.media[0].properties.dimensions.width"
        :height="content.media[0].properties.dimensions.height"
        class="rounded-t-md w-full"
      >
    </figure>
    <div class="px-5 pb-5 bg-white rounded-b-md" :class="{'mt-[13px]': (!content.teaserImage[0] && !content.media[0])}">
      <ContentPartialsNewsCategoryBadge v-if="label || content.categories.length" :label="label" :category="content.categories.length > 0 ? content.categories[0] : []" />
      <p class="text-quinary text-xs">{{ $moment.unix(content.starttime ? content.starttime : content.crdate).format('DD/MM/YYYY') }}</p>
      <p class="h6 font-bold">{{content.teaser_title ? content.teaser_title : content.title}}</p>
      <p v-if="first == 0" class="mb-5 text-md">
        {{ content.teaser_text }}
      </p>
      <p v-else class="mb-2 text-md">
          {{ content.teaser_text | truncate(75) }}
      </p>
      <span
        class="link link-arrow text-sm hover:text-boxed"
      >
        {{ $t('continueReading') }}
      </span>
    </div>
  </t3-nav-link>
</template>
<script>
export default {
  filters: {
      truncate(data,num){
          const reqdString =  data.split("").slice(0, num).join("");
          return reqdString;
      }
  },
  props: {
    content: {
      type: [Object, Array],
      default: () => []
    },
    label: {
      type: String,
      default: ''
    },
    first: {
      type: Number,
      default: 0
    }
  }
}
</script>
<style lang="postcss" scoped>
  .img-wrapper {
    @apply relative overflow-hidden rounded-t-md;
    aspect-ratio: 16/9;
    img {
      @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;
    }
  }
</style>