<template>
  <div class="no-frame-margin">
    <div v-if="tab.content.tabItemContentAside.length > 0">
      <t3-ce-suresecure-cols-eighty-twenty v-if="tab.content.contentOrientation === '80/20'" :left="cleanTabContent" :right="cleanTabContentAside" :fake-container="false" />
      <t3-ce-suresecure-cols-twenty-eighty v-else :left="cleanTabContent" :right="cleanTabContentAside" :fake-container="false" />
    </div>
    <div v-else>
      <t3-renderer :content="cleanTabContent" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    tab: {
      type: Object,
      required: true
    }
  },
  computed: {
    cleanTabContent() {
      const cleanTabContent = []

      this.tab.content.tabItemContent.forEach(function( item ) {
        cleanTabContent.push(item.item)
      })

      return cleanTabContent
    },
    cleanTabContentAside() {
      const cleanTabContentAside = []

      this.tab.content.tabItemContentAside.forEach(function( item ) {
        cleanTabContentAside.push(item.item)
      })

      return cleanTabContentAside
    }
  }
}
</script>
<style lang="postcss">
  .no-frame-margin {
    [class*="frame-space-before"] {
      @apply !mt-0 lg:mt-auto;
    }
  }
</style>
