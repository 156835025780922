var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "t3-ce-textpic",
    _vm._b(
      { staticClass: "t3-ce-textmedia" },
      "t3-ce-textpic",
      _vm.$props,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }