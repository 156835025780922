var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "social" }, [
    _vm.link
      ? _c("a", { attrs: { href: _vm.link, target: "_blank" } }, [
          _vm.backgroundImage[0]
            ? _c("img", {
                directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
                attrs: {
                  "data-src": _vm.backgroundImage[0].publicUrl,
                  alt: _vm.title,
                  width: _vm.backgroundImage[0].properties.dimensions.width,
                  height: _vm.backgroundImage[0].properties.dimensions.height,
                },
              })
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }