<template>
  <div class="social">
    <a v-if="link" :href="link" target="_blank">
      <img     
        v-if="backgroundImage[0]" 
        v-lazy-load :data-src="backgroundImage[0].publicUrl" 
        :alt="title"
        :width="backgroundImage[0].properties.dimensions.width"
        :height="backgroundImage[0].properties.dimensions.height"        
      >
    </a>
  </div>
</template>
<script>
import baseCe from '../mixins/baseCe'
export default {
  name: 'T3SuresecureSocialIcon',
  extends: baseCe,
  props: {
    title: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    },
    backgroundImage: {
      type: Array,
      default: () => []
    }
  }
}
</script>
<style lang="postcss">
.social {
  img {
    @apply transition-all duration-300 opacity-[.191] hover:scale-105 hover:opacity-100;
  }
}
</style>
