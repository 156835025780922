var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "text-center lg:text-right when-nested" }, [
    _vm.backgroundImage.length > 0 && _vm.hideLink === 0
      ? _c(
          "a",
          {
            staticClass:
              "corner relative inline-block text-left hover:scale-105 transition-all hover:shadow-xl",
            attrs: { href: _vm.backgroundImage[0].publicUrl, target: "_blank" },
          },
          [
            _c("span", { staticClass: "badge" }, [_vm._v("PDF")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "absolute inset-x-0 bottom-0 px-5 py-10" },
              [
                _c("p", { staticClass: "h5 text-white font-medium text-md" }, [
                  _vm._v(_vm._s(_vm.title)),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "text-white text-sm" }, [
                  _vm._v(_vm._s(_vm.generalText)),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "content-pdf-download-bg max-w-full" }),
          ]
        )
      : _c("div", { staticClass: "corner relative inline-block text-left" }, [
          _c("span", { staticClass: "badge" }, [_vm._v("PDF")]),
          _vm._v(" "),
          _c("div", { staticClass: "absolute inset-x-0 bottom-0 px-5 py-10" }, [
            _c("p", { staticClass: "h5 text-white font-medium text-md" }, [
              _vm._v(_vm._s(_vm.title)),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "text-white text-sm" }, [
              _vm._v(_vm._s(_vm.generalText)),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "content-pdf-download-bg max-w-full" }),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }