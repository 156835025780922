<template>
  <div>
    <input type="hidden" name="Confirmed_Opt_In_Source__c" value="Website_Form_Contact">

    <input type="hidden" name="Newsletter_Opt_In_Timestamp__c" :value="fullDateTime">

    <div class="form-group">
      <select id="salutation" name="Salutation" required>
        <option hidden></option>
        <option value="Mr.">{{ $t('form.salutations.mr') }}</option>
        <option value="Mrs.">{{ $t('form.salutations.mrs') }}</option>
      </select>
      <label for="salutation">{{ $t('form.salutation') }}</label>
    </div>
      <div class="flex gap-4">
        <div class="form-group flex-1">
          <input
            maxlength="40"
            name="FirstName"
            size="20"
            type="text"
            placeholder="first_name"
            required
          />
          <label for="first_name">{{ $t('form.first_name') }}</label>
        </div>
        <div class="form-group flex-1">
          <input
            maxlength="80"
            name="LastName"
            size="20"
            type="text"
            placeholder="last_name"
            required
          />
          <label for="last_name">{{ $t('form.last_name') }}</label>
        </div>
      </div>
    <div class="form-group">
      <input
        v-model="email"
        maxlength="80"
        name="email"
        size="20"
        type="email"
        placeholder="email"
        required
      />
      <label for="email">{{ $t('form.businessEmail') }}</label>
      <p style="color: red">{{ errorMessage }}</p>
    </div>
    <div class="form-group">
      <textarea
        name="Description"
        placeholder="Description"
        required>
      </textarea>
      <label for="description">{{ $t('form.comment') }}</label>
    </div>
    <div class="form-group !mb-0 checkbox">
      <input
        :id="[`privacy_policy-${unique}`]"
        name="GDPR_Data_Processing_Accepted__c"
        type="checkbox"
        value="true"
        placeholder="privacy_policy"
        required
      />
      <i18n path="form.privacyPolicy" tag="label" :for="[`privacy_policy-${unique}`]">
        <template #link>
          <a class="text-primary font-medium" :href="$t('form.linkPrivacyPolicy')">{{ $t('form.link') }}</a>
        </template>
      </i18n>
    </div>
    <div class="form-group checkbox">
      <input
        :id="[`newsletter-${unique}`]"
        ref="newsletter"
        name="Newsletter_Opt_In__c"
        type="checkbox"
        value=""
        placeholder="newsletter"
        @click="printFullDate()"
      />
      <i18n path="form.newsletter" tag="label" :for="[`newsletter-${unique}`]" @click="printFullDate()">
        {{ $t('form.newsletter') }}
      </i18n>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    unique: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      fullDateTime: '',
      email: '',
      errorMessage: ''
    }
  },
    watch: {
      email(value){
        this.email = value;
        this.validateEmail(value);
      }
    },
    methods: {
    printFullDate() {
      if(this.$refs.newsletter.value === '') {
        this.$refs.newsletter.value = true
        this.fullDateTime = new Date()
      } else {
        this.$refs.newsletter.value = ''
          this.fullDateTime = ''
      }
    },
    validateEmail(email) {
      const preventMails = [
        '@gmail.com',
        '@outlook.com',
        '@hotmail.com',
        '@live.de',
        '@gmx.de',
        '@t-online.de',
        '@web.de',
        '@yahoo.com'
      ];

      for(let i=0; i < preventMails.length; i++){
        if(email.includes(preventMails[i])){
          this.errorMessage = this.$t('form.noBusinessMail')
          this.$emit('blockSubmit')
          return
        } else {
          this.errorMessage = ''
          this.$emit('unblockSubmit')
        }
      }
    }
  },
}
</script>
