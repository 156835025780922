var render = function render(_c, _vm) {
  return _c(
    "video",
    _vm._b(
      {
        staticClass: "t3-ce-media-video",
        class: [_vm.data.class, _vm.data.staticClass],
        attrs: { controls: "" },
      },
      "video",
      _vm.data.attrs,
      false
    ),
    [
      _c("source", {
        directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
        attrs: {
          "data-src": _vm.props.file.publicUrl,
          height: _vm.props.file.properties.dimensions.height,
          width: _vm.props.file.properties.dimensions.width,
          type: _vm.props.file.properties.mimeType,
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }