var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("t3-ce-header", _vm._b({}, "t3-ce-header", _vm.$props, false)),
      _vm._v(" "),
      _c("t3-html-parser", { attrs: { content: _vm.bodytext } }),
      _vm._v(" "),
      _vm.technoStackItems
        ? _c(
            "div",
            {
              staticClass:
                "mt-10 grid gap-3 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gab-4",
            },
            _vm._l(_vm.technoStackItems, function (item, Key) {
              return _c(
                "div",
                { key: Key, staticClass: "col-span-1 m-auto w-full h-full" },
                [
                  _c(
                    item.properties.link ? "t3-nav-link" : "div",
                    {
                      tag: "component",
                      staticClass:
                        "effect p-5 sm:p-10 rounded-md h-full flex items-center justify-center",
                      class: {
                        lighten: item.properties.lightenBackground == 1,
                      },
                      attrs: { to: item.properties.link },
                    },
                    [
                      _c("figure", [
                        _c("img", {
                          directives: [
                            { name: "lazy-load", rawName: "v-lazy-load" },
                          ],
                          staticClass: "max-h-24 w-auto",
                          attrs: {
                            "data-src": item.publicUrl,
                            height: item.properties.dimensions.height,
                            width: item.properties.dimensions.width,
                            alt: item.properties.alternative || false,
                            title: item.properties.title || "",
                          },
                        }),
                        _vm._v(" "),
                        item.properties.description
                          ? _c("figcaption", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(item.properties.description) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  ),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }