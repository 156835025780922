<template>
  <div class="overflow-hidden">
    <div class="relative my-12">
      <span
        class="block w-16 h-16 rounded-full bg-tertiary absolute z-10 -top-8 overflow-hidden"
      >
        <img
          v-lazy-load data-src="~/assets/images/flow-start.svg"
          class="absolute bottom-0 left-2.5 w-12 h-12 z-10"
        />
      </span>
      <canvas
        :id="`flowCanvas${id}`"
        ref="canvas"
        class="absolute mx-auto z-0 pointer-events-none transition-all"
      />
      <div ref="effect" class="effect">
        <div ref="magic"></div>
      </div>
      <div ref="flowContainer" class="mb-10">
        <div
          v-for="(flowItem, key) in flowItems"
          :key="key"
          ref="items"
          class="relative z-20 flow-item mx-auto transition-all"
          :class="[
            flowItem.content.variant,
            flowItem.content.imageAsIcon === 1 ? 'icon' : '',
          ]"
        >
          <span
            class="bg-primary rounded-md p-3 pr-7 pl-3 text-white text-xs uppercase absolute -top-6 left-1/2 -translate-x-1/2 inline-flex items-center font-medium"
          >
            <span
              class="text-[10px] inline-block h-5 w-5 center text-center rounded-full bg-black/30 mr-3"
              >{{ key + 1 }}</span
            >
            {{ flowItem.content.header }}
          </span>
          <div
            ref="fade"
            class="content lg:flex items-center gap-4"
            :class="key % 2 ? 'lg:flex-row' : 'lg:flex-row-reverse'"
          >
            <div
              v-if="flowItem.content.imageAsIcon === 0"
              class="image w-full md:w-[300px] shrink-0"
            >
              <img
                v-if="flowItem.content.image.length > 0"
                ref="images"
                data-not-lazy
                v-lazy-load :data-src="flowItem.content.image[0].publicUrl"
                class="w-full mb-4 md:mb-0 transition-all"
                @load="drawLine()"
              />
            </div>
            <div v-else class="mx-auto lg:m-0 my-4 image">
              <div
                v-if="flowItem.content.image.length > 0"
                class="bg-tertiary w-20 h-20 mx-auto lg:mx-0 rounded-full relative"
              >
                <InlineSvg
                  :image="flowItem.content.image[0]"
                  :title="flowItem.content.header"
                  class="absolute z-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full max-w-[60px]"
                />
              </div>
            </div>
            <t3-html-parser              
              :content="flowItem.content.bodytext"
              class="text-white text-base font-normal bodytext transition-all duration-300"
            />
          </div>
        </div>
      </div>
        <a v-if="link" :href="link" class="btn-primary btn-lg absolute bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 md:min-w-[500px] text-center z-20">
            {{ buttonText || $t('readMore') }}
        </a>

    </div>
  </div>
</template>
<script>
import baseCe from '../mixins/baseCe'
import InlineSvg from '../partials/InlineSvg'
export default {
  name: 'T3CeSureseccureFlow',
  components: {
    InlineSvg
  },
  extends: baseCe,
  props: {
    flowItems: {
      type: Array,
      required: true,
      default: () => [],
    },
    link: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: '',
    },
    imageAsIcon: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    window.addEventListener('resize', this.drawLine)
    window.addEventListener('scroll', this.magicDraw)
    window.addEventListener('scroll', this.magicFade)
    this.drawLine()
  },
  destroyed() {
    window.removeEventListener('resize', this.drawLine)
    window.removeEventListener('scroll', this.magicDraw)
    window.removeEventListener('scroll', this.magicFade)
  },
  methods: {
    drawLine() {
      const canvas = document.getElementById('flowCanvas' + this.id)
      const ctx = canvas.getContext('2d')
      canvas.height = this.$refs.flowContainer.offsetHeight + 200
      canvas.width = this.$refs.flowContainer.clientWidth
      ctx.beginPath()
      ctx.moveTo(canvas.offsetLeft + 15, canvas.offsetTop + 0.5)
      const that = this
      const cornerRadius = 15
      let lastlineY = ''
      this.flowItems.forEach(function (item, key) {
        const elem = that.$refs.items[key]
        if (elem.querySelectorAll('.image img').length > 0) {
          elem
            .querySelectorAll('.content')[0]
            .style.setProperty(
              '--offset',
              elem.classList.contains('icon')
                ? elem.querySelectorAll('.image')[0].clientWidth + 'px'
                : elem.querySelectorAll('.image img').length > 0
                ? elem.querySelectorAll('.image img')[0].clientWidth + 'px'
                : elem.querySelectorAll('.image svg').clientWidth + 'px'
            )
        }
        const side =
          key % 2
            ? elem.offsetLeft + 0.5
            : elem.offsetLeft + elem.offsetWidth - 0.5
        if (key % 2) {
          ctx.lineTo(side + cornerRadius, elem.offsetTop + 0.5)
        } else {
          ctx.lineTo(side - cornerRadius, elem.offsetTop + 0.5)
        }
        ctx.arcTo(
          side,
          elem.offsetTop + 0.5,
          side,
          elem.offsetTop + 0.5 + cornerRadius,
          cornerRadius
        )
        ctx.lineTo(
          side,
          elem.offsetTop + elem.offsetHeight - cornerRadius - 0.5
        )
        ctx.arcTo(
          side,
          elem.offsetTop + elem.offsetHeight + 0.5,
          key % 2 ? side + cornerRadius : side - cornerRadius,
          elem.offsetTop + elem.offsetHeight + 0.5,
          cornerRadius
        )
        lastlineY = elem.offsetTop + elem.offsetHeight + 0.5
      })
      ctx.lineTo(this.$refs.flowContainer.offsetWidth / 2, lastlineY)
      ctx.lineWidth = 1
      ctx.strokeStyle = '#89BD2A'
      ctx.stroke()
    },
    magicDraw() {
      const scrollPosition = document.documentElement.scrollTop
      const screen = window.innerHeight
      const position = this.$refs.effect.getBoundingClientRect().top
      const elDistanceToTop = window.pageYOffset + position      
      if (position < screen/2) {                  
          this.$refs.magic.style.top = (scrollPosition+screen*.6)-elDistanceToTop + "px"          
      } else {
        this.$refs.magic.style.top = 0
      }      
    },
    magicFade() {     
      const screen = window.innerHeight      
      this.$refs.fade.forEach((el) => {      
        if (el.getBoundingClientRect().top < screen*.6) {                         
          el.classList.add('fade-in')          
        }
      })
    }
  },
}
</script>
<style lang="postcss" scoped>
.flow-item {
  .bodytext h2 {
    font-size: 2rem;
  }
  @apply mx-auto max-w-4xl p-5 py-10 lg:p-20 z-20;
  &.medium {
    @apply max-w-2xl;
  }
  &.small {
    @apply max-w-xl;
  }
  .bodytext {
    /deep/ {
      p {
        &:last-child {
          @apply lg:mb-0;
        }
      }
    }
  }
  .content {
    @apply xl:mr-0 xl:ml-[calc((var(--offset)/2+5rem)*-1)] xl:translate-x-[calc((var(--offset)/2)+5rem)];
    /deep/ {
      .bodytext {
        @apply opacity-0 translate-x-1/2;
      }
    }
    &[class*='-reverse'] {
      /deep/ {
        .bodytext {
          @apply -translate-x-1/2;
        }
      }
    }
    &.fade-in {
      /deep/ {
        .bodytext {
          @apply opacity-100 translate-x-0;
        }
      }
    }
  }

  &:nth-child(even) {
    .content {
      @apply xl:translate-x-[calc(((var(--offset)/2)+5rem)*-1)] xl:ml-0 xl:mr-[calc(((var(--offset)/2)+5rem)*-1)];
    }
  }
  &:last-child {
    @apply pb-20;
  }
}
.effect {
  div {
    @apply absolute -top-0 right-0 -bottom-1 left-0 content-[''] block bg-secondary z-[1];
  }
}
</style>
