<template>
  <div id="page-wrap">
    <div class="bg-secondary">
      <layout-quick-menu />
      <layout-main-header />
    </div>
    <div
      class="lg:flex-1 lg:transition-all"
      :class="isBlurContent ? 'md:blur md:after:block md:after:content-[\'\'] md:after:fixed md:after:inset-0 md:after:bg-white/5 md:after:z-50' : ''"
    >
      <nuxt />
      <div class="relative bg-white">
        <layout-to-top
          class="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2"
        />
        <layout-footer-meta-nav />
      </div>
    </div>
    <layout-incident-response class="fixed bottom-4 right-4 z-[999]" />
  </div>
</template>
<script>
export default {
  name: 'DefaultLayout',
  data() {
    return {
      isBlurContent: false,
    }
  },
  created() {
    this.$nuxt.$on('layout-blur-content', () => {
      this.blurContent()
    })
    this.$nuxt.$on('layout-unblur-content', () => {
      this.unblurContent()
    })
  },
  beforeDestroy() {
    this.$nuxt.$off('layout-blur-content')
    this.$nuxt.$off('layout-unblur-content')
  },
  methods: {
    blurContent() {
      this.isBlurContent = true
    },
    unblurContent() {
      this.isBlurContent = false
    },
  },
}
</script>
