var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "relative" },
    [
      _c("div", { staticClass: "sm:float-right text-right" }, [
        _c(
          "span",
          {
            staticClass:
              "cursor-pointer w-10 h-10 inline-block rotate-180 hover:bg-grey/20 rounded-full",
            on: { click: _vm.swipeLeft },
          },
          [
            _c("IconArrowForward", {
              staticClass: "text-zinc-400 hover:text-zinc-100",
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass:
              "cursor-pointer w-10 h-10 inline-block hover:bg-grey/20 rounded-full",
            on: { click: _vm.swipeRight },
          },
          [
            _c("IconArrowForward", {
              staticClass: "text-zinc-400 hover:text-zinc-100",
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "t3-ce-header",
        _vm._b(
          { staticClass: "after:clear-both after:block" },
          "t3-ce-header",
          _vm.$props,
          false
        )
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "snap",
          staticClass:
            "lg:mr-[calc((var(--screen-width)-var(--current-screen))/-2+7.5px)] overflow-x-auto snap-x snap-mandatory hide-scrollbar",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "flex flex-col flex-wrap content-between h-[440px] lg:h-[860px] gap-x-5 gap-y-4 p-5",
            },
            _vm._l(_vm.items, function (item, key) {
              return _c(
                "div",
                {
                  key: key,
                  ref: "items",
                  refInFor: true,
                  staticClass:
                    "snap-start break-inside-avoid transition-transform hover-style",
                  class:
                    key === 0
                      ? "w-[332px] lg:w-[482px] break-after-column"
                      : "w-[332px]",
                },
                [
                  item.doktype === 116
                    ? _c("blog-item", {
                        attrs: { first: key, content: item, label: "Blog" },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  item.doktype === 117
                    ? _c("event-item", {
                        attrs: { first: key, content: item, label: "Event" },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  item.doktype === 118
                    ? _c("podcast-news-list-item", {
                        attrs: {
                          first: key,
                          content: item,
                          "list-page": _vm.podcastList,
                          label: "Podcast",
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }