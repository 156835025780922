<template>
    <span v-if="label || category" class="rounded-md bg-primary px-5 py-2 text-white font-bold text-xs uppercase relative -top-4">
        <template v-if="label">
            {{ label }}
        </template>
        <template v-else>
            {{ category.title }}
        </template>
    </span>
</template>
<script>
export default {
    props: {
        label: {
            type: String,
            default: ''
        },
        category: {
            type: [Array, Object],
            default: () => []
        }
    }
}
</script>

