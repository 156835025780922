import { render, staticRenderFns } from "./T3CeSuresecureContactPerson.vue?vue&type=template&id=60eec57e&scoped=true"
import script from "./T3CeSuresecureContactPerson.vue?vue&type=script&lang=js"
export * from "./T3CeSuresecureContactPerson.vue?vue&type=script&lang=js"
import style0 from "./T3CeSuresecureContactPerson.vue?vue&type=style&index=0&id=60eec57e&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60eec57e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/vhosts/suresecure.de/dev.suresecure.de/releases/20231128/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('60eec57e')) {
      api.createRecord('60eec57e', component.options)
    } else {
      api.reload('60eec57e', component.options)
    }
    module.hot.accept("./T3CeSuresecureContactPerson.vue?vue&type=template&id=60eec57e&scoped=true", function () {
      api.rerender('60eec57e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/content/elements/T3CeSuresecureContactPerson.vue"
export default component.exports