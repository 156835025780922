<template>
  <t3-nav-link
    :to="content.slug"
    :title="content.teaser_title"
    class="block transition-all duration-300 hover:shadow-lg"
  >
    <figure v-if="(content.teaserImage[0] || content.media[0])" class="img-wrapper">
      <img
        v-if="content.teaserImage[0]"
        v-lazy-load :data-src="content.teaserImage[0].publicUrl"
        :alt="content.header"
        :width="content.teaserImage[0].properties.dimensions.width"
        :height="content.teaserImage[0].properties.dimensions.height"
        class="rounded-t-md w-full"
      >
      <img
        v-else-if="content.media.length > 0"
        v-lazy-load :data-src="content.media[0].publicUrl"
        :alt="content.header"
        :width="content.media[0].properties.dimensions.width"
        :height="content.media[0].properties.dimensions.height"
        class="rounded-t-md w-full"
      >
    </figure>
    <div class="px-5 pb-5 bg-white rounded-b-md" :class="{'mt-[13px]': (!content.teaserImage[0] && !content.media[0])}">
      <ContentPartialsNewsCategoryBadge v-if="label || content.categories.length" :label="label" :category="content.categories.length > 0 ? content.categories[0] : []" />
      <p class="h6 font-bold">{{content.teaser_title ? content.teaser_title : content.title}}</p>
      <ul class="flex flex-col gap-5 mt-3 mb-5">
          <li v-if="(content.event_date)" class="flex gap-7 items-center text-md">
              <div class="flex items-center relative before:content-[''] before:block before:bg-primary before:rounded-full before:w-9 before:h-9 before:absolute before:z-[1]">
                  <IconCalendar class="ml-[7px] h-5 w-5 z-10" />
              </div>
              {{ content.event_date }}
          </li>

        <li v-else class="flex gap-7 items-center text-md">
          <div class="flex items-center relative before:content-[''] before:block before:bg-primary before:rounded-full before:w-9 before:h-9 before:absolute before:z-[1]">
            <IconCalendar class="ml-[7px] h-5 w-5 z-10" />
          </div>
          {{ $moment.unix(content.starttime).format('DD.MM.YYYY') }}
        </li>
          <li  v-if="(content.event_city)" class="flex gap-7 items-center text-md">
              <div class="flex items-center relative before:content-[''] before:block before:bg-primary before:rounded-full before:w-9 before:h-9 before:absolute before:z-[1]">
                  <IconMapMarker class="ml-[8px] h-5 w-5 z-10" />
              </div>
              {{ content.event_city }}
          </li>
      </ul>
      <span
        class="link link-arrow text-sm hover:text-boxed"
      >
        {{ $t('readMore') }}
      </span>
    </div>
  </t3-nav-link>
</template>
<script>
import IconCalendar from '~/assets/icons/calendar.svg?inline'
import IconMapMarker from '~/assets/icons/map-marker.svg?inline'
export default {
  components: {
    IconCalendar,
    IconMapMarker
  },
  props: {
    content: {
      type: Object,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    first: {
      type: Number,
      default: 0
    }
  }
}
</script>
<style lang="postcss" scoped>
  .img-wrapper {
    @apply relative overflow-hidden rounded-t-md;
    aspect-ratio: 16/9;
    img {
      @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;
    }
  }
</style>
