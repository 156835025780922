var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.label || _vm.category
    ? _c(
        "span",
        {
          staticClass:
            "rounded-md bg-primary px-5 py-2 text-white font-bold text-xs uppercase relative -top-4",
        },
        [
          _vm.label
            ? [_vm._v("\n        " + _vm._s(_vm.label) + "\n    ")]
            : [_vm._v("\n        " + _vm._s(_vm.category.title) + "\n    ")],
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }