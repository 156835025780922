<template>
  <div class="t3-ce-uploads">
    <t3-ce-header v-bind="$props" />
    <ul v-if="media">
      <li v-for="(file, key) in media" :key="key" class="download">
        <t3-nav-link :to="file.properties.modalLink !== '' ? file.properties.modalLink.href : file.publicUrl" :target="target" class="flex items-center gap-5">
          <span v-if="displayInformation == 1" class="t3-ce-loads__icon">
            <IconDownload class="w-10 h-10 absolute top-1/2 left-1/2 translate-x-[-57%] -translate-y-1/2" />
          </span>
          <span 
            v-if="displayInformation === 2 && file.properties.type === 'image'"
            class="t3-ce-uploads__thumb"
          >
            <t3-ce-media-file :file="file" />
          </span>
          <span class="t3-ce-uploads__name text-lg">
            {{ file.properties.title || file.publicUrl }}
          </span>
          <span v-if="displayFileSizeInformation == 1" class="t3-ce-uploads__size text-lg">
            {{ file.properties.size }}
          </span>
          <span class="ml-auto text-sm">
            <IconArrowForward class="arrow-right" />
            DOWNLOAD            
          </span>
        </t3-nav-link>
        <p 
          v-if="displayDescription && file.properties.description"
          class="t3-ce-uploads__desc"
        >
          {{ file.properties.description }}
        </p>
      </li>
    </ul>
  </div>
</template>
<script>
import baseCe from '../mixins/baseCe'
import UrlMixins from '~typo3/mixins/utils/urls'
import IconArrowForward from '~/assets/icons/arrow-forward-outline-quinary.svg?inline'
import IconDownload from '~/assets/icons/pdf-download.svg?inline'
export default {
  name: 'T3CeUploads',
  components: {
    IconArrowForward,
    IconDownload
  },
  extends: baseCe,
  mixins: [UrlMixins],
  props: {
    media: {
      type: Array,
      required: true
    },
    displayDescription: {
      type: String,
      default: ''
    },
    displayFileSizeInformation: {
      type: String,
      default: ''
    },
    displayInformation: {
      type: String,
      default: ''
    },
    target: {
      type: String,
      default: ''
    }
  },
  methods: {
    getExtensionImg (extension) {
      return this.getApiBaseUrl(
        `typo3/sysext/frontend/Resources/Public/Icons/FileIcons/${extension}.gif`
      )
    },
    onError (err) {
      // resolve default icon on error
      // @todo make it nicer
      // https://github.com/TYPO3/TYPO3.CMS/tree/master/typo3/sysext/core/Resources/Public/Icons/T3Icons/mimetypes
      err.target.src = this.getExtensionImg('default')
    }
  }
}
</script>
<style lang="postcss">
  .download {
    @apply relative px-4 py-3 rounded-lg mb-5 cursor-pointer bg-boxed font-medium hover:scale-[101%] transition-all duration-300;
    .t3-ce-loads__icon {
      @apply block bg-white/10 w-20 h-20 rounded-full relative;
    }
    .arrow-right {
      @apply w-5 h-5 ml-4 float-right transition-all duration-300;
    }
    &:hover {
      @apply pr-2;
      .arrow-right {
        @apply ml-5;
      }
    }
  }
</style>