<template>
<div class="when-nested">
  <t3-ce-header v-bind="$props" />
  <form
    :action="formActionHero"
    method="POST"
    @submit="getFormValues()"
  >
    <input type="hidden" name="LeadSource" value="Web">
    <input type="hidden" name="CampaignID" :value="campaignId">

    <input type="hidden" name="UTM_Campaign_Name__c" :value="campaign">
    <input type="hidden" name="UTM_Campaign_Source__c" :value="source">
    <input type="hidden" name="UTM_Campaign_Medium__c" :value="medium">

    <div class="form-group with-position">
      <input
        ref="position"
        maxlength="40"
        name="Position"
        size="20"
        type="text"
        placeholder="position"
      />
      <label for="position">{{ $t('form.position') }}</label>
    </div>
    <div v-if="layout === '20'">
      <forms-contact-item :unique="id" />
    </div>
    <div v-else-if="layout === '30'">
      <forms-download-item :unique="id" />
    </div>
    <div v-else-if="layout === '40'">
      <forms-conference-item :unique="id" />
    </div>
    <div v-else>
      <forms-newsletter-item :unique="id" />
    </div>
    <input
      type="submit"
      name="submit"
      :value="buttonText != '' ? buttonText : $t('form.submit')"
      class="btn btn-primary btn-lg w-full"
      :disabled="formDisabled"
    />
  </form>
</div>
</template>
<script>
import FormsNewsletterItem from '../partials/Forms/FormsNewsletterItem'
import FormsContactItem from '../partials/Forms/FormsContactItem'
import FormsDownloadItem from '../partials/Forms/FormsDownloadItem'
import FormsConferenceItem from '../partials/Forms/FormsConferenceItem'
import baseCe from '../mixins/baseCe'
export default {
  name: 'T3CeSuresecureForms',
  components: {
    FormsNewsletterItem,
    FormsContactItem,
    FormsDownloadItem,
    FormsConferenceItem
  },
  extends: baseCe,
  props: {
    layout: {
      type: String,
      default: '10',
    },
    formAction: {
      type: String,
      default: '',
    },
    campaignId: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      formActionHero: '',
      campaign: this.$route.query.utm_campaign,
      medium: this.$route.query.utm_medium,
      source: this.$route.query.utm_source,
      formDisabled: false
    }
  },
  methods: {
    getFormValues () {
      if(this.$refs.position.value === '') {
        this.formActionHero = this.formAction
      }
    }
  }
}
</script>
<style lang="postcss">
.form-group {
  &.with-position {
    @apply hidden;
  }
  @apply relative mb-6 text-[15px] text-[#92A6A6] leading-8;
  &:last-child:not(.checkbox) {
    @apply mb-2;
  }
  label {
    @apply absolute top-[5px] left-3 z-10 text-[15px] text-[#92A6A6] transition-all;
  }
  input,
  textarea {
    @apply placeholder-transparent rounded-[4px] bg-[#3F5151] border-0 w-full transition-all border-opacity-0 border-b-4 border-white;
    &:focus,
    &:not(:placeholder-shown) {
      @apply outline-none shadow-none ring-0 border-opacity-100;
      ~ label {
        @apply top-[-26px] text-[11px] uppercase;
      }
    }
  }
  &.checkbox {
    label {
      @apply static ml-2 !normal-case text-[#627373];
    }
    input {
      @apply w-4 h-4 border-none !ring-offset-0;
      &:checked {
        @apply bg-primary;
      }
    }
  }
  select {
    @apply placeholder-transparent rounded-[4px] bg-[#3F5151] border-0 w-full transition-all border-opacity-0 border-b-4 border-white text-[15px] text-[#92A6A6];
    ~ label {
      @apply pointer-events-none;
    }
    &:focus,
    &:valid {
      @apply outline-none shadow-none ring-0 border-opacity-100;
      ~ label {
        @apply top-[-26px] text-[11px] uppercase;
      }
    }
    option {
      @apply text-[15px] text-[#92A6A6];
    }
  }
}
.col-span-8 {
  .when-nested {
    @apply p-8 bg-boxed rounded-md;
  }
}
</style>
