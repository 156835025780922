var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "absolute overflow-hidden inset-x-0 lg:w-full min-h-[500px] bg-dark",
      },
      [
        _c(
          "client-only",
          [
            _c("Particles", {
              staticClass: "absolute -inset-40 z-0 opacity-10",
              attrs: {
                id: `tsParticles-${_vm.id}`,
                options: {
                  fullScreen: false,
                  fpsLimit: 120,
                  particles: {
                    links: {
                      enable: true,
                      color: "#ffffff",
                      distance: 750,
                      width: 3,
                    },
                    move: {
                      direction: "none",
                      enable: true,
                      outModes: "out",
                      random: false,
                      speed: 1,
                      straight: true,
                    },
                    number: {
                      density: { enable: true, area: 1250 },
                      value: 20,
                    },
                    shape: {
                      type: "circle",
                    },
                    size: {
                      value: 10,
                    },
                    collisions: {
                      enable: true,
                      mode: "bounce",
                    },
                  },
                  detectRetina: true,
                },
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "lg:grid grid-cols-3 gap-10 mb-10" }, [
      _c(
        "div",
        { staticClass: "relative z-10 col-span-2" },
        [
          _c("div", { staticClass: "min-h-[500px] flex items-center" }, [
            _c("div", [
              _c("h2", { staticClass: "text-primary" }, [
                _vm._v("\n            " + _vm._s(_vm.data.jobOffer.title)),
                _c("br"),
                _vm._v(" "),
                _c("span", { staticClass: "text-white" }, [
                  _vm._v(_vm._s(_vm.data.jobOffer.gender)),
                ]),
              ]),
              _vm._v(" "),
              _c("ul", { staticClass: "flex flex-col lg:flex-row gap-10" }, [
                _vm.data.jobOffer.experience
                  ? _c(
                      "li",
                      {
                        staticClass:
                          "flex gap-7 items-center font-medium text-white",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex items-center relative before:content-[''] before:block before:bg-primary before:rounded-full before:w-12 before:h-12 before:absolute before:-z-10",
                          },
                          [_c("IconCalendar", { staticClass: "ml-2 h-7 w-7" })],
                          1
                        ),
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.data.jobOffer.experience) +
                            "\n            "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass:
                      "flex gap-7 items-center font-medium text-white",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex items-center relative before:content-[''] before:block before:bg-primary before:rounded-full before:w-12 before:h-12 before:absolute before:-z-10",
                      },
                      [
                        _c("IconMapMarker", {
                          staticClass: "ml-[10px] h-7 w-7",
                        }),
                      ],
                      1
                    ),
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.data.jobOffer.location.name) +
                        "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass:
                      "flex gap-7 items-center font-medium text-white",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex items-center relative before:content-[''] before:block before:bg-primary before:rounded-full before:w-12 before:h-12 before:absolute before:-z-10",
                      },
                      [_c("IconClock", { staticClass: "ml-[10px] h-7 w-7" })],
                      1
                    ),
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.data.jobOffer.employment.name) +
                        "\n            "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass:
                    "mt-16 btn btn-outline btn-outline--arrow hidden",
                  on: { click: _vm.goDown },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("joboffers.applyNow")) +
                      "\n          "
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm._l(_vm.data.jobOffer.content, function (item, key) {
            return _c(
              "div",
              { key: key, staticClass: "my-20" },
              [
                _c(
                  "t3-ce-header",
                  _vm._b({}, "t3-ce-header", item.content, false)
                ),
                _vm._v(" "),
                _c("t3-html-parser", {
                  attrs: { content: item.content.bodytext },
                }),
              ],
              1
            )
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-span-1" }, [
        _c(
          "div",
          {
            staticClass:
              "bg-boxed relative px-7 pt-16 pb-7 rounded-lg mt-32 lg:mt-60",
          },
          [
            _c("IconMagnify", {
              staticClass:
                "absolute -top-14 max-w-[95px] rotate-12 left-2/4 z-10 -translate-x-1/2",
            }),
            _vm._v(" "),
            _c("span", {
              staticClass:
                "w-28 h-28 rounded-full bg-boxed block absolute -top-12 left-2/4 -translate-x-1/2",
            }),
            _vm._v(" "),
            _c("p", { staticClass: "h4 font-medium mb-10" }, [
              _vm._v(_vm._s(_vm.$t("joboffers.summary"))),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "grid grid-cols-2 border-b-[1px] border-white/10 mb-8",
              },
              [
                _c("p", { staticClass: "font-medium" }, [
                  _vm._v(_vm._s(_vm.$t("joboffers.begin"))),
                ]),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(_vm.data.jobOffer.start))]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "grid grid-cols-2 border-b-[1px] border-white/10 mb-8",
              },
              [
                _c("p", { staticClass: "font-medium" }, [
                  _vm._v(_vm._s(_vm.$t("joboffers.language"))),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("joboffers.languages." + _vm.language))),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "grid grid-cols-2 border-b-[1px] border-white/10 mb-8",
              },
              [
                _c("p", { staticClass: "font-medium" }, [
                  _vm._v(_vm._s(_vm.$t("joboffers.location"))),
                ]),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(_vm.data.jobOffer.location.name))]),
              ]
            ),
            _vm._v(" "),
            _vm.data.jobOffer.experience
              ? _c(
                  "div",
                  {
                    staticClass:
                      "grid grid-cols-2 border-b-[1px] border-white/10 mb-8",
                  },
                  [
                    _c("p", { staticClass: "font-medium" }, [
                      _vm._v(_vm._s(_vm.$t("joboffers.careerLevel"))),
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(_vm.data.jobOffer.experience))]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "grid grid-cols-2 mb-5" }, [
              _c("p", { staticClass: "font-medium" }, [
                _vm._v(_vm._s(_vm.$t("joboffers.employment"))),
              ]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.data.jobOffer.employment.name))]),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "bg-boxed relative p-7 rounded-lg mt-5 mb-10 hidden",
            attrs: { id: "contact" },
          },
          [
            _c("p", { staticClass: "h4 font-medium mb-10" }, [
              _vm._v(_vm._s(_vm.$t("joboffers.interested")) + "?"),
            ]),
            _vm._v(" "),
            _c(
              "form",
              {
                ref: "form",
                attrs: {
                  action:
                    "https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8",
                  method: "POST",
                },
              },
              [
                _c("input", {
                  attrs: {
                    type: "hidden",
                    name: "captcha_settings",
                    value:
                      '{"keyname":"suresecure_recap","fallback":"true","orgId":"00D5J000000DGbu","ts":""}',
                  },
                }),
                _vm._v(" "),
                _c("input", {
                  attrs: {
                    type: "hidden",
                    name: "oid",
                    value: "00D5J000000DGbu",
                  },
                }),
                _vm._v(" "),
                _c("input", {
                  attrs: { type: "hidden", name: "retURL" },
                  domProps: { value: _vm.$t("form.retUrl") },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "select",
                    {
                      attrs: {
                        id: "salutation",
                        name: "salutation",
                        required: "",
                      },
                    },
                    [
                      _c("option", { attrs: { hidden: "" } }),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "Mr." } }, [
                        _vm._v(_vm._s(_vm.$t("form.salutations.mr"))),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "Mrs." } }, [
                        _vm._v(_vm._s(_vm.$t("form.salutations.mrs"))),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "salutation" } }, [
                    _vm._v(_vm._s(_vm.$t("form.salutation"))),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("input", {
                    attrs: {
                      id: "first_name",
                      maxlength: "40",
                      name: "first_name",
                      size: "20",
                      type: "text",
                      placeholder: "first_name",
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "first_name" } }, [
                    _vm._v(_vm._s(_vm.$t("form.first_name"))),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("input", {
                    attrs: {
                      id: "last_name",
                      maxlength: "80",
                      name: "last_name",
                      size: "20",
                      type: "text",
                      placeholder: "last_name",
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "last_name" } }, [
                    _vm._v(_vm._s(_vm.$t("form.last_name"))),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("input", {
                    attrs: {
                      id: "email",
                      maxlength: "80",
                      name: "email",
                      size: "20",
                      type: "text",
                      placeholder: "email",
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "email" } }, [
                    _vm._v(_vm._s(_vm.$t("form.email"))),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("input", {
                    attrs: {
                      id: "phone",
                      maxlength: "40",
                      name: "phone",
                      size: "20",
                      type: "text",
                      placeholder: "phone",
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "phone" } }, [
                    _vm._v(_vm._s(_vm.$t("form.phone"))),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("input", {
                    attrs: {
                      id: "mobile",
                      maxlength: "40",
                      name: "mobile",
                      size: "20",
                      type: "text",
                      placeholder: "mobile",
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "mobile" } }, [
                    _vm._v(_vm._s(_vm.$t("form.mobile"))),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("textarea", {
                    attrs: { name: "description", placeholder: "description" },
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "description" } }, [
                    _vm._v(_vm._s(_vm.$t("form.description"))),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "select",
                    {
                      attrs: {
                        id: "00N5J000002HB9Z",
                        name: "00N5J000002HB9Z",
                        required: "",
                      },
                    },
                    [
                      _c("option", { attrs: { hidden: "" } }),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v(_vm._s(_vm.$t("form.withOut"))),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "Yes" } }, [
                        _vm._v(_vm._s(_vm.$t("form.yes"))),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "00N5J000002HB9Z" } }, [
                    _vm._v(_vm._s(_vm.$t("form.dataProcessing"))),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "select",
                    {
                      attrs: {
                        id: "00N5J000002HB9e",
                        name: "00N5J000002HB9e",
                        required: "",
                      },
                    },
                    [
                      _c("option", { attrs: { hidden: "" } }),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v(_vm._s(_vm.$t("form.withOut"))),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "Yes" } }, [
                        _vm._v(_vm._s(_vm.$t("form.yes"))),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "00N5J000002HB9e" } }, [
                    _vm._v(_vm._s(_vm.$t("form.dataProtection"))),
                  ]),
                ]),
                _vm._v(" "),
                _c("input", {
                  attrs: {
                    id: "00N5J000002HCcw",
                    name: "00N5J000002HCcw",
                    input: "",
                    type: "hidden",
                    value: "1",
                  },
                }),
                _vm._v(" "),
                _c("input", {
                  attrs: {
                    id: "recordType",
                    name: "recordType",
                    input: "",
                    type: "hidden",
                    value: "0125J000000kFrD",
                  },
                }),
                _vm._v(" "),
                _c("input", {
                  attrs: {
                    id: "lead_source",
                    name: "lead_source",
                    input: "",
                    type: "hidden",
                    value: "Web",
                  },
                }),
                _vm._v(" "),
                _c("input", {
                  attrs: {
                    id: "Campaign_ID",
                    name: "Campaign_ID",
                    input: "",
                    type: "hidden",
                  },
                  domProps: { value: _vm.data.jobOffer.salesforceCampaignId },
                }),
                _vm._v(" "),
                _c("input", {
                  attrs: {
                    id: "00N5J000002HCd1",
                    name: "00N5J000002HCd1",
                    input: "",
                    type: "hidden",
                  },
                  domProps: { value: _vm.data.jobOffer.title },
                }),
                _vm._v(" "),
                _c("input", {
                  staticClass: "btn btn-primary btn-lg w-full",
                  attrs: { type: "submit", name: "submit" },
                }),
              ]
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }