<template functional>
  <iframe
    v-bind="data.attrs"
    :class="[data.class, data.staticClass]"
    v-lazy-load :data-src="props.file.publicUrl"
    :width="props.file.properties.dimensions.width || 640"
    :height="props.file.properties.dimensions.height || 360"
    :title="props.file.properties.title || false"
    frameborder="0"
    class="t3-ce-media-video t3-ce-media-vimeo"
    webkitallowfullscreen
    mozallowfullscreen
    allowfullscreen
  />
</template>
<script>
export default {
  name: 'MediaVimeo',
  functional: true,
  props: {
    file: {
      type: Object,
      required: true
    }
  }
}
</script>
