var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(_vm.getMediaType(_vm.file.properties), {
    tag: "component",
    attrs: { file: _vm.file },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }