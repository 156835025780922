<template>
  <div>
    <div id="content-wrap" class="min-h-screen content-wrap frame-background-light">
      <section class="mt-16 frame-container lg:px-[10%]">
        <div class="relative before:content-[''] before:rounded-md before:outline-2 before:outline before:outline-primary before:absolute before:-top-5 before:-right-5 before:bottom-5 before:left-5 before:z-10">
          <span v-if="page.categories.length > 0" class="bg-primary uppercase rounded-b-[3px] text-xs inline-block text-white px-5 py-2 absolute -top-5 left-10 z-20">
            {{ page.categories[0].title }}
          </span>
          <figure class="rounded-md relative overflow-hidden lg:h-[500px] xl:h-[600px]">
            <img
              v-if="page.media.length > 0"
              v-lazy-load :data-src="page.media[0].publicUrl"
              :alt="page.media[0].properties.filename"
              :width="page.media[0].properties.dimensions.width"
              :height="page.media[0].properties.dimensions.height"
              class="w-full lg:absolute top-1/2 lg:-translate-y-1/2"
            >
          </figure>
        </div>
        <div class="frame-background-light relative lg:top-[-104px] rounded-tr-xl pt-6 lg:w-3/4 z-20 lg:float-left lg:mr-5">
          <p class="text-quinary font-medium">{{ $moment.unix(page.starttime ? page.starttime : page.crdate).format('D/M/YYYY') }}</p>
          <h2 class="lg:mr-5">{{ page.meta.title }}</h2>
          <t3-renderer :content="content.colPos0" />
        </div>
        <div v-if="page.suresecureAuthor.length > 0" class="mb-7 lg:mt-9">
          <div v-for="(author, key) in page.suresecureAuthor" :key="key" class="flex items-center gap-3 mb-3">
            <img
              v-lazy-load :data-src="author.content.image[0].publicUrl"
              :alt="author.content.image[0].properties.filename"
              :width="author.content.image[0].properties.dimensions.width"
              :height="author.content.image[0].properties.dimensions.height"
              class="w-[60px] rounded-full"
            >
            <p class="text-grey leading-6">
              {{ key > 0 ? $t('and') : $t('by') }} <span class="text-boxed font-bold">{{ author.content.name }}</span><br />
              {{ author.content.title }}
            </p>
          </div>
        </div>
      </section>
      <div v-if="page.relatedBlogPosts.length > 0" class="frame-container">
        <p class="h2 !mb-14">{{ $t('moreArticles') }}</p>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-4 gap-y-8 mb-20">
          <div v-for="(item, key) in page.relatedBlogPosts" :key="key" class="col-span-1">
            <blog-item :content="item" />
          </div>
        </div>
      </div>
    </div>
    <layout-footer-content
      class="mb-5"
      :col90="content.colPos90"
      :col91="content.colPos91"
      :col92="content.colPos92"
      :col93="content.colPos93"
      :col94="content.colPos94"
      :col95="content.colPos95"
      :col101="content.colPos101"
      :col102="content.colPos102"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import BlogItem from '../../components/content/partials/News/BlogItem'
export default {
  name: 'T3BlBlog',
  components: {
    BlogItem
  },
  filters: {
      truncate(data,num){
          const reqdString =  data.split("").slice(0, num).join("");
          return reqdString;
      }
  },
  props: {
    content: {
      type: [Object, Array],
      required: true,
    },
    suresecureAuthor: {
      type: [Object, Array],
      default: () => {}
    },
  },
  computed: {
    ...mapState({
      page: state => state.typo3.page || false,
    }),
  },
}
</script>
