<template>
    <div>
    <div class="content-wrap ce-suresecurecareer_eventdetails layout-default frame frame-default frame-space-before- frame-space-after- frame-space-inner-before- frame-space-inner-after- frame-background-light">
    <div class="frame-container">
        <div class="frame-inner">
        <div
            class="absolute overflow-hidden inset-x-0 lg:w-full min-h-[360px] lg:min-h-[500px] bg-dark"
        >
            <client-only>
                <Particles
                    :id="`tsParticles-${id}`"
                    class="absolute -inset-40 z-0 opacity-10"
                    :options="{
            fullScreen: false,
            fpsLimit: 120,
            particles: {
              links: {
                enable: true,
                color: '#ffffff',
                distance: 750,
                width: 3,
              },
              move: {
                direction: 'none',
                enable: true,
                outModes: 'out',
                random: false,
                speed: 1,
                straight: true,
              },
              number: { density: { enable: true, area: 1250 }, value: 20 },
              shape: {
                type: 'circle',
              },
              size: {
                value: 10,
              },
              collisions: {
                enable: true,
                mode: 'bounce',
              },
            },
            detectRetina: true,
          }"
                />
            </client-only>
        </div>

        <div class="lg:grid grid-cols-3 gap-10 mb-10">
            <div class="relative z-10 col-span-2">
                <div class="min-h-[360px] lg:min-h-[500px] flex items-center px-7 lg:px-0 flex-wrap lg:flex-nowrap">
                    <div>
                        <h2 class="text-primary text-4xl md:text-5xl lg:text-5xl">
                            {{ staticPage.meta.title }}
                            <span v-if="staticPage.meta.subtitle" class="text-white">
                                <br/>
                                {{ staticPage.meta.subtitle }}
                            </span>
                        </h2>
                    </div>
                    <div v-if="staticPage.formAction" class="w-full">
                        <a class="btn btn-outline px-7 btn-lg w-full btn-outline--arrow lg:hidden" @click="goDown">
                            {{ $t('event.registerNow') }}
                        </a>
                    </div>
                </div>
                <div class="col-span-1">
                    <div
                        class="bg-boxed rounded-lg relative px-7 pt-16 pb-7 mt-24 lg:mt-60 lg:hidden"
                    >
                        <p class="h4 font-medium mb-10">{{ $t('event.summary') }}</p>
                        <div v-if="staticPage.eventCity" class="grid grid-cols-2 border-b-[1px] border-white/10 mb-8">
                            <p class="font-medium">{{ $t('event.city') }}</p>
                            <p>{{ staticPage.eventCity }}</p>
                        </div>
                        <div v-if="staticPage.eventDate || staticPage.starttime" class="grid grid-cols-2 border-b-[1px] border-white/10 mb-8">
                            <p class="font-medium">{{ $t('event.date') }}</p>
                            <p v-if="staticPage.eventDate"> {{ staticPage.eventDate }} </p>
                            <p v-else> {{ $moment.unix(staticPage.starttime ? staticPage.starttime : staticPage.starttime).format('D/M/YYYY') }}</p>
                        </div>
                        <div v-if="staticPage.categories.length > 0" class="grid grid-cols-2 border-b-[1px] border-white/10 mb-8">
                            <p class="font-medium">{{ $t('event.format') }}</p>
                            <p>{{ staticPage.categories[0].title }}</p>
                        </div>
                        <div v-if="staticPage.eventTheme" class="grid grid-cols-2 border-b-[1px] border-white/10 mb-8">
                            <p class="font-medium">{{ $t('event.theme') }}</p>
                            <p>{{ staticPage.eventTheme }}</p>
                        </div>
                        <div v-if="staticPage.exhibitionHallStand" class="grid grid-cols-2 border-b-[1px] border-white/10 mb-8">
                            <p class="font-medium">{{ $t('event.hall_stand') }}</p>
                            <p>{{ staticPage.exhibitionHallStand }}</p>
                        </div>
                        <div v-if="staticPage.registerLink" class="border-white/10 mb-8">
                            <a class="btn btn-primary btn-lg w-full" :href="staticPage.registerLink" target="_blank">
                                {{ $t('event.registerNow') }}
                            </a>
                        </div>
                    </div>
                </div>
                <t3-renderer :content="content.colPos0" />
                <div v-if="staticPage.formAction" class="border-white/10 mb-8">
                <div id="register" class="col-span-1">
                    <div
                        class="bg-boxed rounded-lg relative px-7 pt-16 pb-7 lg:mt-60 lg:hidden"
                    >

                    <p class="h4 font-medium mb-10"> {{ $t('event.registerNow') }}</p>
                    <t3-ce-header v-bind="$props" />
                    <form
                        :action="formActionHero"
                        method="POST"
                        @submit="getFormValues()"
                    >
                        <input type="hidden" name="LeadSource" value="Web">

                        <input type="hidden" name="UTM_Campaign_Name__c" :value="campaign">
                        <input type="hidden" name="UTM_Campaign_Source__c" :value="source">
                        <input type="hidden" name="UTM_Campaign_Medium__c" :value="medium">

                        <div class="form-group with-position">
                            <input
                                ref="position"
                                maxlength="40"
                                name="Position"
                                size="20"
                                type="text"
                                placeholder="position"
                            />
                            <label for="position">{{ $t('form.position') }}</label>
                        </div>
                        <div>
                            <forms-event-item :unique="id"/>
                        </div>
                        <input
                            type="submit"
                            name="submit"
                            :value="$t('form.submit')"
                            class="btn btn-primary btn-lg w-full"
                            :disabled="formDisabled"
                        />
                    </form>
                </div>
                    </div>
                </div>
            </div>
            <div class="col-span-1">
                <div
                    class="bg-boxed rounded-lg relative px-7 pt-16 pb-7 lg:mt-60 hidden lg:block"
                >
                    <p class="h4 font-medium mb-10">{{ $t('event.summary') }}</p>
                    <div v-if="staticPage.eventCity" class="grid grid-cols-2 border-b-[1px] border-white/10 mb-8">
                        <p class="font-medium">{{ $t('event.city') }}</p>
                        <p>{{ staticPage.eventCity }}</p>
                    </div>
                    <div v-if="staticPage.eventDate || staticPage.starttime" class="grid grid-cols-2 border-b-[1px] border-white/10 mb-8">
                        <p class="font-medium">{{ $t('event.date') }}</p>
                        <p v-if="staticPage.eventDate"> {{ staticPage.eventDate }} </p>
                        <p v-else> {{ $moment.unix(staticPage.starttime ? staticPage.starttime : staticPage.starttime).format('D/M/YYYY') }}</p>
                    </div>
                    <div v-if="staticPage.categories.length > 0" class="grid grid-cols-2 border-b-[1px] border-white/10 mb-8">
                        <p class="font-medium">{{ $t('event.format') }}</p>
                        <p>{{ staticPage.categories[0].title }}</p>
                    </div>
                    <div v-if="staticPage.eventTheme" class="grid grid-cols-2 border-b-[1px] border-white/10 mb-8">
                        <p class="font-medium">{{ $t('event.theme') }}</p>
                        <p>{{ staticPage.eventTheme }}</p>
                    </div>
                    <div v-if="staticPage.exhibitionHallStand" class="grid grid-cols-2 border-b-[1px] border-white/10 mb-8">
                        <p class="font-medium">{{ $t('event.hall_stand') }}</p>
                        <p>{{ staticPage.exhibitionHallStand }}</p>
                    </div>
                    <div v-if="staticPage.registerLink" class="border-white/10 mb-8">
                    <a class="btn btn-primary btn-lg w-full" :href="staticPage.registerLink" target="_blank">
                        {{ $t('event.registerNow') }}
                    </a>
                    </div>
                    <div v-if="staticPage.formAction" class="border-white/10 mb-8 hidden md:block">
                        <p class="h4 font-medium mb-10"> {{ $t('event.registerNow') }}</p>
                        <t3-ce-header v-bind="$props" />
                        <form
                            :action="formActionHero"
                            method="POST"
                            @submit="getFormValues()"
                        >
                            <input type="hidden" name="LeadSource" value="Web">

                            <input type="hidden" name="UTM_Campaign_Name__c" :value="campaign">
                            <input type="hidden" name="UTM_Campaign_Source__c" :value="source">
                            <input type="hidden" name="UTM_Campaign_Medium__c" :value="medium">

                            <div class="form-group with-position">
                                <input
                                    ref="position"
                                    maxlength="40"
                                    name="Position"
                                    size="20"
                                    type="text"
                                    placeholder="position"
                                />
                                <label for="position">{{ $t('form.position') }}</label>
                            </div>
                            <div>
                                <forms-event-item :unique="id"/>
                            </div>
                            <input
                                type="submit"
                                name="submit"
                                :value="$t('form.submit')"
                                class="btn btn-primary btn-lg w-full"
                                :disabled="formDisabled"
                            />
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

</div>
    <layout-footer-content
        class="mb-5"
        :col90="content.colPos90"
        :col91="content.colPos91"
        :col92="content.colPos92"
        :col93="content.colPos93"
        :col94="content.colPos94"
        :col95="content.colPos95"
        :col101="content.colPos101"
        :col102="content.colPos102"
    />
    </div>
</template>

<script>

import {mapState} from "vuex";

import FormsEventItem from '../../components/content/partials/Forms/FormsEventItem'
import baseCe from '~/components/content/mixins/baseCe'

export default {
    name: 'T3BlEvent',
    components: {

        FormsEventItem
    },
    extends: baseCe,
    props: {
        content: {
            type: [Object, Array],
            required: true
        },
        campaignId: {
            type: String,
            default: '',
        },
        formAction: {
            type: String,
            default: '',
        },
    },
    data () {
        return {
            staticPage: {},
            formActionHero: '',
            campaign: this.$route.query.utm_campaign,
            medium: this.$route.query.utm_medium,
            source: this.$route.query.utm_source,
            formDisabled: false
        }
    },
    computed: {
        ...mapState({
            page: state => state.typo3.page || false
        })
    },
    created () {
        this.staticPage = this.page
    },
    methods: {
        goDown() {
            this.$gsap.to(window, 1, {
                scrollTo: {
                    y: '#register',
                    offsetY: () => {
                        return innerWidth < 1280 ? (innerWidth < 1024 ? 0 : 190) : 150
                    },
                },
            })
        },
        getFormValues () {
            if (this.$refs.position.value === '') {
                this.formActionHero = this.staticPage.formAction
            }
        }
    },
}
</script>
<style lang="postcss" scoped>
 .form-group {
    &.with-position {
     @apply hidden;
    }
 }
</style>
