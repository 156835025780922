<script>
import baseCe from './baseCe'
export default {
  extends:baseCe,
  props: {
    baseUrl: {
      type: String,
      required: true
    },
    urlSegment: {
      type: String,
      default: 'page'
    },
    paginationActive: {
      type: Number,
      default: 0
    },
    currentPage: {
      type: Number,
      default: 0
    },
    itemsCount: {
      type: Number,
      default: 0
    },
    items: {
      type: Array,
      default: () => []
    },
    itemsPerPage: {
      type: Number,
      default: 10
    }
  }
}
</script>
