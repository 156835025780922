var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "t3-ce-header",
        _vm._b({ staticClass: "mb-20" }, "t3-ce-header", _vm.$props, false)
      ),
      _vm._v(" "),
      _vm._l(_vm.accordionItems, function (item, key) {
        return _c(
          "div",
          { key: key },
          [
            _c("AccordionItem", {
              attrs: { "accordion-item": _vm.accordionItems[key] },
            }),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }