<template>
  <div :id="'podcast-'+content.uid" class="lg:grid grid-cols-2 gap-x-10 mb-20">
    <div class="col-span-1 h-[200px] mb-10">
      <iframe v-lazy-load :data-src="content.podcast_link" width="100%" height="100%" frameborder="0" scrolling="no" />
    </div>
    <div class="col-span-1" :class="{'mt-[13px]': (!content.teaserImage[0] && !content.media[0])}">
      <ContentPartialsNewsCategoryBadge v-if="content.podcast_episode" :label="content.podcast_episode" />
      <p class="text-grey text-xs">{{ $moment.unix(content.starttime).format('DD/MM/YYYY') }}</p>
      <p class="h3 font-bold">{{content.teaser_title ? content.teaser_title : content.title}}</p>
      <p class="mb-5 text-grey">
        {{ content.teaser_text }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  filters: {
    truncate(data,num){
      const reqdString =  data.split("").slice(0, num).join("");
      return reqdString;
    }
  },
  props: {
    content: {
      type: Object,
      required: true
    }
  }
}
</script>