// states
export const state = () => ({
  filter: []
})

// mutations
export const mutations = {
  filter (state, filter) {
    if (state.filter.includes(filter)) {
      state.filter = state.filter.filter(e => e !== filter);
    } else {
      state.filter.push(filter)
    }
  },
  filterReset (state) {
    state.filter = []
  }
}

// actions
export const actions = {
  filter ({ commit }, filter) {
    commit('filter', filter)
  },
  filterReset ({ commit }) {
    commit('filterReset')
  }
}

// getters
export const getters = {
  filter: state => state.filter
}
