<template>
    <div>
        <t3-dynamic
            v-if="t3page"
            :data="t3page.content"
            :type="t3page.appearance.backendLayout"
            layout
        />
    </div>
</template>
<script>
export default {
    name: 'ErrorLayout',
    props: {
        error: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        t3page () {
            return this.error?.ssr?.t3page
        }
    }
}
</script>
