<template functional>
  <div
    v-bind="data.attrs"
    :class="[data.class, data.staticClass]"
    class="t3-ce-media-image"
  >
    <component
      :is="props.file.properties.link ? 't3-nav-link' : 'div'"
      :to="props.file.properties.link"
    >
      <figure class="relative" :class="[props.file.properties.description ? 'hover-figure' : '']">
        <img
          v-lazy-load :data-src="props.file.publicUrl"
          :height="props.file.properties.dimensions.height"
          :width="props.file.properties.dimensions.width"
          :alt="props.file.properties.alternative || false"
          :title="props.file.properties.title || ''"          
        >
        <figcaption v-if="props.file.properties.description" class="bg-gray-800/50 absolute -bottom-7 transition-all duration-300 text-white text-xs w-full px-3 py-1 rounded-b-md">
          {{ props.file.properties.description }}
        </figcaption>
      </figure>
    </component>
  </div>
</template>

<script>
export default {
  name: 'MediaImage',
  functional: true,
  props: {
    file: {
      type: Object,
      required: true
    }
  }
}
</script>
<style lang="postcss">
  .hover-figure {
    @apply overflow-hidden rounded-md;
    img {
      @apply transition-all duration-300;
    }
    &:hover {
      img {
        @apply scale-[102%];
      }
      figcaption {
        @apply bottom-0;
      }
    }
  }
</style>
