<template>
  <div class="relative">
    <div ref="overAll" class="flex gap-10 flex-col-reverse xl:flex-row items-center pb-12">
      <div class="xl:w-1/2">
        <t3-ce-header v-bind="$props" />
        <t3-html-parser :content="bodytext" />
      </div>
      <div class="xl:w-1/2 relative" :class="applyDecor === 1 ? 'decor' : 'self-end'" :style="`min-height: ${minHeight}px`">
        <figure>
          <img
            v-if="backgroundImage[0]"
            ref="image"
            v-lazy-load :data-src="backgroundImage[0].publicUrl"
            :width="backgroundImage[0].properties.dimensions.width"
            :height="backgroundImage[0].properties.dimensions.height"
            class="mx-auto xl:mx-0"
            @load="resizeHandler"
          >
        </figure>
      </div>
    </div>
    <div
      v-if="enableAnchorNav"
      class="z-50"
      :class="[!stickyState ? 'absolute bottom-0 translate-y-1/2 w-full' : 'fixed top-3 left-3 right-3']"
    >
      <div class="xl:container bg-boxed rounded-md flex items-center">
        <div 
          class="bg-primary rounded-l-md p-3 md:pr-6 text-white text-base relative shrink-0"
          :class="isShowStickyMenu ? 'downangle' : 'triangle'"
        >
          <div class="flex items-center">
            <div class="relative md:mr-4 h-10">
              <button
                type="button"
                class="w-10 h-10 rounded-full transition-all duration-300 bg-white/25"                
                :aria-expanded="isShowStickyMenu ? true : false"
                aria-controls="sticky-menu"
                aria-label="menu"
                aria-haspopup="menu"
                role="button"
                @click="toggleStickyMenu()"
              />
              <span class="absolute top-3 left-2 z-50 space-y-[5px] pointer-events-none">
                <span
                  class="block w-6 h-0.5 bg-white transition-all duration-300"
                  :class="isShowStickyMenu ? 'rotate-45 translate-y-[7px]' : ''"
                ></span>
                <span
                  class="block w-6 h-0.5 bg-white transition-all duration-500"
                  :class="isShowStickyMenu ? '-translate-x-6 opacity-0' : ''"
                ></span>
                <span
                  class="block w-6 h-0.5 bg-white transition-all duration-300"
                  :class="isShowStickyMenu ? '-rotate-45 -translate-y-[7px]' : ''"
                ></span>
              </span>
            </div>
            <span class="hidden md:block">{{ $t('ourSolutions') }}</span>
          </div>
        </div>
        <div
          v-if="menu.length > 0"
          ref="snap"
          class="overflow-x-auto snap-x snap-mandatory hide-scrollbar flex"
        >
          <template v-for="(element, key) in menu[0].content">
            <div
              v-if="element.data.uid !== id && element.data.colPos !== 1001 && element.data.colPos !== 90"
              :key="key"
              ref="items"
              class="px-10 snap-start shrink-0"
            >
              <a :href="`#c${element.data.uid}`" class="underscore">
                {{ element.data.skyline }}
              </a>
            </div>
          </template>
        </div>
        <div v-if="overflowing" class="ml-auto shrink-0 flex">
          <IconArrowForward
            class="mr-3 w-8 h-8 text-boxed rotate-180 hover:text-white transition-all hover:scale-105 bg-grey rounded-full"
            @click="swipeLeft"
          />
          <IconArrowForward
            class="mr-4 w-8 h-8 text-boxed hover:text-white transition-all hover:scale-105 bg-grey rounded-full"
            @click="swipeRight"
          />
        </div>
      </div>
      <ul 
        v-show="isShowStickyMenu"
        id="sticky-menu" 
        :class="[stickyState ? 'xl:left-[calc(((var(--screen-width)-(var(--screen-width)-100%))-var(--current-screen))/2)]' : '']"
        class="absolute top-20 rounded-md bg-boxed py-3 text-xs uppercase flex-col w-full sm:w-auto">
        <li 
          v-for="(item, key) in navMain.children" 
          :key="key" 
          class="relative">
          <nuxt-link
            class="font-medium lg:text-xxs xl:text-xs text-white pl-5 pr-6 transition-all cursor-pointer hover:text-primary h-10 flex items-center"
            :to="item.link"
            active-class="bg-grey border-l-4 border-primary"                
          >
            <span>{{ item.title }}</span>
          </nuxt-link>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import baseCe from '../mixins/baseCe'
import IconArrowForward from '~/assets/icons/arrow-forward-outline.svg?inline'
export default {
  name: 'T3CeSuresecureHero',
  components: {
    IconArrowForward,
  },
  extends: baseCe,
  props: {
    backgroundImage: {
      type: Array,
      default: () => [],
    },
    bodytext: {
      type: String,
      default: '',
    },
    applyDecor: {
      type: Number,
      default: 0,
    },
    enableAnchorNav: {
      type: Number,
      default: 0,
    },
    menu: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      swipePosition: 0,
      stickyState: false,
      activeState: false,
      minHeight: 0,
      isShowStickyMenu: false,
      overflowing: false
    }
  },
  computed: {
    contentElements() {
      const uids = []
      const parentId = this.id
      this.menu[0].content.forEach(function(item) {
        if (item.data.skyline &&
            item.data.sectionIndex === 1 &&
            item.data.uid !== parentId &&
            item.data.colPos !== 1001 &&
            item.data.colPos !== 90) {
          uids.push('#c' + item.data.uid)
        }
      })
      return document.querySelectorAll(uids)
    },
    ...mapState({
      navMain: (state) => state.typo3.initial.navigation[0] || [],
    }),
  }, 
  mounted() {
    if (this.enableAnchorNav === 1) {
      window.addEventListener('scroll', this.isSticky)
      window.addEventListener('scroll', this.setActiveClass)
    }
    window.addEventListener('resize', this.resizeHandler)
  },
  destroyed() {
    if (this.enableAnchorNav === 1) {
      window.removeEventListener('scroll', this.isSticky)
      window.removeEventListener('scroll', this.setActiveClass)
    }
    window.removeEventListener('resize', this.resizeHandler)
  },
  methods: {
    resizeHandler() {
      this.minHeight = this.$refs.image.clientHeight
      this.overflowing = (this.$refs.snap && (this.$refs.snap.offsetWidth < this.$refs.snap.scrollWidth))
    },
    isSticky() {
      const bottom = this.$refs.overAll.getBoundingClientRect()
      if (bottom.bottom < 48) {
          this.stickyState = true
      } else {
          this.stickyState = false
      }
    },
    setActiveClass() {
      const scrollPosition = document.documentElement.scrollTop
      for (let i = 0; i < this.contentElements.length; i++) {
        const container = this.contentElements[i]
        const containerOffset = container.offsetTop
        const containerHeight = container.clientHeight
        const containerBottom = containerOffset + containerHeight

        if (this.$refs.items[i]) {
          if(scrollPosition < containerBottom-48 && scrollPosition >= containerOffset-48) {
            this.$refs.items[i].classList.add('active')
            this.$gsap.to(this.$refs.snap, {scrollTo: this.$refs.items[i]})
          } else {
            this.$refs.items[i].classList.remove('active')
          }
        }
      }
    },
    swipeLeft() {
      if (this.swipePosition > 0) {
        this.swipePosition -= this.$refs.items[0].clientWidth
        this.$refs.snap.scroll({
          left: this.swipePosition,
          behavior: 'smooth',
        })
      }
    },
    swipeRight() {
      if (
        this.swipePosition + this.$refs.items[0].clientWidth * 3 <
        this.$refs.snap.scrollWidth
      ) {
        this.swipePosition += this.$refs.items[0].clientWidth
        this.$refs.snap.scroll({
          left: this.swipePosition,
          behavior: 'smooth',
        })
      }
    },
    toggleStickyMenu () {
      if (this.isShowStickyMenu) {
        this.isShowStickyMenu = false
      } else {
        this.isShowStickyMenu = true
      }
    },   
  },
}
</script>
<style lang="postcss" scoped>
.decor {
  figure {
    @apply relative xl:absolute xl:right-[calc((var(--screen-width)-var(--current-screen))/-2)] xl:left-0
              before:content-[''] before:h-full xl:before:rounded-bl-md before:absolute before:-top-4 before:-left-4 before:-right-4 xl:before:right-0 before:z-10;
    img {
      @apply rounded-bl-md overflow-hidden;
    }
    &:before {
      border-bottom: 3px solid #89bd27;
      @media (min-width: 1280px) {
        border-left: 3px solid #89bd27;
      }
    }
  }
}
.triangle {
  &:before {
    @apply content-[''] absolute -right-3 top-1/2 -translate-y-1/2 block border-t-[10px] border-t-transparent border-b-[10px] border-b-transparent border-l-[12px] border-l-primary;
  }
}
.downangle {
  &:before {
    @apply content-[''] absolute left-[22px] bottom-0 translate-y-full block border-l-[10px] border-l-transparent border-r-[10px] border-r-transparent border-t-[12px] border-t-primary;
  }
}
.underscore {
  @apply transition-all cursor-pointer py-5 text-[14px] uppercase font-medium inline-block;
  &:hover {
    box-shadow: 0px -4px 0px var(--color-primary) inset;
  }
}
.active {
  a {
    box-shadow: 0px -4px 0px var(--color-primary) inset;
  }
}
</style>