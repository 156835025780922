<template>
  <div class="center-items">
    <div v-if="downloadedSvg" v-html="downloadedSvg" />
    <img
      v-else
      v-lazy-load :data-src="image.publicUrl"
      :alt="title"
      :width="image.properties.dimensions.width"
      :height="image.properties.dimensions.height"
    >
  </div>
</template>
<script>
export default {
  props: {
    image: {
      type: [Array, Object],
      required: true
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      downloadedSvg: ''
    }
  },
  mounted() {
    this.downloadSvg()
  },
  methods: {
    async downloadSvg() {
      if (this.image && this.image.properties.extension === 'svg') {
        const response = await this.$axios.$get(
          this.image.publicUrl
        )
        this.downloadedSvg = response
        this.$nuxt.$emit('inline-svg-loaded', true)
      }
    },
  }
}
</script>
<style lang="postcss">
  .center-items {
    svg,
    img {
      @apply mx-auto;
    }
  }
</style>