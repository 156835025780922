<template>
  <!-- eslint-disable-next-line vue/no-v-html -->
  <div v-html="content" />
</template>

<script>
// source: https://github.com/nuxt/nuxtjs.org/blob/master/components/HtmlParser.vue
export default {
  name: 'T3HtmlParser',
  props: {
    /**
     * HTML Content to parse
     */
    content: {
      type: String,
      default: ''
    }
  },
  watch: {
    content: 'contentUpdated'
  },
  mounted () {
    this.$nextTick(this.addListeners)
  },
  beforeDestroy () {
    this.removeListeners()
  },
  methods: {
    navigate (event) {
      let target = event.target
      let i = 0
      // Go throught 5 parents max to find a tag
      while (
        i < 5 &&
        !(target instanceof HTMLAnchorElement) &&
        target.parentNode
      ) {
        target = target.parentNode
        i++
      }
      // If target is still not a link, ignore
      if (!(target instanceof HTMLAnchorElement)) { return }
      const href = target.getAttribute('href')
      // Get link target, if local link, navigate with router link
      if (href && href[0] === '/') {
        event.preventDefault()
        this.$router.push(href)
      }
    },
    contentUpdated () {
      this.removeListeners()
      this.$nextTick(() => {
        this.addListeners()
      })
    },
    addListeners () {
      this._links = this.$el.getElementsByTagName('a')
      for (let i = 0; i < this._links.length; i++) {
        this._links[i].addEventListener('click', this.navigate, false)
      }
    },
    removeListeners () {
      if (this._links) {
        for (let i = 0; i < this._links.length; i++) {
          this._links[i].removeEventListener('click', this.navigate, false)
        }
        this._links = []
      }
    }
  }
}
</script>
<style lang="postcss" scoped>
* {
  @apply text-white;
  /deep/ {
    .center {
      @apply text-center;
    }
    p {
      @apply mb-4 text-white;
    }
    a:not([class*="btn"]) {
      @apply text-primary underline;
    }
    ul ~ h2,
    ol ~ h2,
    p ~ h2,
    ul ~ h3,
    ol ~ h2,
    p ~ h3,
    ul ~ h4,
    ol ~ h4,
    p ~ h4 {
      @apply mt-12;
    }
    .bg-white {
      p {
        @apply text-boxed;
      }
    }
    ul.list {
      @apply my-12;
      &.list-2-cols {
        @media (min-width: 640px) {
          columns: 2;
        }
      }
      li {
        @apply relative mb-5 break-inside-avoid;
      }
      &:not([class*='-']) {
        li {
          @apply mb-4 pl-10;
          &:before {
            @apply absolute left-3 top-[5px] content-[''] inline-block bg-primary w-3 h-3 rounded-sm mr-3 flex-shrink-0;
          }
        }
      }

      &.list-checked {
        li {
          @apply pl-10 pt-1;
          &:before {
            @apply absolute left-0 top-0 content-check inline-block w-[30px] h-[30px] bg-repeat-round rounded-full mr-3;
          }
        }
      }
    }
  }
}
</style>