var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("input", {
      attrs: {
        type: "hidden",
        name: "Confirmed_Opt_In_Source__c",
        value: "Website_Form_Contact",
      },
    }),
    _vm._v(" "),
    _c("input", {
      attrs: { type: "hidden", name: "Newsletter_Opt_In_Timestamp__c" },
      domProps: { value: _vm.fullDateTime },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c(
        "select",
        { attrs: { id: "salutation", name: "Salutation", required: "" } },
        [
          _c("option", { attrs: { hidden: "" } }),
          _vm._v(" "),
          _c("option", { attrs: { value: "Mr." } }, [
            _vm._v(_vm._s(_vm.$t("form.salutations.mr"))),
          ]),
          _vm._v(" "),
          _c("option", { attrs: { value: "Mrs." } }, [
            _vm._v(_vm._s(_vm.$t("form.salutations.mrs"))),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("label", { attrs: { for: "salutation" } }, [
        _vm._v(_vm._s(_vm.$t("form.salutation"))),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "flex gap-4" }, [
      _c("div", { staticClass: "form-group flex-1" }, [
        _c("input", {
          attrs: {
            maxlength: "40",
            name: "FirstName",
            size: "20",
            type: "text",
            placeholder: "first_name",
            required: "",
          },
        }),
        _vm._v(" "),
        _c("label", { attrs: { for: "first_name" } }, [
          _vm._v(_vm._s(_vm.$t("form.first_name"))),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group flex-1" }, [
        _c("input", {
          attrs: {
            maxlength: "80",
            name: "LastName",
            size: "20",
            type: "text",
            placeholder: "last_name",
            required: "",
          },
        }),
        _vm._v(" "),
        _c("label", { attrs: { for: "last_name" } }, [
          _vm._v(_vm._s(_vm.$t("form.last_name"))),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.email,
            expression: "email",
          },
        ],
        attrs: {
          maxlength: "80",
          name: "email",
          size: "20",
          type: "email",
          placeholder: "email",
          required: "",
        },
        domProps: { value: _vm.email },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.email = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _c("label", { attrs: { for: "email" } }, [
        _vm._v(_vm._s(_vm.$t("form.businessEmail"))),
      ]),
      _vm._v(" "),
      _c("p", { staticStyle: { color: "red" } }, [
        _vm._v(_vm._s(_vm.errorMessage)),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("textarea", {
        attrs: {
          name: "Description",
          placeholder: "Description",
          required: "",
        },
      }),
      _vm._v(" "),
      _c("label", { attrs: { for: "description" } }, [
        _vm._v(_vm._s(_vm.$t("form.comment"))),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-group !mb-0 checkbox" },
      [
        _c("input", {
          attrs: {
            id: [`privacy_policy-${_vm.unique}`],
            name: "GDPR_Data_Processing_Accepted__c",
            type: "checkbox",
            value: "true",
            placeholder: "privacy_policy",
            required: "",
          },
        }),
        _vm._v(" "),
        _c("i18n", {
          attrs: {
            path: "form.privacyPolicy",
            tag: "label",
            for: [`privacy_policy-${_vm.unique}`],
          },
          scopedSlots: _vm._u([
            {
              key: "link",
              fn: function () {
                return [
                  _c(
                    "a",
                    {
                      staticClass: "text-primary font-medium",
                      attrs: { href: _vm.$t("form.linkPrivacyPolicy") },
                    },
                    [_vm._v(_vm._s(_vm.$t("form.link")))]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-group checkbox" },
      [
        _c("input", {
          ref: "newsletter",
          attrs: {
            id: [`newsletter-${_vm.unique}`],
            name: "Newsletter_Opt_In__c",
            type: "checkbox",
            value: "",
            placeholder: "newsletter",
          },
          on: {
            click: function ($event) {
              return _vm.printFullDate()
            },
          },
        }),
        _vm._v(" "),
        _c(
          "i18n",
          {
            attrs: {
              path: "form.newsletter",
              tag: "label",
              for: [`newsletter-${_vm.unique}`],
            },
            on: {
              click: function ($event) {
                return _vm.printFullDate()
              },
            },
          },
          [_vm._v("\n      " + _vm._s(_vm.$t("form.newsletter")) + "\n    ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }