<template>
  <div>
    <t3-renderer v-if="head" :content="head" :in-container="true" />
    <div class="lg:grid grid-cols-12 gap-10">
      <div class="col-span-4">
        <t3-renderer v-if="left" :content="left" :in-container="true" />
      </div>
      <div class="col-span-8">
        <t3-renderer v-if="right" :content="right" :in-container="true" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'T3CeSuresecureColsTwentyEighty',
  props: {
    head: {
      type: Array,
      default: () => []
    },
    left: {
      type: Array,
      default: () => []
    },
    right: {
      type: Array,
      default: () => []
    }
  }
}
</script>