// Content element wrapper - Section Frame
// All props are returned from appearance
export default {
  name: 'T3Frame',
  functional: true,
  props: {
    id: {
      type: Number,
      required: true
    },
    type: {
      type: String,
      required: false,
      default: ''
    },
    appearance: {
      type: Object,
      required: false,
      default: ''
    }
  },
  render (createElement, ctx) {
    return createElement(
      'div',
      {
        attrs: {
          id: `c${ctx.props.id}`
        },
        class: [
          `ce-${ctx.props.type}`,
          `layout-${ctx.props.appearance.layout}`,
          'frame',
          `frame-${ctx.props.appearance.frameClass}`,
          `frame-space-before-${ctx.props.appearance.spaceBefore}`,
          `frame-space-after-${ctx.props.appearance.spaceAfter}`,
          `frame-space-inner-before-${ctx.props.appearance.spaceInnerBefore}`,
          `frame-space-inner-after-${ctx.props.appearance.spaceInnerAfter}`,
          `frame-background-${ctx.props.appearance.bgColorClass}`
        ]
      },
      [
        createElement(
          'div',
          {
            class: 'frame-container'
          },
          [
            createElement(
              'div',
              {
                class: 'frame-inner'
              },
              ctx.children
            )
          ]
        )
      ]
    )
  }
}
