<template>
<div :class="backgroundClass" :style="{'padding-top': footerTopHeight/2+'px'}">
  <div class="bg-[#242C2B]">
    <section class="xl:container">
      <div v-if="col90.length" ref="footerTop" class="-translate-y-1/2" :style="{'margin-bottom': '-'+footerTopHeight/2-20+'px'}">
        <t3-renderer :content="col90" />
      </div>
      <div
        class="xs:gap-4 sm:grid sm:grid-cols-2 md:grid-cols-4 md:mx-3 lg:grid-cols-5 pt-10"
      >
        <div><layout-footer-block :content="col91" /></div>
        <div><layout-footer-block :content="col92" /></div>
        <div><layout-footer-block :content="col93" /></div>
        <div><layout-footer-block :content="col94" /></div>
        <div><layout-footer-block :content="col95" /></div>
      </div>
    </section>
    <section class="xl:container mt-10 sm:mt-0">
      <div class="mx-3">
        <h4 class="relative py-3 text-xs font-medium uppercase cursor-pointer mb-0 sm:mb-5 text-quinary">{{ $t('ourAwards') }}</h4>
        <t3-renderer :content="col101" />
      </div>
    </section>
    <section class="xl:container mb-10">
      <div class="mx-3">
        <h4 class="relative py-3 text-xs font-medium uppercase cursor-pointer mb-0 sm:mb-5 text-quinary">{{ $t('followUs') }}</h4>
        <div class="flex justify-start gap-1 mt-5"><t3-renderer :content="col102" /></div>
      </div>
    </section>
  </div>
</div>
</template>
<script>
export default {
  props: {
    col90: {
      type: [Object, Array],
      default: () => []
    },
    col91: {
      type: [Object, Array],
      default: () => [],
    },
    col92: {
      type: [Object, Array],
      default: () => [],
    },
    col93: {
      type: [Object, Array],
      default: () => [],
    },
    col94: {
      type: [Object, Array],
      default: () => [],
    },
    col95: {
      type: [Object, Array],
      default: () => [],
    },
    col96: {
      type: [Object, Array],
      default: () => [],
    },
    col101: {
      type: [Object, Array],
      default: () => [],
    },
    col102: {
      type: [Object, Array],
      default: () => [],
    },
  },
  data() {
    return {
      footerTopHeight: 0,
      backgroundClass: null
    }
  },
  beforeMount()  {
    if (this.$refs.footerTop) {
      window.addEventListener('resize', this.resizeHandler);
    }
  },
  destroyed() {
    if (this.$refs.footerTop) {
      window.removeEventListener('resize', this.resizeHandler);
    }
  },
  mounted() {
    if (this.$refs.footerTop) {
      this.footerTopHeight = this.$refs.footerTop.clientHeight
      const contentElementCount = document.getElementById('content-wrap').childNodes.length
      if (contentElementCount > 0) {
        const classes = document.getElementById('content-wrap').childNodes[contentElementCount-1].classList
        if (classes.contains('frame-background-light')) {
          this.backgroundClass = 'bg-zinc-100'
        } else if (classes.contains('frame-background-medium')) {
          this.backgroundClass = 'frame-background-medium'
        } else if (classes.contains('frame-background-dark')) {
          this.backgroundClass = 'frame-background-dark'
        }
      }
    }
  },
  methods:  {
    resizeHandler(e)  {
        if (this.$refs.footerTop) {
          this.footerTopHeight = this.$refs.footerTop.clientHeight
        }
    },
},
}
</script>
<style lang="postcss" scoped>
 /deep/ .t3-ce-gallery__container {
   @apply grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-6 gap-4 items-center content-evenly;
   .t3-ce-gallery__row {
    @apply !mt-0;
   }
 }
</style>
