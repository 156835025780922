<template>
  <div>
    <div class="mt-4 mb-8">
      <button class="md:hidden text-sm bg-primary text-white w-full py-2 rounded-lg uppercase btn-dropdown-toggle transition-all" :class="showDropdown ? 'open rounded-b-none' : ''" @click="toggleDropdown">
        <span class="flex flex-row justify-between pl-3 !pr-5  items-baseline">
          {{ $t('filter.show')}}
        </span>
      </button>
      <nav v-if="cleanedCategories" class="bg-boxed md:max-h-max rounded-b-lg md:rounded-t-lg transition-all overflow-hidden" :class="showDropdown ? 'max-h-screen p-3 pb-px overflow-y-scroll' : 'max-h-0'">
        <div class="md:m-3 md:mb-px md:flex md:flex-row md:justify-between ">
          <div>
            <button
              v-for="(item, key) in cleanedCategories"
              :key="key"
              class="btn-pill bg-white/20 border-transparent text-white block w-full mb-3 md:w-auto md:inline-block md:mr-3"
              :class="
                filter.includes(item.uid) ? 'active' : ''
              "
              @click="handleFilter(item.uid)"
            >
              <span class="remove" />{{ item.name }}
            </button>
          </div>
          <transition
            enter-active-class="duration-300 ease-out opacity-0 -translate-y-full md:translate-y-0 md:translate-x-full"
            enter-to-class="opacity-100 translate-y-0 md:translate-x-0"
            leave-active-class="duration-200 ease-in"
            leave-class="opacity-100 translate-y-0 md:translate-x-0"
            leave-to-class="opacity-0 -translate-y-full md:translate-y-0 md:translate-x-full"
            >
            <div v-show="filter.length > 0">
              <button
                class="btn-pill btn-pill--remove mb-3 block w-full md:inline-block md:w-auto whitespace-nowrap self-end"
                @click="resetFilter"
              >
                <span class="remove" />{{ $t('filter.showAll') }}
              </button>
            </div>
          </transition>
        </div>
      </nav>
    </div>
  </div>
</template>
<script>
export default {
  name: 'JoboffersCategories',
  props: {
    store: {
      type: String ,
      required: false,
      default: 'blogposts',
    },
    categories: {
      type: Array,
      required: true,
    },
    showAllLink: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data () {
    return {
      showDropdown: false
    }
  },
  computed: {
    filter() {
      return this.$store.state.categories[this.store].filter
    },
    cleanedCategories() {
      const cleanedCategories = []
      this.categories.forEach(function(category) {
        if (category.jobOffersCount > 0) {
          cleanedCategories.push(category)
        }
      })
      return cleanedCategories
    }
  },
  methods: {
    toggleDropdown () {
      this.showDropdown = !this.showDropdown
    },
    handleFilter (item) {
      this.$store.dispatch(
        'categories/' + this.store + '/filter',
        item
      )
    },
    resetFilter () {
      this.$store.dispatch(
        'categories/' + this.store + '/filterReset'
      )
    },
  },
}
</script>
