var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.content
    ? _c(
        "div",
        { staticClass: "footer-block" },
        _vm._l(_vm.content, function (contentElement, key) {
          return _c("layout-footer-block-item", {
            key: key,
            attrs: { "content-element": contentElement },
          })
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }