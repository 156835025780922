var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "min-h-screen content-wrap frame-background-light",
          attrs: { id: "content-wrap" },
        },
        [
          _c("section", { staticClass: "mt-16 frame-container lg:px-[10%]" }, [
            _c(
              "div",
              {
                staticClass:
                  "relative before:content-[''] before:rounded-md before:outline-2 before:outline before:outline-primary before:absolute before:-top-5 before:-right-5 before:bottom-5 before:left-5 before:z-10",
              },
              [
                _vm.page.categories.length > 0
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "bg-primary uppercase rounded-b-[3px] text-xs inline-block text-white px-5 py-2 absolute -top-5 left-10 z-20",
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.page.categories[0].title) +
                            "\n        "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "figure",
                  {
                    staticClass:
                      "rounded-md relative overflow-hidden lg:h-[500px] xl:h-[600px]",
                  },
                  [
                    _vm.page.media.length > 0
                      ? _c("img", {
                          directives: [
                            { name: "lazy-load", rawName: "v-lazy-load" },
                          ],
                          staticClass:
                            "w-full lg:absolute top-1/2 lg:-translate-y-1/2",
                          attrs: {
                            "data-src": _vm.page.media[0].publicUrl,
                            alt: _vm.page.media[0].properties.filename,
                            width:
                              _vm.page.media[0].properties.dimensions.width,
                            height:
                              _vm.page.media[0].properties.dimensions.height,
                          },
                        })
                      : _vm._e(),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "frame-background-light relative lg:top-[-104px] rounded-tr-xl pt-6 lg:w-3/4 z-20 lg:float-left lg:mr-5",
              },
              [
                _c("p", { staticClass: "text-quinary font-medium" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$moment
                        .unix(
                          _vm.page.starttime
                            ? _vm.page.starttime
                            : _vm.page.crdate
                        )
                        .format("D/M/YYYY")
                    )
                  ),
                ]),
                _vm._v(" "),
                _c("h2", { staticClass: "lg:mr-5" }, [
                  _vm._v(_vm._s(_vm.page.meta.title)),
                ]),
                _vm._v(" "),
                _c("t3-renderer", { attrs: { content: _vm.content.colPos0 } }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.page.suresecureAuthor.length > 0
              ? _c(
                  "div",
                  { staticClass: "mb-7 lg:mt-9" },
                  _vm._l(_vm.page.suresecureAuthor, function (author, key) {
                    return _c(
                      "div",
                      { key: key, staticClass: "flex items-center gap-3 mb-3" },
                      [
                        _c("img", {
                          directives: [
                            { name: "lazy-load", rawName: "v-lazy-load" },
                          ],
                          staticClass: "w-[60px] rounded-full",
                          attrs: {
                            "data-src": author.content.image[0].publicUrl,
                            alt: author.content.image[0].properties.filename,
                            width:
                              author.content.image[0].properties.dimensions
                                .width,
                            height:
                              author.content.image[0].properties.dimensions
                                .height,
                          },
                        }),
                        _vm._v(" "),
                        _c("p", { staticClass: "text-grey leading-6" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(key > 0 ? _vm.$t("and") : _vm.$t("by")) +
                              " "
                          ),
                          _c("span", { staticClass: "text-boxed font-bold" }, [
                            _vm._v(_vm._s(author.content.name)),
                          ]),
                          _c("br"),
                          _vm._v(
                            "\n            " +
                              _vm._s(author.content.title) +
                              "\n          "
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.page.relatedBlogPosts.length > 0
            ? _c("div", { staticClass: "frame-container" }, [
                _c("p", { staticClass: "h2 !mb-14" }, [
                  _vm._v(_vm._s(_vm.$t("moreArticles"))),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-4 gap-y-8 mb-20",
                  },
                  _vm._l(_vm.page.relatedBlogPosts, function (item, key) {
                    return _c(
                      "div",
                      { key: key, staticClass: "col-span-1" },
                      [_c("blog-item", { attrs: { content: item } })],
                      1
                    )
                  }),
                  0
                ),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("layout-footer-content", {
        staticClass: "mb-5",
        attrs: {
          col90: _vm.content.colPos90,
          col91: _vm.content.colPos91,
          col92: _vm.content.colPos92,
          col93: _vm.content.colPos93,
          col94: _vm.content.colPos94,
          col95: _vm.content.colPos95,
          col101: _vm.content.colPos101,
          col102: _vm.content.colPos102,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }