<template>
  <div v-if="content" class="footer-block">
    <layout-footer-block-item
      v-for="(contentElement, key) in content"
      :key="key"
      :content-element="contentElement"
    />
  </div>
</template>
<script>
export default {
  props: {
    content: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
