var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "center-items" }, [
    _vm.downloadedSvg
      ? _c("div", { domProps: { innerHTML: _vm._s(_vm.downloadedSvg) } })
      : _c("img", {
          directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
          attrs: {
            "data-src": _vm.image.publicUrl,
            alt: _vm.title,
            width: _vm.image.properties.dimensions.width,
            height: _vm.image.properties.dimensions.height,
          },
        }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }