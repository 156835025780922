var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "relative",
      on: {
        mouseleave: function ($event) {
          _vm.item.children ? _vm.hideChildren() : ""
        },
      },
    },
    [
      _c(
        "nuxt-link",
        {
          staticClass:
            "block p-3 lg:px-2.5 xl:px-5 font-medium lg:text-xxs xl:text-xs text-white transition-all cursor-pointer hover:text-primary",
          class: [
            _vm.isShowChildren ? "open" : "",
            _vm.item.children ? "btn-dropdown-toggle" : "",
          ],
          attrs: { to: _vm.item.link, "active-class": "text-primary" },
          nativeOn: {
            mouseenter: function ($event) {
              _vm.item.children ? _vm.showChildren() : ""
            },
          },
        },
        [_c("span", [_vm._v(_vm._s(_vm.item.title))])]
      ),
      _vm._v(" "),
      _c(
        "transition",
        {
          attrs: {
            "enter-active-class": "transition ease-out duration-100",
            "enter-class": "opacity-0 transform scale-95",
            "enter-to-class": "opacity-100 transform scale-100",
            "leave-active-class": "transition ease-in duration-75",
            "leave-class": "opacity-100 transform scale-100",
            "leave-to-class": "opacity-0 transform scale-95",
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isShowChildren && _vm.item.children,
                  expression: "isShowChildren && item.children",
                },
              ],
              staticClass:
                "absolute before:absolute before:inset-x-[-9999px] -right-10 -left-1 z-20 before:z-20 before:h-[3px] text-sm text-white before:content-[''] origin-top-left before:bg-primary lg:min-w-[calc(100%+60px)]",
              attrs: { "aria-haspopup": "true" },
            },
            [_c("layout-nested-menu", { attrs: { source: _vm.item } })],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }