<template>
  <div
    v-if="header && headerLayout !== 100"
    :class="headerCss"
    class="t3-ce-header"
  >
    <div v-if="skyline" class="text-md sm:text-lg text-grey font-bold uppercase mb-3 sm:mb-7" :class="[`text-${headerPosition}`]">{{ skyline }}</div>
    <component
      :is="`h${headerLevel}`"
      v-if="headerLayout >= 0 && headerLayout !== 100"
      :class="[`text-${headerPosition}`, `h${headerSize}`]"
    >
      <t3-nav-link v-if="headerLink" :to="headerLink.href">
        <span :class="{'text-primary' : headerHighlight}" v-html="header" />
        <br v-if="headerBreak" class="hidden md:block">
        <template v-if="headerAddOne">
          <span :class="{'text-primary' : headerAddOneHighlight}" v-html="headerAddOne" />
          <br v-if="headerAddOneBreak" class="hidden md:block">
        </template>
        <template v-if="headerAddTwo">
          <span :class="{'text-primary' : headerAddTwoHighlight}" v-html="headerAddTwo" />         
          <br v-if="headerAddTwoBreak" class="hidden md:block">
        </template>
        <template v-if="headerAddThree">
          <span :class="{'text-primary' : headerAddThreeHighlight}" v-html="headerAddThree" />          
          <br v-if="headerAddThreeBreak" class="hidden md:block">
        </template>
        <template v-if="headerAddFour">
          <span :class="{'text-primary' : headerAddFourHighlight}" v-html="headerAddFour" />          
          <br v-if="headerAddFourBreak" class="hidden md:block">
        </template>
        <span v-if="headerAddFive" :class="{'text-primary' : headerAddFiveHighlight}" v-html="headerAddFive" />
      </t3-nav-link>
      <template v-else>
        <span :class="{'text-primary' : headerHighlight}" v-html="header" />
        <br v-if="headerBreak" class="hidden md:block">
        <template v-if="headerAddOne">
          <span :class="{'text-primary' : headerAddOneHighlight}" v-html="headerAddOne" />
          <br v-if="headerAddOneBreak" class="hidden md:block">
        </template>
        <template v-if="headerAddTwo">
          <span :class="{'text-primary' : headerAddTwoHighlight}" v-html="headerAddTwo" />         
          <br v-if="headerAddTwoBreak" class="hidden md:block">
        </template>
        <template v-if="headerAddThree">
          <span :class="{'text-primary' : headerAddThreeHighlight}" v-html="headerAddThree" />          
          <br v-if="headerAddThreeBreak" class="hidden md:block">
        </template>
        <template v-if="headerAddFour">
          <span :class="{'text-primary' : headerAddFourHighlight}" v-html="headerAddFour" />          
          <br v-if="headerAddFourBreak" class="hidden md:block">
        </template>
        <span v-if="headerAddFive" :class="{'text-primary' : headerAddFiveHighlight}" v-html="headerAddFive" />
      </template>
    </component>
    <component :is="`h${headerLevel + 1}`" v-if="subheader">
      {{ subheader }}
    </component>
  </div>
</template>

<script>
import baseCe from '../mixins/baseCe'
export default {
  name: 'T3CeHeader',
  extends: baseCe,
  computed: {
    headerLevel () {
      // by defualt if type is 0, set h1
      return this.headerLayout === 0 ? 1 : this.headerLayout
    },
    headerSize () {
      return this.headerClass
    },
    headerCss () {
      return this.headerPosition ? `t3-ce-header--${this.headerPosition}` : ''
    }
  }
}
</script>
