<template>
  <div>
    <div
      class="rounded-full border-8 border-transparent h-18 lg:h-24 hover:border-danger/50 transition-all duration-500"
    >
      <button
        role="button"
        aria-label="Open Icident Response Layer"
        class="h-14 lg:h-20 w-14 lg:w-20 rounded-full text-center bg-danger"
        @click="show = !show"
      >
        <transition
          enter-active-class="transition ease-out duration-100"
          enter-class="opacity-0 transform"
          enter-to-class="opacity-100 transform"
          leave-active-class="transition ease-in duration-75"
          leave-class="opacity-100 transform"
          leave-to-class="opacity-0 transform"
        >
          <ExtinguisherIcon v-if="!show" class="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 inline-block h-8 lg:h-12" />
          <div v-if="show">
            <span
              class="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 z-50 space-y-2 pointer-events-none"
              ><span
                class="block w-6 lg:w-12 h-0.5 bg-white rotate-45 translate-y-1.5"
              ></span>
              <span
                class="block w-6 lg:w-12 h-0.5 bg-white -rotate-45 -translate-y-1"
              ></span
            ></span>
          </div>
        </transition>
      </button>
      <transition
        enter-active-class="transition ease-out duration-100"
        enter-class="opacity-0 transform scale-95"
        enter-to-class="opacity-100 transform scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-class="opacity-100 transform scale-100"
        leave-to-class="opacity-0 transform scale-95"
      >
        <div v-if="show" class="absolute right-1/2 bottom-full mb-5">
          <div
            class="bg-boxed text-white rounded-lg rounded-br-none w-72 md:w-80 overflow-hidden"
          >
            <div class="border-l-4 border-l-danger p-6">
              <h4 class="text-lg mb-1">Incident Response</h4>
              <p class="mb-8">{{ $t('incidentResponse.text') }}</p>
              <p class="mb-2">
                <a
                  href="mailto:911@suresecure.de"
                  class="text-danger whitespace-nowrap flex items-center font-medium"
                  ><MailIcon
                    class="w-5 h-5 mr-2 inline-flex"
                  />911@suresecure.de</a
                >
              </p>
              <p class="mb-8">
                <a
                  href="tel:+49021569749110"
                  title="+49 (0) 2156 97 49 110"
                  class="text-danger whitespace-nowrap flex items-center font-medium"
                  ><PhoneIcon class="w-5 h-5 mr-2 inline-flex" />+49 (0) 2156 97 49 110</a
                >
              </p>
              <a
                :href="'mailto:911@suresecure.de?subject=' + $t('incident')"
                class="btn-lg btn-danger px-2 py-3 text-center whitespace-nowrap block"
                >{{ $t('incidentResponse.contact') }}</a
              >
            </div>
          </div>
          <div class="tip" />
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import MailIcon from '~/assets/icons/mail-outline.svg?inline'
import PhoneIcon from '~/assets/icons/phone-outline.svg?inline'
import ExtinguisherIcon from '~/assets/icons/extinguisher-outline-white.svg?inline'
export default {
  components: {
    ExtinguisherIcon,
    MailIcon,
    PhoneIcon,
  },
  data() {
    return {
      show: false,
    }
  },
}
</script>
<style lang="postcss" scoped>
.tip {
  @apply absolute top-full right-0 bg-boxed w-4 h-4;
  mask-image: url(@/assets/icons/tip-mask.svg);
  mask-size: cover;
}
</style>
