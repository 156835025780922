export default ({ app }) => {
  app.router.afterEach((to) => {
    setTimeout(() => {
      const links = document.querySelectorAll('a[href*="calendly"]')
      links.forEach(function (link) {
        link.addEventListener('click', function (e) {
          e.preventDefault()
          // eslint-disable-next-line no-undef
          Calendly.initPopupWidget({ url: link.getAttribute('href') })
        })
      })
    }, 250)
  })
}
