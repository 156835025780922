var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "t3-ce-gallery", class: _vm.galleryClass },
    [
      _vm._t("before"),
      _vm._v(" "),
      _vm.gallery.position.vertical === "below"
        ? _c(
            "div",
            { staticClass: "t3-ce-gallery__text" },
            [_vm._t("default")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.gallery.count.files
        ? _c(
            "div",
            { staticClass: "t3-ce-gallery__container relative" },
            _vm._l(_vm.gallery.rows, function (row, rowKey) {
              return _c(
                "div",
                {
                  key: rowKey,
                  staticClass: "t3-ce-gallery__row",
                  class: rowKey > 1 ? "mt-8" : "",
                },
                _vm._l(row.columns, function (col, colKey) {
                  return _c(
                    _vm.enlargeImageOnClick ? "a" : "div",
                    _vm._b(
                      {
                        key: colKey,
                        tag: "component",
                        staticClass: "t3-ce-gallery__col",
                      },
                      "component",
                      {
                        ...(_vm.enlargeImageOnClick && {
                          target: "_blank",
                          href: col.publicUrl,
                        }),
                      },
                      false
                    ),
                    [_c("t3-media-file", { attrs: { file: col } })],
                    1
                  )
                }),
                1
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.gallery.position.vertical === "above" ||
      _vm.gallery.position.vertical === "intext"
        ? _c(
            "div",
            { staticClass: "t3-ce-gallery__text" },
            [_vm._t("default")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._t("after"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }