var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.header && _vm.headerLayout !== 100
    ? _c(
        "div",
        { staticClass: "t3-ce-header", class: _vm.headerCss },
        [
          _vm.skyline
            ? _c(
                "div",
                {
                  staticClass:
                    "text-md sm:text-lg text-grey font-bold uppercase mb-3 sm:mb-7",
                  class: [`text-${_vm.headerPosition}`],
                },
                [_vm._v(_vm._s(_vm.skyline))]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.headerLayout >= 0 && _vm.headerLayout !== 100
            ? _c(
                `h${_vm.headerLevel}`,
                {
                  tag: "component",
                  class: [`text-${_vm.headerPosition}`, `h${_vm.headerSize}`],
                },
                [
                  _vm.headerLink
                    ? _c(
                        "t3-nav-link",
                        { attrs: { to: _vm.headerLink.href } },
                        [
                          _c("span", {
                            class: { "text-primary": _vm.headerHighlight },
                            domProps: { innerHTML: _vm._s(_vm.header) },
                          }),
                          _vm._v(" "),
                          _vm.headerBreak
                            ? _c("br", { staticClass: "hidden md:block" })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.headerAddOne
                            ? [
                                _c("span", {
                                  class: {
                                    "text-primary": _vm.headerAddOneHighlight,
                                  },
                                  domProps: {
                                    innerHTML: _vm._s(_vm.headerAddOne),
                                  },
                                }),
                                _vm._v(" "),
                                _vm.headerAddOneBreak
                                  ? _c("br", { staticClass: "hidden md:block" })
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.headerAddTwo
                            ? [
                                _c("span", {
                                  class: {
                                    "text-primary": _vm.headerAddTwoHighlight,
                                  },
                                  domProps: {
                                    innerHTML: _vm._s(_vm.headerAddTwo),
                                  },
                                }),
                                _vm._v(" "),
                                _vm.headerAddTwoBreak
                                  ? _c("br", { staticClass: "hidden md:block" })
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.headerAddThree
                            ? [
                                _c("span", {
                                  class: {
                                    "text-primary": _vm.headerAddThreeHighlight,
                                  },
                                  domProps: {
                                    innerHTML: _vm._s(_vm.headerAddThree),
                                  },
                                }),
                                _vm._v(" "),
                                _vm.headerAddThreeBreak
                                  ? _c("br", { staticClass: "hidden md:block" })
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.headerAddFour
                            ? [
                                _c("span", {
                                  class: {
                                    "text-primary": _vm.headerAddFourHighlight,
                                  },
                                  domProps: {
                                    innerHTML: _vm._s(_vm.headerAddFour),
                                  },
                                }),
                                _vm._v(" "),
                                _vm.headerAddFourBreak
                                  ? _c("br", { staticClass: "hidden md:block" })
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.headerAddFive
                            ? _c("span", {
                                class: {
                                  "text-primary": _vm.headerAddFiveHighlight,
                                },
                                domProps: {
                                  innerHTML: _vm._s(_vm.headerAddFive),
                                },
                              })
                            : _vm._e(),
                        ],
                        2
                      )
                    : [
                        _c("span", {
                          class: { "text-primary": _vm.headerHighlight },
                          domProps: { innerHTML: _vm._s(_vm.header) },
                        }),
                        _vm._v(" "),
                        _vm.headerBreak
                          ? _c("br", { staticClass: "hidden md:block" })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.headerAddOne
                          ? [
                              _c("span", {
                                class: {
                                  "text-primary": _vm.headerAddOneHighlight,
                                },
                                domProps: {
                                  innerHTML: _vm._s(_vm.headerAddOne),
                                },
                              }),
                              _vm._v(" "),
                              _vm.headerAddOneBreak
                                ? _c("br", { staticClass: "hidden md:block" })
                                : _vm._e(),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.headerAddTwo
                          ? [
                              _c("span", {
                                class: {
                                  "text-primary": _vm.headerAddTwoHighlight,
                                },
                                domProps: {
                                  innerHTML: _vm._s(_vm.headerAddTwo),
                                },
                              }),
                              _vm._v(" "),
                              _vm.headerAddTwoBreak
                                ? _c("br", { staticClass: "hidden md:block" })
                                : _vm._e(),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.headerAddThree
                          ? [
                              _c("span", {
                                class: {
                                  "text-primary": _vm.headerAddThreeHighlight,
                                },
                                domProps: {
                                  innerHTML: _vm._s(_vm.headerAddThree),
                                },
                              }),
                              _vm._v(" "),
                              _vm.headerAddThreeBreak
                                ? _c("br", { staticClass: "hidden md:block" })
                                : _vm._e(),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.headerAddFour
                          ? [
                              _c("span", {
                                class: {
                                  "text-primary": _vm.headerAddFourHighlight,
                                },
                                domProps: {
                                  innerHTML: _vm._s(_vm.headerAddFour),
                                },
                              }),
                              _vm._v(" "),
                              _vm.headerAddFourBreak
                                ? _c("br", { staticClass: "hidden md:block" })
                                : _vm._e(),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.headerAddFive
                          ? _c("span", {
                              class: {
                                "text-primary": _vm.headerAddFiveHighlight,
                              },
                              domProps: {
                                innerHTML: _vm._s(_vm.headerAddFive),
                              },
                            })
                          : _vm._e(),
                      ],
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.subheader
            ? _c(`h${_vm.headerLevel + 1}`, { tag: "component" }, [
                _vm._v("\n    " + _vm._s(_vm.subheader) + "\n  "),
              ])
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }