<template>
  <div
    class="flex relative flex-wrap justify-between items-center xl:container z-[1000]"
  >
    <div
      class="flex flex-col content-center pt-2 mt-8 w-full text-center lg:flex-row lg:justify-between lg:items-end lg:text-left"
    >
      <nuxt-link :to="navMain.link" @click.native="closeNav">
        <svg-logo class="m-4 lg:ml-16 h-8 lg:mt-0 xl:ml-0" />
        <span class="sr-only">sure[secure] Home</span>
      </nuxt-link>
      <layout-main-nav
        v-if="navMain && navMain.children"
        :nav-main="navMain"
      />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import SvgLogo from '~/assets/images/logo-white.svg?inline'
export default {
  components: {
    SvgLogo,
  },
  computed: {
    ...mapState({
      navMain: (state) => state.typo3.initial.navigation[0] || [],
    }),
  },
  methods: {
    closeNav() {
      this.$nuxt.$emit('close-nav')
      this.$gsap.to(window, { duration: 1, scrollTo: { X: 0, y: 0 } })
    }
  }
}
</script>
