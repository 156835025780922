<template>
  <div class="mb-10">
    <t3-ce-header v-bind="$props" />
<div v-if="selectedCategories == 0">
      <template v-if="categories.length > 1">
          <div class="text-lg">
              <strong> {{ $t('blog.filter') }} </strong>
          </div>
          <NewsCategories
              :categories="categories"
              store="blogposts"
              :show-all-link="true"
          />
      </template>
</div>
    <client-only>
      <masonry-wall :items="filteredItemsArray" :ssr-columns="4" :column-width="300" :gap="16">
        <template #default="{ item }">
          <transition appear appear-class="opacity-0" appear-to-class="opacity-100" class="transition-all">
            <blog-item :content="item" class="transition-all" />
          </transition>
        </template>
      </masonry-wall>
    </client-only>
      <button v-if="areThereMore" class="btn-loadmore block md:max-w-[25%] mx-auto mt-10" @click="loadMore()">
          {{ $t('loadMore') }}
      </button>

  </div>
</template>
<script>
import baseCe from '../mixins/baseCe'
import baseNews from '../mixins/baseNews'
import NewsCategories from '../partials/News/NewsCategories'
import BlogItem from '../partials/News/BlogItem'
export default {
  name: 'T3CeSuresecureBlogList',
  components: {
    NewsCategories,
    BlogItem,
  },
  extends: baseCe,
  mixins: [baseNews],
  data() {
    return {
      store: 'blogposts',
    }
  },
}
</script>
