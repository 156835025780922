<template>
  <div>
    <template v-if="categories.length > 1">
      <div class="text-lg">
        <strong> {{ $t('events.filter') }} </strong>
      </div>
      <NewsCategories
        :categories="categories"
        store="events"
        :show-all-link="true"
      />
    </template>
    <client-only>
      <masonry-wall :items="filteredItemsArray" :ssr-columns="4" :column-width="300" :gap="16">
        <template #default="{ item }">
          <event-item :content="item" class="transition-all" />
        </template>
      </masonry-wall>
    </client-only>
    <button v-if="areThereMore" class="btn-loadmore block md:max-w-[25%] mx-auto mt-10" @click="loadMore()">
      {{ $t('loadMore') }}
    </button>
  </div>
</template>
<script>
import baseCe from '../mixins/baseCe'
import baseNews from '../mixins/baseNews'
import EventItem from '../partials/News/EventItem'
import NewsCategories from '../partials/News/NewsCategories'
export default {
  name: 'T3CeSuresecureEventList',
  components: {
    NewsCategories,
    EventItem,
  },
  extends: baseCe,
  mixins: [baseNews],
  data() {
    return {
      store: 'events',
    }
  },
}
</script>
