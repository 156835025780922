var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "relative" }, [
    _c(
      "div",
      { staticClass: "flex flex-row items-end justify-between" },
      [
        _c("t3-ce-header", _vm._b({}, "t3-ce-header", _vm.$props, false)),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "ml-auto whitespace-nowrap",
            class: [!_vm.makeItCarousel ? "hidden" : "block"],
          },
          [
            _c(
              "span",
              {
                staticClass:
                  "cursor-pointer w-10 h-10 inline-block rotate-180 hover:bg-grey/20 rounded-full",
                on: { click: _vm.swipeLeft },
              },
              [_c("IconArrowForward")],
              1
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass:
                  "cursor-pointer w-10 h-10 inline-block hover:bg-grey/20 rounded-full",
                on: { click: _vm.swipeRight },
              },
              [_c("IconArrowForward")],
              1
            ),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _vm.menu
      ? _c(
          "ul",
          {
            ref: "snap",
            staticClass:
              "gap-4 py-2 -mx-3 px-3 flex flex-row flex-nowrap snap-mandatory hide-scrollbar",
            class: _vm.makeItCarousel
              ? "xl:-mr-container-margin overflow-x-auto snap-x"
              : "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3",
          },
          _vm._l(_vm.menu, function (item, key) {
            return _c(
              "li",
              {
                key: key,
                ref: "items",
                refInFor: true,
                staticClass:
                  "w-full shrink-0 hover:scale-105 transition-all duration-300",
                class: {
                  "flex-shrink-0 snap-start md:w-[calc(((var(--screen-width))/3)-(theme(gap.4)*2)/theme(columns.3)-theme(gap.1))] xl:w-[calc(((var(--current-screen))/3)-(theme(gap.4)*2)/theme(columns.3))]":
                    _vm.makeItCarousel,
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "flex flex-col bg-boxed rounded-lg h-full px-6 py-10 text-white",
                  },
                  [
                    _c("p", { staticClass: "text-xl font-bold mb-4" }, [
                      _vm._v(_vm._s(item.teaser_title)),
                    ]),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-quinary text-[14px] leading-[25px] sm:text-[16px] mb-8",
                      },
                      [_vm._v(_vm._s(item.teaser_text))]
                    ),
                    _vm._v(" "),
                    _c(
                      "t3-nav-link",
                      {
                        staticClass:
                          "btn btn-primary btn-lg w-full text-center mt-auto",
                        attrs: {
                          to: item.link,
                          target: item.target || false,
                          title: item.teaser_title,
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("readMore")))]
                    ),
                  ],
                  1
                ),
              ]
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }