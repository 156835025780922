<template>
  <div>
    <div
      class="overflow-hidden absolute top-0 left-0 z-[9999] min-w-full h-screen transition-[left] duration-1500"
      :class="{ 'left-0': isActive }"
    >
      <!-- prev -->
      <layout-nested-menu-panel
        :nav-claim="claimFromParent ? navClaimFromParent : source.navClaim"
        :nav-claim-hide="navClaimHide"
        :desktop="desktop"
        :list="content_prevItem"
        :position-style="panel_prevPositionStyle"
        :is-translating="isTranslating"
        :transition-style="style_transitionStyle"
        :show-header-arrow="prevItemHasParent"
      />
      <!-- staging -->
      <layout-nested-menu-panel
        :nav-claim="claimFromParent ? navClaimFromParent : source.navClaim"
        :nav-claim-hide="navClaimHide"
        :desktop="desktop"
        :list="content_currentItem"
        :position-style="panel_stagingPositionStyle"
        :is-translating="isTranslating"
        :transition-style="style_transitionStyle"
        :show-header-arrow="currentItemHasParent"
        :handle-header-clicked="clickPrevItem"
        :handle-item-clicked="clickNextItem"
      />
      <!-- next -->
      <layout-nested-menu-panel
        :nav-claim="claimFromParent ? navClaimFromParent : source.navClaim"
        :nav-claim-hide="navClaimHide"
        :desktop="desktop"
        :list="content_nextItem"
        :position-style="panel_nextPositionStyle"
        :is-translating="isTranslating"
        :transition-style="style_transitionStyle"
        :show-header-arrow="true"
      />

      <nuxt-link
        :to="contactLink"
        class="fixed inset-x-3 bottom-4 py-3 text-sm font-medium text-white uppercase rounded-md bg-primary lg:hidden"
        >{{ $t('contact') }}</nuxt-link
      >

    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  props: {
    source: {
      type: Object,
      default: () => {},
    },
    claimFromParent: {
      type: Boolean,
      default: false,
    },
    desktop: {
      type: Boolean,
      default: false,
    },
    menuOpenSpeed: {
      type: Number,
      default: 350,
    },
    menuSwitchSpeed: {
      type: Number,
      default: 300,
    },
  },
  data() {
    return {
      isActive: false,
      isTranslating: false,
      style_transitionStyle: {},
      content_prevItem: {},
      content_currentItem: {},
      content_nextItem: {},
      content_parentStack: [],
      panel_prevPositionStyle: {},
      panel_stagingPositionStyle: {},
      panel_nextPositionStyle: {},
      navClaimFromParent: '',
      navClaimHide: false,
    }
  },
  computed: {
    ...mapState({
      contactLink: (state) => state.typo3.initial.contactLink || [],
    }),
    $_panelControl_positionSet() {
      return {
        staging: {
          left: 0,
        },
        prev: {
          left: '-100%',
        },
        next: {
          left: '100%',
        },
      }
    },
    currentItemHasParent() {
      return this.content_parentStack.length >= 1
    },
    prevItemHasParent() {
      return this.content_parentStack.length >= 2
    },
  },
  watch: {
    list() {
      this.content_currentItem = this.source
    },
  },
  mounted() {
    this.content_currentItem = this.source

    this.panel_prevPositionStyle = this.$_panelControl_positionSet.prev
    this.panel_stagingPositionStyle = this.$_panelControl_positionSet.staging
    this.panel_nextPositionStyle = this.$_panelControl_positionSet.next

    const menuSwitchSpeed = this.menuSwitchSpeed
    const menuSwitchTransitionSecond = `.${menuSwitchSpeed / 10}s`

    const transitionStyle = {
      transition: `left ${menuSwitchTransitionSecond}`,
    }

    this.style_transitionStyle = transitionStyle

    if (this.claimFromParent && this.content_parentStack.length === 0) {
      this.navClaimHide = true
    }
  },
  methods: {
    content_setNextItem(targetItem) {
      this.content_nextItem = targetItem
    },
    content_setPrevItem() {
      this.content_prevItem = this.content_parentStack[
        this.content_parentStack.length - 1
      ]
    },
    content_homingItemAfterNext() {
      this.content_prevItem = this.content_currentItem
      this.content_currentItem = this.content_nextItem
      this.content_nextItem = {}
    },
    content_homingItemAfterBack() {
      this.content_parentStack.pop()
      this.content_currentItem = this.content_prevItem
      this.content_nextItem = {}

      if (this.claimFromParent && this.content_parentStack.length === 0) {
        this.navClaimFromParent = ''
        this.navClaimHide = true
      }
    },
    content_pushCurrentToParentStack(item) {
      const parent = this.content_currentItem
      this.content_parentStack.push(parent)
      if (this.claimFromParent && this.content_parentStack.length === 1) {
        this.navClaimFromParent = this.content_nextItem.navClaim
        this.navClaimHide = false
      }
    },
    clickNextItem(targetItem) {
      if (this.isTranslating || !targetItem.children) {
        return
      }
      this.slideToNext(targetItem)
    },
    clickPrevItem() {
      if (this.isTranslating || !this.currentItemHasParent) {
        return
      }
      this.slideToPrev()
    },
    /*
     * main part of core
     * definite of how to handle panel sliding and item updating
     */
    slideToNext(targetItem) {
      this.content_setNextItem(targetItem)
      this.setTranslating(true)
      this.$nextTick(() => {
        this.panel_slideNext()
      })
      this.homingAfterTranslatingNext()
    },
    slideToPrev() {
      this.content_setPrevItem()
      this.setTranslating(true)
      this.$nextTick(() => {
        this.panel_slideBack()
      })
      this.homingAfterTranslatingBack()
    },
    homingAfterTranslatingNext() {
      setTimeout(() => {
        this.setTranslating(false)
        this.content_pushCurrentToParentStack()
        this.panel_homingPosition()
        this.content_homingItemAfterNext()
      }, this.menuSwitchSpeed)
    },
    homingAfterTranslatingBack() {
      setTimeout(() => {
        this.setTranslating(false)
        this.panel_homingPosition()
        this.content_homingItemAfterBack()
      }, this.menuSwitchSpeed)
    },
    // utils
    setTranslating(status) {
      this.isTranslating = status
    },
    panel_slideNext() {
      this.panel_stagingPositionStyle = this.$_panelControl_positionSet.prev
      this.panel_nextPositionStyle = this.$_panelControl_positionSet.staging
    },
    panel_slideBack() {
      this.panel_stagingPositionStyle = this.$_panelControl_positionSet.next
      this.panel_prevPositionStyle = this.$_panelControl_positionSet.staging
    },
    panel_homingPosition() {
      this.panel_prevPositionStyle = this.$_panelControl_positionSet.prev
      this.panel_nextPositionStyle = this.$_panelControl_positionSet.next
      this.panel_stagingPositionStyle = this.$_panelControl_positionSet.staging
    },
  },
}
</script>
