var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "overflow-hidden absolute top-0 z-[9999] w-full text-sm rounded-b-lg border-t-[3px] bg-secondary border-t-primary",
        style: [
          _vm.positionStyle,
          _vm.isTranslating ? _vm.transitionStyle : {},
        ],
        attrs: { "aria-hidden": "true", "aria-label": "submenu" },
      },
      [
        _c(
          "transition",
          {
            attrs: {
              "enter-active-class": "transition-all duration-100 ease-out",
              "enter-class": "transform -translate-y-full opacity-0",
              "enter-to-class": "transform translate-y-0",
              "leave-active-class": "transition-all duration-100 ease-in",
              "leave-class": "translate-y-0 ransform",
              "leave-to-class": "transform -translate-y-full opacity-0",
            },
          },
          [
            _vm.list.title && !_vm.navClaimHide
              ? _c(
                  "div",
                  {
                    staticClass:
                      "flex after:block relative after:absolute after:-bottom-1.5 after:left-7 flex-row items-center p-3 pt-2 mb-4 after:w-3 after:h-3 text-xs text-white after:content-[''] after:rotate-45 bg-primary after:bg-primary",
                  },
                  [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showHeaderArrow,
                            expression: "showHeaderArrow",
                          },
                        ],
                        staticClass:
                          "flex self-start p-1 mr-2 bg-white/30 rounded-full cursor-pointer",
                        on: { click: _vm.handleHeaderClicked },
                      },
                      [
                        _c("ArrowBackIcon", {
                          staticClass: "w-5 h-5 fill-current",
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c(
                          "transition",
                          {
                            attrs: {
                              "enter-active-class":
                                "transition-all duration-100 ease-out",
                              "enter-class":
                                "transform -translate-y-full opacity-0",
                              "enter-to-class": "transform translate-y-0",
                              "leave-active-class":
                                "transition-all duration-100 ease-in",
                              "leave-class": "translate-y-0 ransform",
                              "leave-to-class":
                                "transform -translate-y-full opacity-0",
                            },
                          },
                          [
                            _vm.navClaim != ""
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "block text-base font-light text-left -translate-y-0.5",
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.navClaim) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "nuxt-link",
                          {
                            staticClass:
                              "block text-xs font-medium uppercase group",
                            attrs: { to: _vm.list.link },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.closeNav()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.list.title) +
                                "\n            "
                            ),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "inline-block w-3.5 h-3.5 translate-y-0.5 cursor-pointer",
                              },
                              [
                                _c("ArrowForwardIcon", {
                                  staticClass:
                                    "fill-current group-hover:translate-x-4 transition-all ease-out",
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c(
          "ul",
          _vm._l(_vm.list.children, function (item, key) {
            return _c(
              "li",
              {
                key: key,
                staticClass:
                  "group px-3 py-5 first:mt-3 hover:pl-4 uppercase font-medium text-left text-white hover:bg-white/20 border-l-2 border-transparent transition-all duration-200 cursor-pointer hover:border-primary",
              },
              [
                _c(
                  "nuxt-link",
                  {
                    staticClass: "block",
                    attrs: {
                      to: item.link,
                      event: item.children ? "" : "click",
                      "active-class": "text-primary",
                    },
                    nativeOn: {
                      click: function ($event) {
                        item.children
                          ? _vm.handleItemClicked(item)
                          : _vm.closeNav()
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        class: item.children
                          ? "link-chevron-right flex flex-row justify-between group-hover:after:translate-x-1 after:transition-all after:duration-300"
                          : "",
                      },
                      [_vm._v(_vm._s(item.title))]
                    ),
                  ]
                ),
              ],
              1
            )
          }),
          0
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }