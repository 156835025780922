var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "min-h-screen content-wrap overflow-x-hidden",
          attrs: { id: "content-wrap" },
        },
        [_c("t3-renderer", { attrs: { content: _vm.content.colPos0 } })],
        1
      ),
      _vm._v(" "),
      _c("layout-footer-content", {
        staticClass: "mb-5",
        attrs: {
          col90: _vm.content.colPos90,
          col91: _vm.content.colPos91,
          col92: _vm.content.colPos92,
          col93: _vm.content.colPos93,
          col94: _vm.content.colPos94,
          col95: _vm.content.colPos95,
          col101: _vm.content.colPos101,
          col102: _vm.content.colPos102,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }