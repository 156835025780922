var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex h-full flex-col rounded-b-lg bg-boxed" },
    [
      _c(
        "div",
        {
          staticClass:
            "relative rounded-t-lg bg-white px-7 pb-7 transition-all duration-300 ease-in-out after:absolute after:inset-x-0 after:bottom-0 after:hidden after:h-28 after:bg-gradient-to-t after:from-white after:transition-opacity after:duration-300 after:content-[''] lg:after:block",
          class: [
            _vm.priceboxItem.content.backgroundImage[0] ? "pt-16" : "pt-7",
            !_vm.isOpen
              ? "lg:max-h-[300px] lg:after:opacity-100"
              : "lg:max-h-[1000px] lg:after:opacity-0",
          ],
          on: {
            mouseenter: function ($event) {
              _vm.isOpen = !_vm.isOpen
            },
            mouseleave: function ($event) {
              _vm.isOpen = !_vm.isOpen
            },
          },
        },
        [
          _vm.priceboxItem.content.backgroundImage[0]
            ? _c("img", {
                directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
                staticClass:
                  "absolute -top-16 left-2/4 z-10 max-w-[100px] -translate-x-1/2",
                attrs: {
                  "data-src":
                    _vm.priceboxItem.content.backgroundImage[0].publicUrl,
                  alt: _vm.priceboxItem.content.header,
                  width:
                    _vm.priceboxItem.content.backgroundImage[0].properties
                      .dimensions.width,
                  height:
                    _vm.priceboxItem.content.backgroundImage[0].properties
                      .dimensions.height,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.priceboxItem.content.backgroundImage[0]
            ? _c("span", {
                staticClass:
                  "absolute -top-12 left-2/4 block h-28 w-28 -translate-x-1/2 rounded-full bg-white",
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.priceboxItem.content.header
            ? _c(
                "h3",
                { staticClass: "mb-4 text-2xl font-bold text-secondary" },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.priceboxItem.content.header) +
                      "\n    "
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.priceboxItem.content.subheader
            ? _c(
                "h4",
                { staticClass: "mb-3 text-md font-medium text-primary" },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.priceboxItem.content.subheader) +
                      "\n    "
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("t3-html-parser", {
            staticClass: "text-md text-secondary",
            attrs: { content: _vm.priceboxItem.content.bodytext },
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass:
                "absolute -bottom-4 left-6 z-20 rounded-t-lg bg-boxed px-4 pt-2 text-[14px] font-medium uppercase leading-[36px] text-white",
            },
            [_vm._v(_vm._s(_vm.$t("ourServices")))]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "relative break-words p-7" },
        [
          _c("t3-html-parser", {
            staticClass: "relative mb-8 font-medium",
            attrs: { content: _vm.priceboxItem.content.bodytext2 },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.priceboxItem.content.link
        ? [
            _vm.isExternalLink(_vm.priceboxItem.content.link)
              ? _c(
                  "a",
                  {
                    staticClass:
                      "btn btn-primary btn-lg mx-4 mt-auto mb-4 block w-auto text-center",
                    attrs: { href: _vm.priceboxItem.content.link },
                  },
                  [
                    _vm.priceboxItem.content.buttonText
                      ? [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.priceboxItem.content.buttonText) +
                              "\n      "
                          ),
                        ]
                      : [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("readMore")) +
                              "\n      "
                          ),
                        ],
                  ],
                  2
                )
              : _c(
                  "nuxt-link",
                  {
                    staticClass:
                      "btn btn-primary btn-lg mx-4 mt-auto mb-4 block w-auto text-center",
                    attrs: { to: _vm.priceboxItem.content.link },
                  },
                  [
                    _vm.priceboxItem.content.buttonText
                      ? [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.priceboxItem.content.buttonText) +
                              "\n      "
                          ),
                        ]
                      : [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("readMore")) +
                              "\n      "
                          ),
                        ],
                  ],
                  2
                ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }