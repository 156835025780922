var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("t3-ce-header", _vm._b({}, "t3-ce-header", _vm.$props, false)),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "text-white" },
        [
          _vm.data.filterOptions.categories.length > 1
            ? [
                _c("div", { staticClass: "text-lg" }, [
                  _c("strong", [
                    _vm._v(" " + _vm._s(_vm.$t("joboffers.filter")) + " "),
                  ]),
                ]),
                _vm._v(" "),
                _c("Categories", {
                  attrs: {
                    categories: _vm.data.filterOptions.categories,
                    store: "joboffers",
                    "show-all-link": true,
                  },
                }),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "frame-width80" }, [
            _c(
              "div",
              { staticClass: "frame-container" },
              [
                _c("div", { staticClass: "mt-16" }, [
                  _c("strong", [_vm._v(_vm._s(_vm.filteredItemsArray.length))]),
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          `result${
                            _vm.filteredItemsArray.length !== 1 ? "s" : ""
                          }`
                        )
                      ) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _vm._l(_vm.filteredItemsArray, function (item, key) {
                  return _c(
                    "div",
                    {
                      key: key,
                      staticClass:
                        "rounded-md p-6 px-8 my-4 mb-5 text-white bg-boxed md:flex md:flex-row md:justify-between md:items-center",
                    },
                    [
                      _c("div", { staticClass: "w-full max-w-3xl" }, [
                        _c(
                          "h4",
                          [
                            _c(
                              "nuxt-link",
                              {
                                attrs: {
                                  to:
                                    _vm.data.jobOfferDetails + "/" + item.slug,
                                },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(item.title) +
                                    " " +
                                    _vm._s(item.gender) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "md:flex md:flex-row md:gap-8" },
                          [
                            item.experience
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex flex-row items-center mb-3 mt-6 md:my-0",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "bg-white/20 rounded-full w-8 h-8 p-1.5 mr-2",
                                      },
                                      [
                                        _c("IconCalendar", {
                                          staticClass: "mx-auto",
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("strong", { staticClass: "text-xs" }, [
                                      _vm._v(_vm._s(item.experience)),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex flex-row items-center mb-3 md:mb-0",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "bg-white/20 rounded-full w-8 h-8 p-1.5 mr-2",
                                  },
                                  [
                                    _c("IconMapMarker", {
                                      staticClass: "mx-auto",
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("strong", { staticClass: "text-xs" }, [
                                  _vm._v(_vm._s(item.location.name)),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex flex-row items-center mb-6 md:mb-0",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "bg-white/20 rounded-full w-8 h-8 p-1.5 mr-2",
                                  },
                                  [_c("IconClock", { staticClass: "mx-auto" })],
                                  1
                                ),
                                _vm._v(" "),
                                _c("strong", { staticClass: "text-xs" }, [
                                  _vm._v(_vm._s(item.employment.name)),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "nuxt-link",
                            {
                              staticClass:
                                "link-arrow text-white text-sm whitespace-nowrap md:ml-6 hover:text-primary",
                              attrs: {
                                to: _vm.data.jobOfferDetails + "/" + item.slug,
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("joboffers.applyNow")) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }),
              ],
              2
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }