<template>
  <div class="relative p-4 rounded-lg transition-colors mb-5 cursor-pointer" :class="{ 'bg-white' : visible, 'bg-boxed': !visible }">
    <div
      :class="{'accordion__trigger_active': visible}"
      @click="open">
      <slot name="accordion-trigger">
        <div class="flex items-center">
          <div v-if="accordionItem.content.backgroundImage[0]" class="relative">
            <div class="bg-white/10 w-20 h-20 block rounded-full relative">
              <InlineSvg :image="accordionItem.content.backgroundImage[0]" :title="accordionItem.content.header" class="absolute z-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full max-w-[60px]" />
            </div>
          </div>
          <h4 class="text-lg font-bold ml-4 mb-0 mr-8" :class="visible ? 'text-black' : 'text-white'">{{ accordionItem.content.header }}</h4>
          <span class="ml-auto relative mr-4">
            <div class="h-[3px] w-[23px] bg-primary absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
            <div class="h-[23px] w-[3px] bg-primary transition-transform duration-300" :class="{ 'rotate-90' : visible}" />
          </span>
        </div>
      </slot>
    </div>
    <transition
      name="accordion"
      @enter="start"
      @after-enter="end"
      @before-leave="start"
      @after-leave="end">
      <div v-show="visible">
        <slot name="accordion-content">
          <t3-html-parser :content="accordionItem.content.bodytext" :class="{ 'text-black' : visible } && accordionItem.content.backgroundImage[0] ? 'ml-24 mr-8 sm:mx-24' : 'ml-4 mt-5 mr-8 sm:mr-24'" />
        </slot>
      </div>
    </transition>
  </div>
</template>
<script>
import InlineSvg from './InlineSvg';
export default {
  name: 'AccordionItem',
  components: {
    InlineSvg
  },
  inject: ["Accordion"],
  props: {
    accordionItem: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data() {
    return {
      index: null
    };
  },
  computed: {
    visible() {
      return this.index === this.Accordion.active;
    }
  },
  created() {
    this.index = this.Accordion.count++;
  },
  methods: {
    open() {
      if (this.visible) {
        this.Accordion.active = null;
      } else {
        this.Accordion.active = this.index;
      }
    },
    start(el) {
      el.style.height = el.scrollHeight + "px";
    },
    end(el) {
      el.style.height = "";
    }
  }
}
</script>