var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "rounded-full border-8 border-transparent h-18 lg:h-24 hover:border-danger/50 transition-all duration-500",
      },
      [
        _c(
          "button",
          {
            staticClass:
              "h-14 lg:h-20 w-14 lg:w-20 rounded-full text-center bg-danger",
            attrs: {
              role: "button",
              "aria-label": "Open Icident Response Layer",
            },
            on: {
              click: function ($event) {
                _vm.show = !_vm.show
              },
            },
          },
          [
            _c(
              "transition",
              {
                attrs: {
                  "enter-active-class": "transition ease-out duration-100",
                  "enter-class": "opacity-0 transform",
                  "enter-to-class": "opacity-100 transform",
                  "leave-active-class": "transition ease-in duration-75",
                  "leave-class": "opacity-100 transform",
                  "leave-to-class": "opacity-0 transform",
                },
              },
              [
                !_vm.show
                  ? _c("ExtinguisherIcon", {
                      staticClass:
                        "absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 inline-block h-8 lg:h-12",
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.show
                  ? _c("div", [
                      _c(
                        "span",
                        {
                          staticClass:
                            "absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 z-50 space-y-2 pointer-events-none",
                        },
                        [
                          _c("span", {
                            staticClass:
                              "block w-6 lg:w-12 h-0.5 bg-white rotate-45 translate-y-1.5",
                          }),
                          _vm._v(" "),
                          _c("span", {
                            staticClass:
                              "block w-6 lg:w-12 h-0.5 bg-white -rotate-45 -translate-y-1",
                          }),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "transition",
          {
            attrs: {
              "enter-active-class": "transition ease-out duration-100",
              "enter-class": "opacity-0 transform scale-95",
              "enter-to-class": "opacity-100 transform scale-100",
              "leave-active-class": "transition ease-in duration-75",
              "leave-class": "opacity-100 transform scale-100",
              "leave-to-class": "opacity-0 transform scale-95",
            },
          },
          [
            _vm.show
              ? _c(
                  "div",
                  { staticClass: "absolute right-1/2 bottom-full mb-5" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "bg-boxed text-white rounded-lg rounded-br-none w-72 md:w-80 overflow-hidden",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "border-l-4 border-l-danger p-6" },
                          [
                            _c("h4", { staticClass: "text-lg mb-1" }, [
                              _vm._v("Incident Response"),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "mb-8" }, [
                              _vm._v(_vm._s(_vm.$t("incidentResponse.text"))),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "mb-2" }, [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "text-danger whitespace-nowrap flex items-center font-medium",
                                  attrs: { href: "mailto:911@suresecure.de" },
                                },
                                [
                                  _c("MailIcon", {
                                    staticClass: "w-5 h-5 mr-2 inline-flex",
                                  }),
                                  _vm._v("911@suresecure.de"),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "mb-8" }, [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "text-danger whitespace-nowrap flex items-center font-medium",
                                  attrs: {
                                    href: "tel:+49021569749110",
                                    title: "+49 (0) 2156 97 49 110",
                                  },
                                },
                                [
                                  _c("PhoneIcon", {
                                    staticClass: "w-5 h-5 mr-2 inline-flex",
                                  }),
                                  _vm._v("+49 (0) 2156 97 49 110"),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass:
                                  "btn-lg btn-danger px-2 py-3 text-center whitespace-nowrap block",
                                attrs: {
                                  href:
                                    "mailto:911@suresecure.de?subject=" +
                                    _vm.$t("incident"),
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("incidentResponse.contact"))
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "tip" }),
                  ]
                )
              : _vm._e(),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }