<template>
  <t3-ce-suresecure-contact-person :contacts="[contactPerson]" />
</template>

<script>
export default {
  name: 'T3CeSuresecurecareerJoboffercontact',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    contactPerson() {
      return this.data.jobOffer.contactPerson
        ? this.data.jobOffer.contactPerson
        : this.data.defaultContactPerson
    },
  },
}
</script>
