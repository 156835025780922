import { state, actions, mutations } from '~typo3/store/typo3.js'

export default ({ store }) => {
  let moduleOptions = {"baseURL":"https:\u002F\u002Fdev.suresecure.de","api":{"baseURL":"https:\u002F\u002Fapi.dev.suresecure.de\u002F","endpoints":{"initialData":"\u002F?type=834"}},"layouts":{},"i18n":{"locales":["en","de","en"],"defaultLocale":"de"},"components":false,"forms":true,"store":{"nuxtServerInit":true},"debug":false}

  store.registerModule('typo3', {
    namespaced: true,
    state,
    actions,
    mutations
  })

  if (moduleOptions?.store?.nuxtServerInit) {
    store.registerModule('typo3/nuxtServerInit', {
      actions: {
        async nuxtServerInit ({ dispatch }, context) {
          await dispatch('typo3/nuxtServerInit', context)
        }
      }
    })
  }
}
