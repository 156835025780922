var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("p", { staticClass: "h6 font-medium" }, [
        _vm._v(_vm._s(_vm.$t("podcast"))),
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("p", { staticClass: "h2" }, [
        _vm._v(_vm._s(_vm.$t("all")) + " "),
        _c("span", { staticClass: "text-primary" }, [
          _vm._v(_vm._s(_vm.$t("episodes"))),
        ]),
      ]),
      _vm._v(" "),
      _vm._l(_vm.items, function (item, key) {
        return _c(
          "div",
          { key: key },
          [_c("podcast-item", { attrs: { content: item } })],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "grid md:flex grid-cols-1 sm:grid-cols-2 gap-5 mb-20" },
      [
        _c(
          "a",
          {
            staticClass:
              "shrink-0 h-12 border-2 border-quinary rounded-[60px] px-4 text-boxed flex items-center font-medium before:content-podcast-apple before:block before:h-7 before:w-7 before:mr-2 transition-all hover:text-white hover:border-primary hover:scale-105 hover:bg-primary",
            attrs: {
              href: "https://podcasts.apple.com/us/podcast/der-security-horst-it-security-to-go/id1567043544",
              target: "_blank",
            },
          },
          [_vm._v("Apple Podcasts")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass:
              "shrink-0 h-12 border-2 border-quinary rounded-[60px] pl-3 pr-4 text-boxed flex items-center font-medium before:content-podcast-spotify before:block before:h-7 before:w-7 before:mr-2 transition-all hover:text-white hover:border-primary hover:scale-105 hover:bg-primary",
            attrs: {
              href: "https://open.spotify.com/show/38ro5pDX7ToVMA9w6RVyNM",
              target: "_blank",
            },
          },
          [_vm._v("Spotify")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass:
              "shrink-0 h-12 border-2 border-quinary rounded-[60px] px-4 text-boxed flex items-center font-medium before:content-podcast-google before:block before:h-7 before:w-7 before:mr-2 transition-all hover:text-white hover:border-primary hover:scale-105 hover:bg-primary",
            attrs: {
              href: "https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xNzc5NDI2LnJzcw",
              target: "_blank",
            },
          },
          [_vm._v("Google Podcasts")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass:
              "shrink-0 h-12 border-2 border-quinary rounded-[60px] px-4 text-boxed flex items-center font-medium before:content-podcast-rss before:block before:h-7 before:w-7 before:mr-2 transition-all hover:text-white hover:border-primary hover:scale-105 hover:bg-primary",
            attrs: {
              href: "https://feeds.buzzsprout.com/1779426.rss",
              target: "_blank",
            },
          },
          [_vm._v("RSS feed")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }