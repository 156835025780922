<template>
  <div>
    <div
      aria-hidden="true"
      aria-label="submenu"
      class="overflow-hidden absolute top-0 z-[9999] w-full text-sm rounded-b-lg border-t-[3px] bg-secondary border-t-primary"
      :style="[positionStyle, isTranslating ? transitionStyle : {}]"
    >
      <transition
        enter-active-class="transition-all duration-100 ease-out"
        enter-class="transform -translate-y-full opacity-0"
        enter-to-class="transform translate-y-0"
        leave-active-class="transition-all duration-100 ease-in"
        leave-class="translate-y-0 ransform"
        leave-to-class="transform -translate-y-full opacity-0"
      >
        <div
          v-if="list.title && !navClaimHide"
          class="flex after:block relative after:absolute after:-bottom-1.5 after:left-7 flex-row items-center p-3 pt-2 mb-4 after:w-3 after:h-3 text-xs text-white after:content-[''] after:rotate-45 bg-primary after:bg-primary"
        >
          <span
            v-show="showHeaderArrow"
            class="flex self-start p-1 mr-2 bg-white/30 rounded-full cursor-pointer"
            @click="handleHeaderClicked"
          >
            <ArrowBackIcon class="w-5 h-5 fill-current" />
          </span>
          <div>
            <transition
              enter-active-class="transition-all duration-100 ease-out"
              enter-class="transform -translate-y-full opacity-0"
              enter-to-class="transform translate-y-0"
              leave-active-class="transition-all duration-100 ease-in"
              leave-class="translate-y-0 ransform"
              leave-to-class="transform -translate-y-full opacity-0"
            >
              <span
                v-if="navClaim != ''"
                class="block text-base font-light text-left -translate-y-0.5"
              >
                {{ navClaim }}
              </span>
            </transition>
            <nuxt-link
              class="block text-xs font-medium uppercase group"
              :to="list.link"
              @click.native="closeNav()"
            >
              {{ list.title }}
              <span
                class="inline-block w-3.5 h-3.5 translate-y-0.5 cursor-pointer"
              >
                <ArrowForwardIcon class="fill-current group-hover:translate-x-4 transition-all ease-out" />
              </span>
            </nuxt-link>
          </div>
        </div>
      </transition>

      <ul>
        <li
          v-for="(item, key) in list.children"
          :key="key"
          class="group px-3 py-5 first:mt-3 hover:pl-4 uppercase font-medium text-left text-white hover:bg-white/20 border-l-2 border-transparent transition-all duration-200 cursor-pointer hover:border-primary"
        >
          <nuxt-link
            :to="item.link"
            class="block"
            :event="item.children ? '' : 'click'"
            active-class="text-primary"
            @click.native="item.children ? handleItemClicked(item) : closeNav()"
          >
            <span
              :class="
                item.children
                  ? 'link-chevron-right flex flex-row justify-between group-hover:after:translate-x-1 after:transition-all after:duration-300'
                  : ''
              "
              >{{ item.title }}</span
            >
          </nuxt-link>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import ArrowBackIcon from '@/assets/icons/arrow-back-outline.svg?inline'
import ArrowForwardIcon from '@/assets/icons/arrow-forward-outline.svg?inline'
export default {
  components: {
    ArrowBackIcon,
    ArrowForwardIcon,
  },
  props: {
    navClaim: {
      type: String,
      default: '',
    },
    navClaimHide: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Object,
      required: true,
    },
    positionStyle: {
      type: Object,
      required: true,
    },
    showHeaderArrow: {
      type: Boolean,
      default: false,
    },
    isTranslating: {
      type: Boolean,
      default: false,
    },
    handleHeaderClicked: {
      type: Function,
      default: () => {},
    },
    handleItemClicked: {
      type: Function,
      default: () => {},
    },
    transitionStyle: {
      type: Object,
      required: true,
    },
  },
  methods: {
    closeNav() {
      this.$nuxt.$emit('close-nav')
    },
  },
}
</script>
