var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "relative rounded-md bg-boxed border-t-[5px] border-t-danger px-10 py-12 md:pt-16",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "absolute -top-7 md:-top-10 h-14 md:h-20 w-14 md:w-20 rounded-full text-center bg-danger",
        },
        [
          _c("ExtinguisherIcon", {
            staticClass:
              "absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 inline-block h-8 md:h-12",
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("t3-ce-header", _vm._b({}, "t3-ce-header", _vm.$props, false)),
      _vm._v(" "),
      _c("t3-html-parser", { attrs: { content: _vm.bodytext } }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "text-danger font-bold text-2xl mt-6 md:mt-8 flex items-center",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "relative h-7 lg:h-10 w-7 lg:w-10 rounded-full text-center bg-danger mr-3 shrink-0",
            },
            [
              _c("PhoneIcon", {
                staticClass:
                  "absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 inline-block h-4 lg:h-6",
              }),
            ],
            1
          ),
          _vm._v("\n    " + _vm._s(_vm.phone) + "\n  "),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }