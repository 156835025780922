<template>

  <div class="relative">
    <div class="flex flex-row justify-between">
      <t3-ce-header v-bind="$props" />
      <div class="ml-auto whitespace-nowrap" :class="[!makeItCarousel ? 'hidden' : 'block']">
        <span class="cursor-pointer w-10 h-10 inline-block rotate-180 hover:bg-grey/20 rounded-full" @click="swipeLeft"><IconArrowForward /></span>
        <span class="cursor-pointer w-10 h-10 inline-block hover:bg-grey/20 rounded-full" @click="swipeRight"><IconArrowForward /></span>
      </div>
    </div>
    <div class="flex flex-row justify-between"></div>
      <ul ref="snap" class="gap-4 py-2 -mx-3 px-3 flex flex-row flex-nowrap snap-mandatory hide-scrollbar" :class="makeItCarousel ? 'xl:-mr-container-margin overflow-x-auto snap-x' : 'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3'">
        <li v-for="(item, key) in teaserboxItems" :key="key" ref="items" class="w-full shrink-0 hover:scale-105 transition-all duration-300" :class="{'flex-shrink-0 snap-start md:w-[calc(((var(--screen-width))/3)-(theme(gap.4)*2)/theme(columns.3)-theme(gap.1))] xl:w-[calc(((var(--current-screen))/3)-(theme(gap.4)*2)/theme(columns.3))]' : makeItCarousel}">
            <TeaserBoxItem :teaserbox-item="teaserboxItems[key]" />
        </li>
      </ul>
  </div>
</template>
<script>

import TeaserBoxItem from '../partials/TeaserBoxItem'
import baseCe from '../mixins/baseCe'
import IconArrowForward from '~/assets/icons/arrow-forward-outline-quinary.svg?inline'
export default {
  name: 'T3CeSuresecureTeaserBox',
  components: {
    IconArrowForward,
    TeaserBoxItem
  },
  extends: baseCe,
  props: {
    teaserboxItems: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    return {
      swipePosition: 0,
    }
  },
  methods: {    
    swipeLeft() {
      if (this.swipePosition > 1) {
        this.swipePosition -= (this.$refs.items[0].clientWidth + 5.6)
        this.$refs.snap.scroll({
          left: this.swipePosition,
          behavior: 'smooth'
        });
      }
    },
    swipeRight() {
      const screen = window.innerWidth
      if (screen < 768) {
        if ((this.swipePosition + (this.$refs.items[0].clientWidth + 5.6)*2) < this.$refs.snap.scrollWidth) {
          this.swipePosition += (this.$refs.items[0].clientWidth + 5.6)
          this.$refs.snap.scroll({
            left: this.swipePosition,
            behavior: 'smooth'
          });
      }
      } else if ((this.swipePosition + (this.$refs.items[0].clientWidth + 5.6)*3) < this.$refs.snap.scrollWidth) {         
        this.swipePosition += (this.$refs.items[0].clientWidth + 5.6)
        this.$refs.snap.scroll({
          left: this.swipePosition,
          behavior: 'smooth'
        });        
      }      
    }
  }
}
</script>
