<template>

  <div class="t3-ce-image" :class="layout">
      <h4 v-if="layout == 'layout-1'" class="relative py-3 text-xs font-medium uppercase cursor-pointer mb-0 sm:mb-5 lg:cursor-text">
          <span class="flex flex-row justify-between items-center after:mt-2 after:mr-2 sm:block">
          {{ skyline }}
          </span>
      </h4>
    <t3-ce-header v-bind="$props" />
    <t3-media-gallery :gallery="gallery" :enlarge-image-on-click="enlargeImageOnClick" />
  </div>
</template>
<script>
import baseCe from '../mixins/baseCe'
import T3MediaGallery from '../T3MediaGallery.vue'
export default {
  name: 'T3CeImage',
  components: {
    T3MediaGallery
  },
  extends: baseCe,
  props: {
    gallery: {
      type: Object,
      required: true
    },
    enlargeImageOnClick: {
      type: Boolean,
      default: false
    },
    layout: {
      type: String,
      default: ''
    },
  }
}
</script>
<style lang="postcss" scoped>
    .layout-1 {
    /deep/
        h4 {
            span {
                @apply text-quinary
            }
        }
    /deep/
        .t3-ce-gallery__container {
            margin-bottom: 4rem;
            display: flex;
            align-items: center;
            gap: 20px 20px;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            .t3-ce-gallery__row {
                &.mt-8 {
                    margin-top: 0;
                 }
            }
        }
    }

</style>
