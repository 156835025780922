var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "relative" }, [
    _c(
      "div",
      { staticClass: "flex flex-row justify-between" },
      [
        _c("t3-ce-header", _vm._b({}, "t3-ce-header", _vm.$props, false)),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "ml-auto whitespace-nowrap",
            class: [!_vm.makeItCarousel ? "hidden" : "block"],
          },
          [
            _c(
              "span",
              {
                staticClass:
                  "cursor-pointer w-10 h-10 inline-block rotate-180 hover:bg-grey/20 rounded-full",
                on: { click: _vm.swipeLeft },
              },
              [_c("IconArrowForward")],
              1
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass:
                  "cursor-pointer w-10 h-10 inline-block hover:bg-grey/20 rounded-full",
                on: { click: _vm.swipeRight },
              },
              [_c("IconArrowForward")],
              1
            ),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "flex flex-row justify-between" }),
    _vm._v(" "),
    _c(
      "ul",
      {
        ref: "snap",
        staticClass:
          "gap-4 py-2 -mx-3 px-3 flex flex-row flex-nowrap snap-mandatory hide-scrollbar",
        class: _vm.makeItCarousel
          ? "xl:-mr-container-margin overflow-x-auto snap-x"
          : "grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3",
      },
      _vm._l(_vm.teaserboxItems, function (item, key) {
        return _c(
          "li",
          {
            key: key,
            ref: "items",
            refInFor: true,
            staticClass:
              "w-full shrink-0 hover:scale-105 transition-all duration-300",
            class: {
              "flex-shrink-0 snap-start md:w-[calc(((var(--screen-width))/3)-(theme(gap.4)*2)/theme(columns.3)-theme(gap.1))] xl:w-[calc(((var(--current-screen))/3)-(theme(gap.4)*2)/theme(columns.3))]":
                _vm.makeItCarousel,
            },
          },
          [
            _c("TeaserBoxItem", {
              attrs: { "teaserbox-item": _vm.teaserboxItems[key] },
            }),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }