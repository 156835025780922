<template>
  <div>
    <input type="hidden" name="Interest___c" value="Newsletter">
    <input type="hidden" name="Confirmed_Opt_In_Source__c" value="Website_Form_Newsletter">

    <input type="hidden" name="Newsletter_Opt_In_Timestamp__c" :value="fullDateTime">

    <div class="form-group">
      <select id="salutation" name="Salutation">
        <option hidden></option>
        <option value="Mr.">{{ $t('form.salutations.mr') }}</option>
        <option value="Mrs.">{{ $t('form.salutations.mrs') }}</option>
      </select>
      <label for="salutation">{{ $t('form.salutation') }}</label>
    </div>
    <div class="form-group">
      <input
        maxlength="40"
        name="FirstName"
        size="20"
        type="text"
        placeholder="first_name"
      />
      <label for="first_name">{{ $t('form.first_name') }}</label>
    </div>
    <div class="form-group">
      <input
        maxlength="80"
        name="LastName"
        size="20"
        type="text"
        placeholder="last_name"
      />
      <label for="last_name">{{ $t('form.last_name') }}</label>
    </div>
    <div class="form-group">
      <input
        maxlength="80"
        name="email"
        size="20"
        type="email"
        placeholder="email"
        required
      />
      <label for="email">{{ $t('form.email') }}</label>
    </div>
    <div class="form-group">
      <input
        maxlength="40"
        name="Company"
        size="20"
        type="text"
        placeholder="company"
      />
      <label for="company">{{ $t('form.company') }}</label>
    </div>
    <div class="form-group !mb-0 checkbox">
      <input
        :id="[`privacy_policy-${unique}`]"
        name="GDPR_Data_Processing_Accepted__c"
        type="checkbox"
        value="true"
        placeholder="privacy_policy"
        required
      />
      <i18n path="form.privacyPolicy" tag="label" :for="[`privacy_policy-${unique}`]">
        <template #link>
          <a class="text-primary font-medium" :href="$t('form.linkPrivacyPolicy')">{{ $t('form.link') }}</a>
        </template>
      </i18n>
    </div>
    <div class="form-group checkbox">
      <input
        :id="[`newsletter-${unique}`]"
        ref="newsletter"
        name="Newsletter_Opt_In__c"
        type="checkbox"
        value=""
        placeholder="newsletter"
        required
        @click="printFullDate()"
      />
      <i18n path="form.newsletter" tag="label" :for="[`newsletter-${unique}`]" @click="printFullDate()">
        {{ $t('form.newsletter') }}
      </i18n>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    unique: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      fullDateTime: '',
    }
  },
  mounted() {
    this.printFullDate()
  },
  methods: {
    printFullDate() {
      if(this.$refs.newsletter.value === '') {
        this.$refs.newsletter.value = true
      } else {
        this.$refs.newsletter.value = ''
      }
      const time = new Date()
      this.fullDateTime = time
    },
  },
}
</script>
