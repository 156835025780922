<template>
  <div class="relative">
    <div class="flex flex-row items-end justify-between">
      <t3-ce-header v-bind="$props" />
      <div class="ml-auto whitespace-nowrap" :class="[!makeItCarousel ? 'hidden' : 'block']">
        <span class="cursor-pointer w-10 h-10 inline-block rotate-180 hover:bg-grey/20 rounded-full" @click="swipeLeft"><IconArrowForward /></span>
        <span class="cursor-pointer w-10 h-10 inline-block hover:bg-grey/20 rounded-full" @click="swipeRight"><IconArrowForward /></span>
      </div>
    </div>
    <ul v-if="menu" ref="snap" class="gap-4 py-2 -mx-3 px-3 flex flex-row flex-nowrap snap-mandatory hide-scrollbar" :class="makeItCarousel ? 'xl:-mr-container-margin overflow-x-auto snap-x' : 'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3'">
      <li v-for="(item, key) in menu" :key="key" ref="items" class="w-full shrink-0 hover:scale-105 transition-all duration-300" :class="{'flex-shrink-0 snap-start md:w-[calc(((var(--screen-width))/3)-(theme(gap.4)*2)/theme(columns.3)-theme(gap.1))] xl:w-[calc(((var(--current-screen))/3)-(theme(gap.4)*2)/theme(columns.3))]' : makeItCarousel}">
        <div class="flex flex-col bg-boxed rounded-lg h-full px-6 py-10 text-white">
          <p class="text-xl font-bold mb-4">{{ item.teaser_title }}</p>
          <p class="text-quinary text-[14px] leading-[25px] sm:text-[16px] mb-8">{{ item.teaser_text }}</p>
          <t3-nav-link
            :to="item.link"
            :target="item.target || false"
            :title="item.teaser_title"
            class="btn btn-primary btn-lg w-full text-center mt-auto"
          >{{ $t('readMore') }}</t3-nav-link>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import baseCe from '../mixins/baseCe'
import IconArrowForward from '~/assets/icons/arrow-forward-outline-quinary.svg?inline'
export default {
  name: 'T3CeSuresecureTeaserSelectedPages',
  components: {
    IconArrowForward
  },
  extends: baseCe,
  props: {
    menu: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      swipePosition: 0
    }
  },
  computed: {
    arrows() {
      if (this.menu.length > 3) {
        return 1
      }
      return 0
    }
  },
  methods: {
    swipeLeft() {
      if (this.swipePosition > 0) {
        this.swipePosition -= (this.$refs.items[0].clientWidth + 16)
        this.$refs.snap.scroll({
          left: this.swipePosition,
          behavior: 'smooth'
        });
      }
    },
    swipeRight() {
      const screen = window.innerWidth
      if (screen < 768) {
        if ((this.swipePosition + (this.$refs.items[0].clientWidth + 5.6)*2) < this.$refs.snap.scrollWidth) {
          this.swipePosition += (this.$refs.items[0].clientWidth + 5.6)
          this.$refs.snap.scroll({
            left: this.swipePosition,
            behavior: 'smooth'
          });
      }
      } else if ((this.swipePosition + (this.$refs.items[0].clientWidth + 5.6)*3) < this.$refs.snap.scrollWidth) {         
        this.swipePosition += (this.$refs.items[0].clientWidth + 5.6)
        this.$refs.snap.scroll({
          left: this.swipePosition,
          behavior: 'smooth'
        });        
      }      
    }
  },
}
</script>