<template>
  <div class="t3-ce-textpic">
    <t3-media-gallery :gallery="gallery" :enlarge-image-on-click="enlargeImageOnClick" :class="{ 'decor !gap-16' : applyDecor == 1, 'overflow' : imageOverflow == 1}">
      <t3-ce-header v-bind="$props" />
      <t3-html-parser :content="bodytext" />
      <div v-if="noticeText" class="absolute top-0 -left-5 -translate-x-full text-[14px] font-medium rounded-t-[5px] rounded-bl-[5px] bg-primary px-3 before:content-[''] before:bg-primary before:w-[9px] before:h-[9px] before:absolute before:bottom-[-9px] before:right-0 after:content-[''] after:bg-secondary after:rounded-tr-full after:w-[10px] after:h-[10px] after:absolute after:bottom-[-10px] after:right-0">{{ noticeText }}</div>
    </t3-media-gallery>
  </div>
</template>
<script>
import baseCe from '../mixins/baseCe'
import T3MediaGallery from '../T3MediaGallery.vue'
export default {
  name: 'T3CeTextpic',
  components: {
    T3MediaGallery
  },
  extends: baseCe,
  props: {
    gallery: {
      type: Object,
      required: true
    },
    bodytext: {
      type: String,
      required: true
    },
    noticeText: {
      type: String,
      default: ''
    },
    enlargeImageOnClick: {
      type: Boolean,
      default: false
    },
    applyDecor: {
      type: Number,
      default: 0
    },
    imageOverflow: {
      type: Number,
      default: 0
    }
  }
}
</script>
<style lang="postcss" scoped>
.decor {
  /deep/
  figure {
    @apply  relative z-10
            before:content-[''] before:w-[97%] md:before:w-[102%] before:h-full before:rounded-md before:outline-2 before:outline before:outline-primary before:absolute before:-top-3 md:before:-top-4 before:right-5 before:-z-10
            after:content-[''] after:w-[92%] md:after:w-[90%] after:h-[108%] md:after:h-[114%] after:rounded-md after:outline-2 after:outline after:outline-primary after:absolute after:-top-6 md:after:-top-10 after:right-8 md:after:right-10 after:-z-10; 
  }
}
</style>