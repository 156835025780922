<template>
  <div class="relative xl:container">
    <div
      class="flex absolute top-1 left-3 z-[1001] flex-row items-center pt-3 text-xs rounded-b-lg transition-all lg:top-[-5px] lg:right-16 xl:right-0 lg:left-auto lg:px-3 lg:pt-6 lg:pb-3 lg:text-base lg:bg-white/10 quick-menu"
    >
      <layout-lang-switcher class="mr-4" />
      <nuxt-link
        :to="contactLink"
        class="hidden py-[3px] px-5 text-xs font-medium text-white uppercase rounded-full lg:inline-block bg-primary hover:scale-105 transition-all"
        >{{ $t('contact') }}</nuxt-link
      >
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState({
      contactLink: (state) => state.typo3.initial.contactLink || [],
    }),
  },
}
</script>
