<template>
  <div class="flex h-full flex-col rounded-b-lg bg-boxed">
    <div
      class="relative rounded-t-lg bg-white px-7 pb-7 transition-all duration-300 ease-in-out after:absolute after:inset-x-0 after:bottom-0 after:hidden after:h-28 after:bg-gradient-to-t after:from-white after:transition-opacity after:duration-300 after:content-[''] lg:after:block"
      :class="[
        priceboxItem.content.backgroundImage[0] ? 'pt-16' : 'pt-7',
        !isOpen
          ? 'lg:max-h-[300px] lg:after:opacity-100'
          : 'lg:max-h-[1000px] lg:after:opacity-0',
      ]"
      @mouseenter="isOpen = !isOpen"
      @mouseleave="isOpen = !isOpen"
    >
      <img
        v-if="priceboxItem.content.backgroundImage[0]"
        v-lazy-load :data-src="priceboxItem.content.backgroundImage[0].publicUrl"
        :alt="priceboxItem.content.header"
        :width="
          priceboxItem.content.backgroundImage[0].properties.dimensions.width
        "
        :height="
          priceboxItem.content.backgroundImage[0].properties.dimensions.height
        "
        class="absolute -top-16 left-2/4 z-10 max-w-[100px] -translate-x-1/2"
      />
      <span
        v-if="priceboxItem.content.backgroundImage[0]"
        class="absolute -top-12 left-2/4 block h-28 w-28 -translate-x-1/2 rounded-full bg-white"
      ></span>
      <h3
        v-if="priceboxItem.content.header"
        class="mb-4 text-2xl font-bold text-secondary"
      >
        {{ priceboxItem.content.header }}
      </h3>
      <h4
        v-if="priceboxItem.content.subheader"
        class="mb-3 text-md font-medium text-primary"
      >
        {{ priceboxItem.content.subheader }}
      </h4>
      <t3-html-parser
        :content="priceboxItem.content.bodytext"
        class="text-md text-secondary"
      />
      <span
        class="absolute -bottom-4 left-6 z-20 rounded-t-lg bg-boxed px-4 pt-2 text-[14px] font-medium uppercase leading-[36px] text-white"
        >{{ $t('ourServices') }}</span
      >
    </div>
    <div class="relative break-words p-7">
      <t3-html-parser
        :content="priceboxItem.content.bodytext2"
        class="relative mb-8 font-medium"
      />
    </div>
    <template v-if="priceboxItem.content.link">
      <a
        v-if="isExternalLink(priceboxItem.content.link)"
        :href="priceboxItem.content.link"
        class="btn btn-primary btn-lg mx-4 mt-auto mb-4 block w-auto text-center"
      >
        <template v-if="priceboxItem.content.buttonText">
          {{ priceboxItem.content.buttonText }}
        </template>
        <template v-else>
          {{ $t('readMore') }}
        </template>
      </a>
      <nuxt-link
        v-else
        :to="priceboxItem.content.link"
        class="btn btn-primary btn-lg mx-4 mt-auto mb-4 block w-auto text-center"
      >
        <template v-if="priceboxItem.content.buttonText">
          {{ priceboxItem.content.buttonText }}
        </template>
        <template v-else>
          {{ $t('readMore') }}
        </template>
      </nuxt-link>
    </template>
  </div>
</template>
<script>
export default {
  name: 'PriceBoxItem',
  props: {
    priceboxItem: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    isExternalLink(url) {
      return /^(https?:\/\/|mailto:|tel:)/.test(url)
    }
  }
}
</script>
