<template>
  <div>
    <div class="relative">
      <ul
        class="hidden text-xs uppercase lg:flex lg:flex-row lg:flex-wrap lg:self-end lg:mt-12 lg:mr-16 xl:mr-0"
      >
        <li v-for="(item, key) in navMain.children" :key="key" class="relative">
          <layout-main-nav-item :item="item" />
        </li>
      </ul>
      <transition
        enter-active-class="transition ease-out duration-100"
        enter-class="opacity-0 transform scale-95"
        enter-to-class="opacity-100 transform scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-class="opacity-100 transform scale-100"
        leave-to-class="opacity-0 transform scale-95"
      >
        <layout-nested-menu
          v-show="isShowMobileMenu"
          id="mobile-menu"
          :source="navMain"
          :claim-from-parent="true"
          class="fixed z-30 w-screen h-screen lg:hidden overflow-y-scroll bg-secondary"
        />
      </transition>
    </div>
    <div class="overflow-hidden absolute top-0 right-0 w-28 h-28 lg:hidden rotat">
      <button
        type="button"
        class="absolute -top-20 -right-36 z-10 mt-2 w-40 h-52 rounded-[15%] transition-all duration-500 rotate-[65deg] skew-x-[0] skew-y-[-35deg] bg-primary"
        :class="isShowMobileMenu ? 'bg-black rotate-[55deg] skew-y-[-37deg] -top-24' : ''"
        :aria-expanded="isShowMobileMenu ? true : false"
        aria-controls="mobile-menu"
        aria-label="menu"
        aria-haspopup="menu"
        role="button"
        @click="toggleMobileMenu()"
      />
      <span class="absolute top-5 right-3 z-50 space-y-2 pointer-events-none">
        <span
          class="block w-9 h-0.5 bg-white transition-all duration-300"
          :class="isShowMobileMenu ? 'rotate-45 translate-y-2.5' : ''"
        ></span>
        <span
          class="block w-9 h-0.5 bg-white transition-all duration-500"
          :class="isShowMobileMenu ? 'translate-x-10 opacity-0' : ''"
        ></span>
        <span
          class="block w-9 h-0.5 bg-white transition-all duration-300"
          :class="isShowMobileMenu ? '-rotate-45 -translate-y-2.5' : ''"
        ></span>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    navMain: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isShowMobileMenu: false,
    }
  },
  created() {
    this.$nuxt.$on('close-nav', () => {
      this.closeMobileMenu()
    })
  },
  beforeDestroyed() {
    this.$nuxt.$off('close-nav')
  },
  methods: {
    toggleMobileMenu () {
      const $body = document.querySelector('body')
      if (this.isShowMobileMenu) {
        this.isShowMobileMenu = false
        $body.style.overflowY = 'auto'
      } else {
        this.isShowMobileMenu = true
        this.$gsap.to(window, { duration: 0.5, scrollTo: { X: 0, y: 0 } })
        $body.style.overflowY = 'hidden'
      }
    },
    closeMobileMenu () {
      const $body = document.querySelector('body')
      this.isShowMobileMenu = false
      $body.style.overflowY = 'auto'
    }
  }
}
</script>
