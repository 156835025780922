var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "t3-ce-html" },
    [_c("t3-html-parser", { attrs: { content: _vm.bodytext } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }