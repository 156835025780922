<template>
  <div class="py-7 text-center md:py-2 xl:container">
    <div class="justify-between md:flex md:flex-row md:flex-wrap md:mx-3">
      <ul
        v-if="navMeta"
        class="flex flex-row flex-wrap justify-evenly items-center mx-3 md:justify-start md:mx-0 md:space-x-7"
      >
        <li
          v-for="(navMetaItem, key) in navMeta"
          :key="key"
          class="text-xs"
        >
          <nuxt-link :to="navMetaItem.link" class="text-quinary hover:text-secondary transition-colors">{{ navMetaItem.title }}</nuxt-link>
        </li>
      </ul>
      <div class="flex-none mt-7 text-xs md:mt-0 text-quinary">
        &copy; 2022 suresecure
      </div>      
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState({
      navMeta: (state) => state.typo3.initial.metaNavigation || [],
    }),
  },
}
</script>
