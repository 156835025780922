var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "when-nested" },
    [
      _c("t3-ce-header", _vm._b({}, "t3-ce-header", _vm.$props, false)),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { action: _vm.formActionHero, method: "POST" },
          on: {
            submit: function ($event) {
              return _vm.getFormValues()
            },
          },
        },
        [
          _c("input", {
            attrs: { type: "hidden", name: "LeadSource", value: "Web" },
          }),
          _vm._v(" "),
          _c("input", {
            attrs: { type: "hidden", name: "CampaignID" },
            domProps: { value: _vm.campaignId },
          }),
          _vm._v(" "),
          _c("input", {
            attrs: { type: "hidden", name: "UTM_Campaign_Name__c" },
            domProps: { value: _vm.campaign },
          }),
          _vm._v(" "),
          _c("input", {
            attrs: { type: "hidden", name: "UTM_Campaign_Source__c" },
            domProps: { value: _vm.source },
          }),
          _vm._v(" "),
          _c("input", {
            attrs: { type: "hidden", name: "UTM_Campaign_Medium__c" },
            domProps: { value: _vm.medium },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "form-group with-position" }, [
            _c("input", {
              ref: "position",
              attrs: {
                maxlength: "40",
                name: "Position",
                size: "20",
                type: "text",
                placeholder: "position",
              },
            }),
            _vm._v(" "),
            _c("label", { attrs: { for: "position" } }, [
              _vm._v(_vm._s(_vm.$t("form.position"))),
            ]),
          ]),
          _vm._v(" "),
          _vm.layout === "20"
            ? _c(
                "div",
                [_c("forms-contact-item", { attrs: { unique: _vm.id } })],
                1
              )
            : _vm.layout === "30"
            ? _c(
                "div",
                [_c("forms-download-item", { attrs: { unique: _vm.id } })],
                1
              )
            : _vm.layout === "40"
            ? _c(
                "div",
                [_c("forms-conference-item", { attrs: { unique: _vm.id } })],
                1
              )
            : _c(
                "div",
                [_c("forms-newsletter-item", { attrs: { unique: _vm.id } })],
                1
              ),
          _vm._v(" "),
          _c("input", {
            staticClass: "btn btn-primary btn-lg w-full",
            attrs: {
              type: "submit",
              name: "submit",
              disabled: _vm.formDisabled,
            },
            domProps: {
              value:
                _vm.buttonText != "" ? _vm.buttonText : _vm.$t("form.submit"),
            },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }