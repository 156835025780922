<template>
  <component :is="getMediaType(file.properties)" :file="file" />
</template>
<script>
import MediaImage from './partials/T3Media/Image'
import MediaYoutube from './partials/T3Media/Youtube'
import MediaVimeo from './partials/T3Media/Vimeo'
import MediaVideo from './partials/T3Media/Video'
import MediaAudio from './partials/T3Media/Audio'
export default {
  name: 'T3MediaFile',
  components: {
    MediaImage,
    MediaYoutube,
    MediaVimeo,
    MediaVideo,
    MediaAudio
  },
  props: {
    file: {
      type: Object,
      required: true
    }
  },
  methods: {
    getMediaType (file) {
      switch (file.type) {
        case 'video': {
          if (file.mimeType.includes('youtube')) {
            return 'media-youtube'
          }
          if (file.mimeType.includes('vimeo')) {
            return 'media-vimeo'
          }
          return 'media-video'
        }
        case 'audio':
          return 'media-audio'
        default:
          return 'media-image'
      }
    }
  }
}
</script>
