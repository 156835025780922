<template>
  <div class="t3-ce-menu">
    <t3-ce-header v-bind="$props" />
    <ul v-if="menu">
      <li v-for="(item, key) in menu" :key="key">
        <t3-nav-link
          :to="item.link"
          :target="item.target || false"
          :title="item.title"
        >
          {{ item.title }}
        </t3-nav-link>
        <slot name="link" :link="item" />
        <t3-ce-menu-pages-list
          v-if="item.children"
          :children="item.children"
        />
      </li>
    </ul>
  </div>
</template>
<script>
import baseCe from '../mixins/baseCe'
import T3CeMenuPagesList from '~typo3/components/T3CeMenuPages/T3CeMenuPagesList.vue'
export default {
  name: 'T3CeMenuPages',
  components: {
    T3CeMenuPagesList,
  },
  extends: baseCe,
  props: {
    menu: {
      type: Array,
      required: true,
    },
  },
}
</script>
