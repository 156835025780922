var render = function render(_c, _vm) {
  return _c(
    "div",
    _vm._b(
      {
        staticClass: "t3-ce-media-image",
        class: [_vm.data.class, _vm.data.staticClass],
      },
      "div",
      _vm.data.attrs,
      false
    ),
    [
      _c(
        _vm.props.file.properties.link ? "t3-nav-link" : "div",
        { tag: "component", attrs: { to: _vm.props.file.properties.link } },
        [
          _c(
            "figure",
            {
              staticClass: "relative",
              class: [
                _vm.props.file.properties.description ? "hover-figure" : "",
              ],
            },
            [
              _c("img", {
                directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
                attrs: {
                  "data-src": _vm.props.file.publicUrl,
                  height: _vm.props.file.properties.dimensions.height,
                  width: _vm.props.file.properties.dimensions.width,
                  alt: _vm.props.file.properties.alternative || false,
                  title: _vm.props.file.properties.title || "",
                },
              }),
              _vm._v(" "),
              _vm.props.file.properties.description
                ? _c(
                    "figcaption",
                    {
                      staticClass:
                        "bg-gray-800/50 absolute -bottom-7 transition-all duration-300 text-white text-xs w-full px-3 py-1 rounded-b-md",
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.props.file.properties.description) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }