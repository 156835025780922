var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "inline-block relative text-left text-gray-800",
        on: {
          mouseenter: function ($event) {
            _vm.isLangMenuOpen = true
          },
          mouseleave: function ($event) {
            _vm.isLangMenuOpen = false
          },
        },
      },
      [
        _c("div", [
          _c(
            "button",
            {
              staticClass:
                "inline-flex text-xs font-medium text-white focus:outline-none lg:text-sm btn-dropdown-toggle",
              class: _vm.isLangMenuOpen ? "open" : "",
              attrs: {
                type: "button",
                "aria-haspopup": "true",
                "aria-expanded": "true",
                "aria-label": "Change Language",
              },
            },
            [
              _c("span", { staticClass: "uppercase" }, [
                _vm._v(" " + _vm._s(_vm.current) + " "),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "transition",
          {
            attrs: {
              "enter-active-class": "transition ease-out duration-100",
              "enter-class": "opacity-0 transform scale-95",
              "enter-to-class": "opacity-100 transform scale-100",
              "leave-active-class": "transition ease-in duration-75",
              "leave-class": "opacity-100 transform scale-100",
              "leave-to-class": "opacity-0 transform scale-95",
            },
          },
          [
            _vm.isLangMenuOpen
              ? _c(
                  "div",
                  {
                    staticClass:
                      "overflow-hidden absolute -inset-x-2 z-20 text-sm text-black rounded-md shadow-lg origin-top-left",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "bg-white rounded-md shadow-xs",
                        attrs: {
                          role: "menu",
                          "aria-orientation": "vertical",
                          "aria-labelledby": "options-menu",
                        },
                      },
                      [
                        _c("div", [
                          _c(
                            "ul",
                            {
                              staticClass: "p-2 text-xs lg:text-sm font-medium",
                            },
                            _vm._l(_vm.available, function (locale, index) {
                              return _c(
                                "li",
                                { key: index },
                                [
                                  _c(
                                    "nuxt-link",
                                    {
                                      staticClass: "uppercase text-black",
                                      attrs: { to: locale.link },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.closeLangMenu.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(locale.twoLetterIsoCode) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                        ]),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }