var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "py-7 text-center md:py-2 xl:container" }, [
    _c(
      "div",
      {
        staticClass: "justify-between md:flex md:flex-row md:flex-wrap md:mx-3",
      },
      [
        _vm.navMeta
          ? _c(
              "ul",
              {
                staticClass:
                  "flex flex-row flex-wrap justify-evenly items-center mx-3 md:justify-start md:mx-0 md:space-x-7",
              },
              _vm._l(_vm.navMeta, function (navMetaItem, key) {
                return _c(
                  "li",
                  { key: key, staticClass: "text-xs" },
                  [
                    _c(
                      "nuxt-link",
                      {
                        staticClass:
                          "text-quinary hover:text-secondary transition-colors",
                        attrs: { to: navMetaItem.link },
                      },
                      [_vm._v(_vm._s(navMetaItem.title))]
                    ),
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex-none mt-7 text-xs md:mt-0 text-quinary" },
          [_vm._v("\n      © 2022 suresecure\n    ")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }