<template>
  <div>
    <div class="content-wrap">
      <t3-renderer :content="content.colPos0" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'T3BlModal',
  props: {
    content: {
      type: [Object, Array],
      required: true,
    },
  },
}
</script>
