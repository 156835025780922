export default ({ app }) => {
  /*
  ** Only run on client-side and only in production mode
  */
  if (process.env.NODE_ENV !== 'production') {
    return
  }
  app.router.afterEach((to) => {
    /*
    ** We tell Google Tag Manager to add a `pageview`
    */
    setTimeout(() => {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({ event: 'nuxtRoute', pageType: 'PageView', pageUrl: to.fullPath, routeName: to.name })
    }, 250)
  })
}
