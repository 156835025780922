var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("t3-ce-suresecure-contact-person", {
    attrs: { contacts: [_vm.contactPerson] },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }