var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "t3-ce-text" },
    [
      _c("t3-ce-header", _vm._b({}, "t3-ce-header", _vm.$props, false)),
      _vm._v(" "),
      _c("t3-html-parser", { attrs: { content: _vm.bodytext } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }