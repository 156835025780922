<template>
  <div>
    <t3-ce-header v-bind="$props" class="mb-20" />
    <div class="grid grid-cols-1 lg:grid-cols-3 gap-7">
      <div v-for="(item, key) in priceboxItems" :key="key" class="col-span-1 mb-20 lg:mb-0">
        <PriceBoxItem :pricebox-item="priceboxItems[key]" />
      </div>
    </div>
  </div>
</template>
<script>
import PriceBoxItem from '../partials/PriceBoxItem'
import baseCe from '../mixins/baseCe'
export default {
  name: 'T3CeSuresecurePriceBox',
  components: {
    PriceBoxItem
  },
  extends: baseCe,
  props: {
    priceboxItems: {
      type: Array,
      required: true,
      default: () => []
    }
  }
}
</script>