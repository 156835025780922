<template>
  <div>
    <div
      class="absolute overflow-hidden inset-x-0 lg:w-full min-h-[500px] bg-dark"
    >
      <client-only>
        <Particles
          :id="`tsParticles-${id}`"
          class="absolute -inset-40 z-0 opacity-10"
          :options="{
            fullScreen: false,
            fpsLimit: 120,
            particles: {
              links: {
                enable: true,
                color: '#ffffff',
                distance: 750,
                width: 3,
              },
              move: {
                direction: 'none',
                enable: true,
                outModes: 'out',
                random: false,
                speed: 1,
                straight: true,
              },
              number: { density: { enable: true, area: 1250 }, value: 20 },
              shape: {
                type: 'circle',
              },
              size: {
                value: 10,
              },
              collisions: {
                enable: true,
                mode: 'bounce',
              },
            },
            detectRetina: true,
          }"
        />
      </client-only>
    </div>

    <div class="lg:grid grid-cols-3 gap-10 mb-10">
      <div class="relative z-10 col-span-2">
        <div class="min-h-[500px] flex items-center">
          <div>
            <h2 class="text-primary">
              {{ data.jobOffer.title }}<br />
              <span class="text-white">{{ data.jobOffer.gender }}</span>
            </h2>
            <ul class="flex flex-col lg:flex-row gap-10">
              <li v-if="data.jobOffer.experience" class="flex gap-7 items-center font-medium text-white">
                <div
                  class="flex items-center relative before:content-[''] before:block before:bg-primary before:rounded-full before:w-12 before:h-12 before:absolute before:-z-10"
                >
                  <IconCalendar class="ml-2 h-7 w-7" />
                </div>
                {{ data.jobOffer.experience }}
              </li>
              <li class="flex gap-7 items-center font-medium text-white">
                <div
                  class="flex items-center relative before:content-[''] before:block before:bg-primary before:rounded-full before:w-12 before:h-12 before:absolute before:-z-10"
                >
                  <IconMapMarker class="ml-[10px] h-7 w-7" />
                </div>
                {{ data.jobOffer.location.name }}
              </li>
              <li class="flex gap-7 items-center font-medium text-white">
                <div
                  class="flex items-center relative before:content-[''] before:block before:bg-primary before:rounded-full before:w-12 before:h-12 before:absolute before:-z-10"
                >
                  <IconClock class="ml-[10px] h-7 w-7" />
                </div>
                {{ data.jobOffer.employment.name }}
              </li>
            </ul>
            <!-- hidden till response from salesforce -->
            <a class="mt-16 btn btn-outline btn-outline--arrow hidden" @click="goDown">
              {{ $t('joboffers.applyNow') }}
            </a>
          </div>
        </div>
        <div
          v-for="(item, key) in data.jobOffer.content"
          :key="key"
          class="my-20"
        >
          <t3-ce-header v-bind="item.content" />
          <t3-html-parser :content="item.content.bodytext" />
        </div>
      </div>
      <div class="col-span-1">
        <div
          class="bg-boxed relative px-7 pt-16 pb-7 rounded-lg mt-32 lg:mt-60"
        >
          <IconMagnify class="absolute -top-14 max-w-[95px] rotate-12 left-2/4 z-10 -translate-x-1/2" />
          <span
            class="w-28 h-28 rounded-full bg-boxed block absolute -top-12 left-2/4 -translate-x-1/2"
          ></span>
          <p class="h4 font-medium mb-10">{{ $t('joboffers.summary') }}</p>
          <div class="grid grid-cols-2 border-b-[1px] border-white/10 mb-8">
            <p class="font-medium">{{ $t('joboffers.begin') }}</p>
            <p>{{ data.jobOffer.start }}</p>
          </div>
          <div class="grid grid-cols-2 border-b-[1px] border-white/10 mb-8">
            <p class="font-medium">{{ $t('joboffers.language') }}</p>
            <p>{{ $t('joboffers.languages.' + language) }}</p>
          </div>
          <div class="grid grid-cols-2 border-b-[1px] border-white/10 mb-8">
            <p class="font-medium">{{ $t('joboffers.location') }}</p>
            <p>{{ data.jobOffer.location.name }}</p>
          </div>
          <div v-if="data.jobOffer.experience" class="grid grid-cols-2 border-b-[1px] border-white/10 mb-8">
            <p class="font-medium">{{ $t('joboffers.careerLevel') }}</p>
            <p>{{ data.jobOffer.experience }}</p>
          </div>
          <div class="grid grid-cols-2 mb-5">
            <p class="font-medium">{{ $t('joboffers.employment') }}</p>
            <p>{{ data.jobOffer.employment.name }}</p>
          </div>
        </div>
        <!-- hidden till response from salesforce -->
        <div id="contact" class="bg-boxed relative p-7 rounded-lg mt-5 mb-10 hidden">
          <p class="h4 font-medium mb-10">{{ $t('joboffers.interested') }}?</p>
          <form
            ref="form"
            action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
            method="POST"
          >
            <input
              type="hidden"
              name="captcha_settings"
              value='{"keyname":"suresecure_recap","fallback":"true","orgId":"00D5J000000DGbu","ts":""}'
            />
            <input type="hidden" name="oid" value="00D5J000000DGbu" />
            <input type="hidden" name="retURL" :value="$t('form.retUrl')" />
            <div class="form-group">
              <select id="salutation" name="salutation" required>
                <option hidden></option>
                <option value="Mr.">{{ $t('form.salutations.mr') }}</option>
                <option value="Mrs.">{{ $t('form.salutations.mrs') }}</option>
              </select>
              <label for="salutation">{{ $t('form.salutation') }}</label>
            </div>
            <div class="form-group">
              <input
                id="first_name"
                maxlength="40"
                name="first_name"
                size="20"
                type="text"
                placeholder="first_name"
              />
              <label for="first_name">{{ $t('form.first_name') }}</label>
            </div>
            <div class="form-group">
              <input
                id="last_name"
                maxlength="80"
                name="last_name"
                size="20"
                type="text"
                placeholder="last_name"
              />
              <label for="last_name">{{ $t('form.last_name') }}</label>
            </div>
            <div class="form-group">
              <input
                id="email"
                maxlength="80"
                name="email"
                size="20"
                type="text"
                placeholder="email"
              />
              <label for="email">{{ $t('form.email') }}</label>
            </div>
            <div class="form-group">
              <input
                id="phone"
                maxlength="40"
                name="phone"
                size="20"
                type="text"
                placeholder="phone"
              />
              <label for="phone">{{ $t('form.phone') }}</label>
            </div>
            <div class="form-group">
              <input
                id="mobile"
                maxlength="40"
                name="mobile"
                size="20"
                type="text"
                placeholder="mobile"
              />
              <label for="mobile">{{ $t('form.mobile') }}</label>
            </div>
            <div class="form-group">
              <textarea name="description" placeholder="description"></textarea>
              <label for="description">{{ $t('form.description') }}</label>
            </div>
            <div class="form-group">
              <select id="00N5J000002HB9Z" name="00N5J000002HB9Z" required>
                <option hidden></option>
                <option value="">{{ $t('form.withOut') }}</option>
                <option value="Yes">{{ $t('form.yes') }}</option>
              </select>
              <label for="00N5J000002HB9Z">{{
                $t('form.dataProcessing')
              }}</label>
            </div>
            <div class="form-group">
              <select id="00N5J000002HB9e" name="00N5J000002HB9e" required>
                <option hidden></option>
                <option value="">{{ $t('form.withOut') }}</option>
                <option value="Yes">{{ $t('form.yes') }}</option>
              </select>
              <label for="00N5J000002HB9e">{{
                $t('form.dataProtection')
              }}</label>
            </div>
            <input
              id="00N5J000002HCcw"
              name="00N5J000002HCcw"
              input
              type="hidden"
              value="1"
            />
            <input
              id="recordType"
              name="recordType"
              input
              type="hidden"
              value="0125J000000kFrD"
            />
            <input
              id="lead_source"
              name="lead_source"
              input
              type="hidden"
              value="Web"
            />
            <input
              id="Campaign_ID"
              name="Campaign_ID"
              input
              type="hidden"
              :value="data.jobOffer.salesforceCampaignId"
            />
            <input
              id="00N5J000002HCd1"
              name="00N5J000002HCd1"
              input
              type="hidden"
              :value="data.jobOffer.title"
            />
            <input
              type="submit"
              name="submit"
              class="btn btn-primary btn-lg w-full"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import baseCe from '../mixins/baseCe'
import IconCalendar from '~/assets/icons/calendar.svg?inline'
import IconMapMarker from '~/assets/icons/map-marker.svg?inline'
import IconClock from '~/assets/icons/clock.svg?inline'
import IconMagnify from '~/assets/icons/magnify.svg?inline'
export default {
  name: 'T3CeSuresecurecareerJobofferdetails',
  components: {
    IconCalendar,
    IconMapMarker,
    IconClock,
    IconMagnify,
  },
  extends: baseCe,
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    contactPerson() {
      return this.data.jobOffer.contactPerson
        ? this.data.jobOffer.contactPerson
        : this.data.defaultContactPerson
    },
    language() {
      if (this.data.jobOffer.languages === 1) return 'german'
      if (this.data.jobOffer.languages === 2) return 'english'
      return 'both'
    },
  },
  methods: {
    goDown() {
      this.$gsap.to(window, 1, {
        scrollTo: {
          y: '#contact',
          offsetY: () => {
            return innerWidth < 1280 ? (innerWidth < 1024 ? 0 : 190) : 150
          },
        },
      })
    },
  },
}
</script>
<style lang="postcss" scoped>
.form-group {
  @apply relative mb-5 text-[15px] text-[#92A6A6] leading-8;
  label {
    @apply absolute top-[5px] left-3 z-10 text-[15px] text-[#92A6A6] transition-all;
  }
  input,
  textarea {
    @apply placeholder-transparent rounded-[4px] bg-[#3F5151] border-0 w-full transition-all border-opacity-0 border-b-4 border-white;
    &:focus,
    &:not(:placeholder-shown) {
      @apply outline-none shadow-none ring-0 border-opacity-100;
      ~ label {
        @apply top-[-26px] text-[11px] uppercase;
      }
    }
  }
  select {
    @apply placeholder-transparent rounded-[4px] bg-[#3F5151] border-0 w-full transition-all border-opacity-0 border-b-4 border-white text-[15px] text-[#92A6A6];
    ~ label {
      @apply pointer-events-none;
    }
    &:focus,
    &:valid {
      @apply outline-none shadow-none ring-0 border-opacity-100;
      ~ label {
        @apply top-[-26px] text-[11px] uppercase;
      }
    }
    option {
      @apply text-[15px] text-[#92A6A6];
    }
  }
}
</style>
