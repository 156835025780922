import { render, staticRenderFns } from "./PriceBoxItem.vue?vue&type=template&id=ffaa689e"
import script from "./PriceBoxItem.vue?vue&type=script&lang=js"
export * from "./PriceBoxItem.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/vhosts/suresecure.de/dev.suresecure.de/releases/20231128/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ffaa689e')) {
      api.createRecord('ffaa689e', component.options)
    } else {
      api.reload('ffaa689e', component.options)
    }
    module.hot.accept("./PriceBoxItem.vue?vue&type=template&id=ffaa689e", function () {
      api.rerender('ffaa689e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/content/partials/PriceBoxItem.vue"
export default component.exports