var render = function render(_c, _vm) {
  return _c(
    "audio",
    _vm._b(
      {
        staticClass: "t3-ce-media-audio",
        class: [_vm.data.class, _vm.data.staticClass],
        attrs: { controls: "" },
      },
      "audio",
      _vm.data.attrs,
      false
    ),
    [
      _c("source", {
        directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
        attrs: { "data-src": _vm.props.file.publicUrl, type: "audio/mp3" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }