var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass:
            "content-wrap ce-suresecurecareer_eventdetails layout-default frame frame-default frame-space-before- frame-space-after- frame-space-inner-before- frame-space-inner-after- frame-background-light",
        },
        [
          _c("div", { staticClass: "frame-container" }, [
            _c("div", { staticClass: "frame-inner" }, [
              _c(
                "div",
                {
                  staticClass:
                    "absolute overflow-hidden inset-x-0 lg:w-full min-h-[360px] lg:min-h-[500px] bg-dark",
                },
                [
                  _c(
                    "client-only",
                    [
                      _c("Particles", {
                        staticClass: "absolute -inset-40 z-0 opacity-10",
                        attrs: {
                          id: `tsParticles-${_vm.id}`,
                          options: {
                            fullScreen: false,
                            fpsLimit: 120,
                            particles: {
                              links: {
                                enable: true,
                                color: "#ffffff",
                                distance: 750,
                                width: 3,
                              },
                              move: {
                                direction: "none",
                                enable: true,
                                outModes: "out",
                                random: false,
                                speed: 1,
                                straight: true,
                              },
                              number: {
                                density: { enable: true, area: 1250 },
                                value: 20,
                              },
                              shape: {
                                type: "circle",
                              },
                              size: {
                                value: 10,
                              },
                              collisions: {
                                enable: true,
                                mode: "bounce",
                              },
                            },
                            detectRetina: true,
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "lg:grid grid-cols-3 gap-10 mb-10" }, [
                _c(
                  "div",
                  { staticClass: "relative z-10 col-span-2" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "min-h-[360px] lg:min-h-[500px] flex items-center px-7 lg:px-0 flex-wrap lg:flex-nowrap",
                      },
                      [
                        _c("div", [
                          _c(
                            "h2",
                            {
                              staticClass:
                                "text-primary text-4xl md:text-5xl lg:text-5xl",
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.staticPage.meta.title) +
                                  "\n                            "
                              ),
                              _vm.staticPage.meta.subtitle
                                ? _c("span", { staticClass: "text-white" }, [
                                    _c("br"),
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(_vm.staticPage.meta.subtitle) +
                                        "\n                            "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.staticPage.formAction
                          ? _c("div", { staticClass: "w-full" }, [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "btn btn-outline px-7 btn-lg w-full btn-outline--arrow lg:hidden",
                                  on: { click: _vm.goDown },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.$t("event.registerNow")) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-span-1" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "bg-boxed rounded-lg relative px-7 pt-16 pb-7 mt-24 lg:mt-60 lg:hidden",
                        },
                        [
                          _c("p", { staticClass: "h4 font-medium mb-10" }, [
                            _vm._v(_vm._s(_vm.$t("event.summary"))),
                          ]),
                          _vm._v(" "),
                          _vm.staticPage.eventCity
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "grid grid-cols-2 border-b-[1px] border-white/10 mb-8",
                                },
                                [
                                  _c("p", { staticClass: "font-medium" }, [
                                    _vm._v(_vm._s(_vm.$t("event.city"))),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", [
                                    _vm._v(_vm._s(_vm.staticPage.eventCity)),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.staticPage.eventDate || _vm.staticPage.starttime
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "grid grid-cols-2 border-b-[1px] border-white/10 mb-8",
                                },
                                [
                                  _c("p", { staticClass: "font-medium" }, [
                                    _vm._v(_vm._s(_vm.$t("event.date"))),
                                  ]),
                                  _vm._v(" "),
                                  _vm.staticPage.eventDate
                                    ? _c("p", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.staticPage.eventDate) +
                                            " "
                                        ),
                                      ])
                                    : _c("p", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$moment
                                                .unix(
                                                  _vm.staticPage.starttime
                                                    ? _vm.staticPage.starttime
                                                    : _vm.staticPage.starttime
                                                )
                                                .format("D/M/YYYY")
                                            )
                                        ),
                                      ]),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.staticPage.categories.length > 0
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "grid grid-cols-2 border-b-[1px] border-white/10 mb-8",
                                },
                                [
                                  _c("p", { staticClass: "font-medium" }, [
                                    _vm._v(_vm._s(_vm.$t("event.format"))),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(_vm.staticPage.categories[0].title)
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.staticPage.eventTheme
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "grid grid-cols-2 border-b-[1px] border-white/10 mb-8",
                                },
                                [
                                  _c("p", { staticClass: "font-medium" }, [
                                    _vm._v(_vm._s(_vm.$t("event.theme"))),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", [
                                    _vm._v(_vm._s(_vm.staticPage.eventTheme)),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.staticPage.exhibitionHallStand
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "grid grid-cols-2 border-b-[1px] border-white/10 mb-8",
                                },
                                [
                                  _c("p", { staticClass: "font-medium" }, [
                                    _vm._v(_vm._s(_vm.$t("event.hall_stand"))),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(_vm.staticPage.exhibitionHallStand)
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.staticPage.registerLink
                            ? _c(
                                "div",
                                { staticClass: "border-white/10 mb-8" },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "btn btn-primary btn-lg w-full",
                                      attrs: {
                                        href: _vm.staticPage.registerLink,
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(_vm.$t("event.registerNow")) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("t3-renderer", {
                      attrs: { content: _vm.content.colPos0 },
                    }),
                    _vm._v(" "),
                    _vm.staticPage.formAction
                      ? _c("div", { staticClass: "border-white/10 mb-8" }, [
                          _c(
                            "div",
                            {
                              staticClass: "col-span-1",
                              attrs: { id: "register" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "bg-boxed rounded-lg relative px-7 pt-16 pb-7 lg:mt-60 lg:hidden",
                                },
                                [
                                  _c(
                                    "p",
                                    { staticClass: "h4 font-medium mb-10" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("event.registerNow"))
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "t3-ce-header",
                                    _vm._b(
                                      {},
                                      "t3-ce-header",
                                      _vm.$props,
                                      false
                                    )
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "form",
                                    {
                                      attrs: {
                                        action: _vm.formActionHero,
                                        method: "POST",
                                      },
                                      on: {
                                        submit: function ($event) {
                                          return _vm.getFormValues()
                                        },
                                      },
                                    },
                                    [
                                      _c("input", {
                                        attrs: {
                                          type: "hidden",
                                          name: "LeadSource",
                                          value: "Web",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("input", {
                                        attrs: {
                                          type: "hidden",
                                          name: "UTM_Campaign_Name__c",
                                        },
                                        domProps: { value: _vm.campaign },
                                      }),
                                      _vm._v(" "),
                                      _c("input", {
                                        attrs: {
                                          type: "hidden",
                                          name: "UTM_Campaign_Source__c",
                                        },
                                        domProps: { value: _vm.source },
                                      }),
                                      _vm._v(" "),
                                      _c("input", {
                                        attrs: {
                                          type: "hidden",
                                          name: "UTM_Campaign_Medium__c",
                                        },
                                        domProps: { value: _vm.medium },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-group with-position",
                                        },
                                        [
                                          _c("input", {
                                            ref: "position",
                                            attrs: {
                                              maxlength: "40",
                                              name: "Position",
                                              size: "20",
                                              type: "text",
                                              placeholder: "position",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "label",
                                            { attrs: { for: "position" } },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("form.position"))
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        [
                                          _c("forms-event-item", {
                                            attrs: { unique: _vm.id },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        staticClass:
                                          "btn btn-primary btn-lg w-full",
                                        attrs: {
                                          type: "submit",
                                          name: "submit",
                                          disabled: _vm.formDisabled,
                                        },
                                        domProps: {
                                          value: _vm.$t("form.submit"),
                                        },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-span-1" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "bg-boxed rounded-lg relative px-7 pt-16 pb-7 lg:mt-60 hidden lg:block",
                    },
                    [
                      _c("p", { staticClass: "h4 font-medium mb-10" }, [
                        _vm._v(_vm._s(_vm.$t("event.summary"))),
                      ]),
                      _vm._v(" "),
                      _vm.staticPage.eventCity
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "grid grid-cols-2 border-b-[1px] border-white/10 mb-8",
                            },
                            [
                              _c("p", { staticClass: "font-medium" }, [
                                _vm._v(_vm._s(_vm.$t("event.city"))),
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(_vm._s(_vm.staticPage.eventCity)),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.staticPage.eventDate || _vm.staticPage.starttime
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "grid grid-cols-2 border-b-[1px] border-white/10 mb-8",
                            },
                            [
                              _c("p", { staticClass: "font-medium" }, [
                                _vm._v(_vm._s(_vm.$t("event.date"))),
                              ]),
                              _vm._v(" "),
                              _vm.staticPage.eventDate
                                ? _c("p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.staticPage.eventDate) +
                                        " "
                                    ),
                                  ])
                                : _c("p", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$moment
                                            .unix(
                                              _vm.staticPage.starttime
                                                ? _vm.staticPage.starttime
                                                : _vm.staticPage.starttime
                                            )
                                            .format("D/M/YYYY")
                                        )
                                    ),
                                  ]),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.staticPage.categories.length > 0
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "grid grid-cols-2 border-b-[1px] border-white/10 mb-8",
                            },
                            [
                              _c("p", { staticClass: "font-medium" }, [
                                _vm._v(_vm._s(_vm.$t("event.format"))),
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  _vm._s(_vm.staticPage.categories[0].title)
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.staticPage.eventTheme
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "grid grid-cols-2 border-b-[1px] border-white/10 mb-8",
                            },
                            [
                              _c("p", { staticClass: "font-medium" }, [
                                _vm._v(_vm._s(_vm.$t("event.theme"))),
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(_vm._s(_vm.staticPage.eventTheme)),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.staticPage.exhibitionHallStand
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "grid grid-cols-2 border-b-[1px] border-white/10 mb-8",
                            },
                            [
                              _c("p", { staticClass: "font-medium" }, [
                                _vm._v(_vm._s(_vm.$t("event.hall_stand"))),
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  _vm._s(_vm.staticPage.exhibitionHallStand)
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.staticPage.registerLink
                        ? _c("div", { staticClass: "border-white/10 mb-8" }, [
                            _c(
                              "a",
                              {
                                staticClass: "btn btn-primary btn-lg w-full",
                                attrs: {
                                  href: _vm.staticPage.registerLink,
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.$t("event.registerNow")) +
                                    "\n                    "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.staticPage.formAction
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "border-white/10 mb-8 hidden md:block",
                            },
                            [
                              _c("p", { staticClass: "h4 font-medium mb-10" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("event.registerNow"))
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "t3-ce-header",
                                _vm._b({}, "t3-ce-header", _vm.$props, false)
                              ),
                              _vm._v(" "),
                              _c(
                                "form",
                                {
                                  attrs: {
                                    action: _vm.formActionHero,
                                    method: "POST",
                                  },
                                  on: {
                                    submit: function ($event) {
                                      return _vm.getFormValues()
                                    },
                                  },
                                },
                                [
                                  _c("input", {
                                    attrs: {
                                      type: "hidden",
                                      name: "LeadSource",
                                      value: "Web",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("input", {
                                    attrs: {
                                      type: "hidden",
                                      name: "UTM_Campaign_Name__c",
                                    },
                                    domProps: { value: _vm.campaign },
                                  }),
                                  _vm._v(" "),
                                  _c("input", {
                                    attrs: {
                                      type: "hidden",
                                      name: "UTM_Campaign_Source__c",
                                    },
                                    domProps: { value: _vm.source },
                                  }),
                                  _vm._v(" "),
                                  _c("input", {
                                    attrs: {
                                      type: "hidden",
                                      name: "UTM_Campaign_Medium__c",
                                    },
                                    domProps: { value: _vm.medium },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "form-group with-position" },
                                    [
                                      _c("input", {
                                        ref: "position",
                                        attrs: {
                                          maxlength: "40",
                                          name: "Position",
                                          size: "20",
                                          type: "text",
                                          placeholder: "position",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        { attrs: { for: "position" } },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("form.position"))
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    [
                                      _c("forms-event-item", {
                                        attrs: { unique: _vm.id },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("input", {
                                    staticClass:
                                      "btn btn-primary btn-lg w-full",
                                    attrs: {
                                      type: "submit",
                                      name: "submit",
                                      disabled: _vm.formDisabled,
                                    },
                                    domProps: { value: _vm.$t("form.submit") },
                                  }),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("layout-footer-content", {
        staticClass: "mb-5",
        attrs: {
          col90: _vm.content.colPos90,
          col91: _vm.content.colPos91,
          col92: _vm.content.colPos92,
          col93: _vm.content.colPos93,
          col94: _vm.content.colPos94,
          col95: _vm.content.colPos95,
          col101: _vm.content.colPos101,
          col102: _vm.content.colPos102,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }