var render = function render(_c, _vm) {
  return _c(
    "iframe",
    _vm._b(
      {
        directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
        staticClass: "t3-ce-media-video t3-ce-media-vimeo",
        class: [_vm.data.class, _vm.data.staticClass],
        attrs: {
          "data-src": _vm.props.file.publicUrl,
          width: _vm.props.file.properties.dimensions.width || 640,
          height: _vm.props.file.properties.dimensions.height || 360,
          title: _vm.props.file.properties.title || false,
          frameborder: "0",
          webkitallowfullscreen: "",
          mozallowfullscreen: "",
          allowfullscreen: "",
        },
      },
      "iframe",
      _vm.data.attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }