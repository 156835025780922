var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "suresecure-carousel relative" },
    [
      _vm.backgroundImage[0]
        ? _c("img", {
            directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
            staticClass:
              "img-fluid lg:absolute top-1/2 right-0 lg:translate-x-1/2 lg:-translate-y-1/2 lg:max-w-screen-xl xl:max-w-screen-2xl",
            attrs: {
              "data-src": _vm.backgroundImage[0].publicUrl,
              width: _vm.backgroundImage[0].properties.dimensions.width,
              height: _vm.backgroundImage[0].properties.dimensions.height,
              alt: _vm.backgroundImage[0].properties.alternative,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.carouselItems.length > 1
        ? _c(
            "vue-glide",
            _vm._b({}, "vue-glide", _vm.glideOptions, false),
            _vm._l(_vm.carouselItems, function (carouselItem, key) {
              return _c(
                "vue-glide-slide",
                { key: key },
                [
                  _c("CarouselItem", {
                    attrs: { "carousel-item": carouselItem },
                  }),
                ],
                1
              )
            }),
            1
          )
        : _c("div", { staticClass: "glide" }, [
            _c(
              "div",
              { staticClass: "glide__slide" },
              [
                _c("CarouselItem", {
                  attrs: { "carousel-item": _vm.carouselItems[0] },
                }),
              ],
              1
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }