<template>
  <div class="bg-boxed rounded-lg h-full px-4 sm:px-6 py-6 sm:py-10 flex hover:pr-5 duration-300">
    <div v-if="teaserboxItem.content.backgroundImage[0]" class="relative">
        <div class="bg-white/10 w-11 h-11 lg:w-20 lg:h-20 rounded-full relative">
            <img
                v-if="teaserboxItem.content.backgroundImage[0]"
                v-lazy-load :data-src="teaserboxItem.content.backgroundImage[0].publicUrl"
                :alt="teaserboxItem.content.header"
                class="absolute z-10 left-1/2 -translate-x-1/2  top-1/2 -translate-y-1/2 w-full max-w-[30px] lg:max-w-[60px] lg:max-h-[60px]"
            >

        </div>
    </div>
    <div class="ml-6 pt-2 sm:pt-3 w-full">
        <p class="text-white sm:text-lg font-bold mb-2 sm:mb-4 mr-5" style="word-break:break-all">{{ teaserboxItem.content.header }}</p>
        <t3-html-parser :content="teaserboxItem.content.bodytext" class="inline-quinary"/>
    </div>
  </div>

</template>
<script>
export default {
  name:'TeaserBoxItem',
  props: {
    teaserboxItem: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data() {
    return {
      isOpen: false
    }
  }
}
</script>
<style lang="postcss" scoped>
/deep/
.inline-quinary p {
    @apply text-[14px] leading-[25px] sm:text-[16px] text-quinary;
}
</style>
