<template>
  <div
    class="mx-3 text-white/50 border-b border-b-white/50 sm:border-b-0 md:mx-0 footer-block-content-item"
    :class="isBlockOpen ? 'open' : ''"
  >
    
    <h4
      v-if="contentElement.content && contentElement.content.header"
      class="relative py-3 text-xs font-medium uppercase cursor-pointer mb-0 sm:mb-5 lg:cursor-text btn-dropdown-toggle"
      :class="isBlockOpen ? 'open' : ''"
      @click="isBlockOpen = !isBlockOpen"
    >
      <nuxt-link
        v-if="contentElement.content.headerLink.href"
        :to="contentElement.content.headerLink.href"      
      >
        <span
          class="flex flex-row justify-between items-center after:mt-2 after:mr-2 sm:block"
          >{{ contentElement.content.header }}</span
        >
      </nuxt-link>
    </h4>
    <div
      class="overflow-hidden sm:overflow-visible text-sm font-light transition-all duration-200 sm:pb-5 sm:max-h-fit"
      :class="isBlockOpen ? 'max-h-32 mb-3' : 'max-h-0'"
    >
      <div class="pb-3">
        <t3-renderer :content="contentForRenderer" />
      </div>
    </div>    
  </div>
</template>

<script>
export default {
  props: {
    contentElement: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isBlockOpen: false,
    }
  },
  computed: {
    contentForRenderer() {
      const list = [this.contentElement]
      return list
    },
  },
  mounted() {
    if (window.pdInit === undefined) {
      window.pdInit = 0;
      window.piAId = '860003';
      window.piCId = '212183';
      const piHostname = 'go.suresecure.de';

      const s = document.createElement('script');
      s.type = 'text/javascript';
      const protocol = document.location.protocol === 'https:' ? 'https://' : 'http://';
      s.src = protocol + piHostname + '/pd.js';
      document.body.appendChild(s);
    }
  }
}
</script>

<style lang="postcss" scoped>
.footer-block-content-item {
  /deep/ {
    .frame-container {
      @apply mr-auto ml-auto;
    }
    a {
      @apply transition-all text-quinary hover:text-quinary/75;
    }
    p {
      @apply text-quinary;
    }
    p + p,
    .t3-ce-menu li + li {
      @apply mt-3;
    }
  }
}
.btn-dropdown-toggle {
  span {
    &:after {
      @apply opacity-75;
      @screen sm {
        @apply hidden;
      }
    }
  }
  &.open span {
    @apply text-white opacity-100;
    &:after {
      @apply opacity-100;
    }
  }
}
</style>