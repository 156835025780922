var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "relative" }, [
    _c(
      "div",
      {
        ref: "overAll",
        staticClass:
          "flex gap-10 flex-col-reverse xl:flex-row items-center pb-12",
      },
      [
        _c(
          "div",
          { staticClass: "xl:w-1/2" },
          [
            _c("t3-ce-header", _vm._b({}, "t3-ce-header", _vm.$props, false)),
            _vm._v(" "),
            _c("t3-html-parser", { attrs: { content: _vm.bodytext } }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "xl:w-1/2 relative",
            class: _vm.applyDecor === 1 ? "decor" : "self-end",
            style: `min-height: ${_vm.minHeight}px`,
          },
          [
            _c("figure", [
              _vm.backgroundImage[0]
                ? _c("img", {
                    directives: [{ name: "lazy-load", rawName: "v-lazy-load" }],
                    ref: "image",
                    staticClass: "mx-auto xl:mx-0",
                    attrs: {
                      "data-src": _vm.backgroundImage[0].publicUrl,
                      width: _vm.backgroundImage[0].properties.dimensions.width,
                      height:
                        _vm.backgroundImage[0].properties.dimensions.height,
                    },
                    on: { load: _vm.resizeHandler },
                  })
                : _vm._e(),
            ]),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    _vm.enableAnchorNav
      ? _c(
          "div",
          {
            staticClass: "z-50",
            class: [
              !_vm.stickyState
                ? "absolute bottom-0 translate-y-1/2 w-full"
                : "fixed top-3 left-3 right-3",
            ],
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "xl:container bg-boxed rounded-md flex items-center",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "bg-primary rounded-l-md p-3 md:pr-6 text-white text-base relative shrink-0",
                    class: _vm.isShowStickyMenu ? "downangle" : "triangle",
                  },
                  [
                    _c("div", { staticClass: "flex items-center" }, [
                      _c("div", { staticClass: "relative md:mr-4 h-10" }, [
                        _c("button", {
                          staticClass:
                            "w-10 h-10 rounded-full transition-all duration-300 bg-white/25",
                          attrs: {
                            type: "button",
                            "aria-expanded": _vm.isShowStickyMenu
                              ? true
                              : false,
                            "aria-controls": "sticky-menu",
                            "aria-label": "menu",
                            "aria-haspopup": "menu",
                            role: "button",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.toggleStickyMenu()
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass:
                              "absolute top-3 left-2 z-50 space-y-[5px] pointer-events-none",
                          },
                          [
                            _c("span", {
                              staticClass:
                                "block w-6 h-0.5 bg-white transition-all duration-300",
                              class: _vm.isShowStickyMenu
                                ? "rotate-45 translate-y-[7px]"
                                : "",
                            }),
                            _vm._v(" "),
                            _c("span", {
                              staticClass:
                                "block w-6 h-0.5 bg-white transition-all duration-500",
                              class: _vm.isShowStickyMenu
                                ? "-translate-x-6 opacity-0"
                                : "",
                            }),
                            _vm._v(" "),
                            _c("span", {
                              staticClass:
                                "block w-6 h-0.5 bg-white transition-all duration-300",
                              class: _vm.isShowStickyMenu
                                ? "-rotate-45 -translate-y-[7px]"
                                : "",
                            }),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "hidden md:block" }, [
                        _vm._v(_vm._s(_vm.$t("ourSolutions"))),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _vm.menu.length > 0
                  ? _c(
                      "div",
                      {
                        ref: "snap",
                        staticClass:
                          "overflow-x-auto snap-x snap-mandatory hide-scrollbar flex",
                      },
                      [
                        _vm._l(_vm.menu[0].content, function (element, key) {
                          return [
                            element.data.uid !== _vm.id &&
                            element.data.colPos !== 1001 &&
                            element.data.colPos !== 90
                              ? _c(
                                  "div",
                                  {
                                    key: key,
                                    ref: "items",
                                    refInFor: true,
                                    staticClass: "px-10 snap-start shrink-0",
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "underscore",
                                        attrs: {
                                          href: `#c${element.data.uid}`,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(element.data.skyline) +
                                            "\n            "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.overflowing
                  ? _c(
                      "div",
                      { staticClass: "ml-auto shrink-0 flex" },
                      [
                        _c("IconArrowForward", {
                          staticClass:
                            "mr-3 w-8 h-8 text-boxed rotate-180 hover:text-white transition-all hover:scale-105 bg-grey rounded-full",
                          on: { click: _vm.swipeLeft },
                        }),
                        _vm._v(" "),
                        _c("IconArrowForward", {
                          staticClass:
                            "mr-4 w-8 h-8 text-boxed hover:text-white transition-all hover:scale-105 bg-grey rounded-full",
                          on: { click: _vm.swipeRight },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _c(
              "ul",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isShowStickyMenu,
                    expression: "isShowStickyMenu",
                  },
                ],
                staticClass:
                  "absolute top-20 rounded-md bg-boxed py-3 text-xs uppercase flex-col w-full sm:w-auto",
                class: [
                  _vm.stickyState
                    ? "xl:left-[calc(((var(--screen-width)-(var(--screen-width)-100%))-var(--current-screen))/2)]"
                    : "",
                ],
                attrs: { id: "sticky-menu" },
              },
              _vm._l(_vm.navMain.children, function (item, key) {
                return _c(
                  "li",
                  { key: key, staticClass: "relative" },
                  [
                    _c(
                      "nuxt-link",
                      {
                        staticClass:
                          "font-medium lg:text-xxs xl:text-xs text-white pl-5 pr-6 transition-all cursor-pointer hover:text-primary h-10 flex items-center",
                        attrs: {
                          to: item.link,
                          "active-class": "bg-grey border-l-4 border-primary",
                        },
                      },
                      [_c("span", [_vm._v(_vm._s(item.title))])]
                    ),
                  ],
                  1
                )
              }),
              0
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }