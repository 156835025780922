var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "mt-4 mb-8" }, [
      _c(
        "button",
        {
          staticClass:
            "md:hidden text-sm bg-primary text-white w-full py-2 rounded-lg uppercase btn-dropdown-toggle transition-all",
          class: _vm.showDropdown ? "open rounded-b-none" : "",
          on: { click: _vm.toggleDropdown },
        },
        [
          _c(
            "span",
            {
              staticClass:
                "flex flex-row justify-between pl-3 !pr-5 items-baseline",
            },
            [_vm._v("\n        " + _vm._s(_vm.$t("filter.show")) + "\n      ")]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.categories
        ? _c(
            "nav",
            {
              staticClass:
                "bg-white md:max-h-max rounded-b-lg md:rounded-t-lg transition-all overflow-hidden mb-16",
              class: _vm.showDropdown
                ? "max-h-screen p-3 pb-px overflow-y-scroll"
                : "max-h-0",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "md:m-3 md:mb-px md:flex md:flex-row md:justify-between",
                },
                [
                  _vm.normalizedCategories
                    ? _c(
                        "div",
                        { staticClass: "md:w-[75%] lg:w-[83%]" },
                        _vm._l(_vm.normalizedCategories, function (item, key) {
                          return _c(
                            "button",
                            {
                              key: key,
                              staticClass:
                                "btn-pill block w-full mb-3 md:w-auto md:inline-block md:mr-3",
                              class: _vm.filter.includes(item) ? "active" : "",
                              on: {
                                click: function ($event) {
                                  return _vm.handleFilter(item)
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "remove" }),
                              _vm._v(_vm._s(item) + "\n          "),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "transition",
                    {
                      attrs: {
                        "enter-active-class":
                          "duration-300 ease-out opacity-0 -translate-y-full md:translate-y-0 md:translate-x-full",
                        "enter-to-class":
                          "opacity-100 translate-y-0 md:translate-x-0",
                        "leave-active-class": "duration-200 ease-in",
                        "leave-class":
                          "opacity-100 translate-y-0 md:translate-x-0",
                        "leave-to-class":
                          "opacity-0 -translate-y-full md:translate-y-0 md:translate-x-full",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.filter.length > 0,
                              expression: "filter.length > 0",
                            },
                          ],
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn-pill btn-pill--remove mb-3 block w-full md:inline-block md:w-auto whitespace-nowrap self-end",
                              on: { click: _vm.resetFilter },
                            },
                            [
                              _c("span", { staticClass: "remove" }),
                              _vm._v(
                                _vm._s(_vm.$t("filter.showAll")) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }