var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "flex relative flex-wrap justify-between items-center xl:container z-[1000]",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "flex flex-col content-center pt-2 mt-8 w-full text-center lg:flex-row lg:justify-between lg:items-end lg:text-left",
        },
        [
          _c(
            "nuxt-link",
            {
              attrs: { to: _vm.navMain.link },
              nativeOn: {
                click: function ($event) {
                  return _vm.closeNav.apply(null, arguments)
                },
              },
            },
            [
              _c("svg-logo", {
                staticClass: "m-4 lg:ml-16 h-8 lg:mt-0 xl:ml-0",
              }),
              _vm._v(" "),
              _c("span", { staticClass: "sr-only" }, [
                _vm._v("sure[secure] Home"),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm.navMain && _vm.navMain.children
            ? _c("layout-main-nav", { attrs: { "nav-main": _vm.navMain } })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }