<template>
  <div class="text-center lg:text-right when-nested">
    <a v-if="backgroundImage.length > 0 && hideLink === 0" :href="backgroundImage[0].publicUrl" target="_blank" class="corner relative inline-block text-left hover:scale-105 transition-all hover:shadow-xl">
      <span class="badge">PDF</span>
      <div class="absolute inset-x-0 bottom-0 px-5 py-10">
        <p class="h5 text-white font-medium text-md">{{ title }}</p>
        <p class="text-white text-sm">{{ generalText }}</p>
      </div>
      <div class="content-pdf-download-bg max-w-full" />
    </a>
    <div v-else class="corner relative inline-block text-left">
      <span class="badge">PDF</span>
      <div class="absolute inset-x-0 bottom-0 px-5 py-10">
        <p class="h5 text-white font-medium text-md">{{ title }}</p>
        <p class="text-white text-sm">{{ generalText }}</p>
      </div>
      <div class="content-pdf-download-bg max-w-full" />
    </div>
  </div>
</template>
<script>
import baseCe from '../mixins/baseCe'
export default {
  name: 'T3CeSuresecurePdfDownload',
  extends: baseCe,
  props: {
    hideLink: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: ''
    },
    generalText: {
      type: String,
      default: ''
    },
    backgroundImage: {
      type: Array,
      default: () => []
    },
  }
}
</script>
<style lang="postcss" scoped>
.badge {
  @apply text-white bg-primary absolute z-20 top-7 left-0 rounded-[3px] font-medium px-5 text-[12px] -translate-x-1/3;
}
.corner {
  @apply relative;
  &:before {
    @apply content-[''] block absolute top-0 right-0 z-10 bg-secondary;
    border-top: 40px solid transparent;
    border-left: 40px solid #607373;
  }
}
.frame-background-light {
  .corner {
    &:before {
      background-color: rgb(244,244,255);
    }
  }
}
.bg-white {
  .corner {
    &:before {
      background-color: #ffffff;
    }
  }
}
</style>