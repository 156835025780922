var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "bg-boxed rounded-lg h-full px-4 sm:px-6 py-6 sm:py-10 flex hover:pr-5 duration-300",
    },
    [
      _vm.teaserboxItem.content.backgroundImage[0]
        ? _c("div", { staticClass: "relative" }, [
            _c(
              "div",
              {
                staticClass:
                  "bg-white/10 w-11 h-11 lg:w-20 lg:h-20 rounded-full relative",
              },
              [
                _vm.teaserboxItem.content.backgroundImage[0]
                  ? _c("img", {
                      directives: [
                        { name: "lazy-load", rawName: "v-lazy-load" },
                      ],
                      staticClass:
                        "absolute z-10 left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 w-full max-w-[30px] lg:max-w-[60px] lg:max-h-[60px]",
                      attrs: {
                        "data-src":
                          _vm.teaserboxItem.content.backgroundImage[0]
                            .publicUrl,
                        alt: _vm.teaserboxItem.content.header,
                      },
                    })
                  : _vm._e(),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "ml-6 pt-2 sm:pt-3 w-full" },
        [
          _c(
            "p",
            {
              staticClass: "text-white sm:text-lg font-bold mb-2 sm:mb-4 mr-5",
              staticStyle: { "word-break": "break-all" },
            },
            [_vm._v(_vm._s(_vm.teaserboxItem.content.header))]
          ),
          _vm._v(" "),
          _c("t3-html-parser", {
            staticClass: "inline-quinary",
            attrs: { content: _vm.teaserboxItem.content.bodytext },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }