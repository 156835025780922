<template>
  <div>
    <div
      class="inline-block relative text-left text-gray-800"
      @mouseenter="isLangMenuOpen = true"
      @mouseleave="isLangMenuOpen = false"
    >
      <div>
        <button
          type="button"
          class="inline-flex text-xs font-medium text-white focus:outline-none lg:text-sm btn-dropdown-toggle"
          :class="isLangMenuOpen ? 'open' : ''"
          aria-haspopup="true"
          aria-expanded="true"
          aria-label="Change Language"
        >
          <span class="uppercase"> {{ current }} </span>
        </button>
      </div>
      <transition
        enter-active-class="transition ease-out duration-100"
        enter-class="opacity-0 transform scale-95"
        enter-to-class="opacity-100 transform scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-class="opacity-100 transform scale-100"
        leave-to-class="opacity-0 transform scale-95"
      >
        <div
          v-if="isLangMenuOpen"
          class="overflow-hidden absolute -inset-x-2 z-20 text-sm text-black rounded-md shadow-lg origin-top-left"
        >
          <div
            class="bg-white rounded-md shadow-xs"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            <div>
              <ul class="p-2 text-xs lg:text-sm font-medium">
                <li v-for="(locale, index) in available" :key="index">
                  <nuxt-link
                    :to="locale.link"
                    class="uppercase text-black"
                    @click.native="closeLangMenu"
                  >
                    {{ locale.twoLetterIsoCode }}
                  </nuxt-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'LangSwitcher',
  data() {
    return {
      isLangMenuOpen: false,
    }
  },
  computed: {
    ...mapState({
      current: (state) => state.typo3.locale,
      available: (state) => state.typo3.initial.i18n,
    }),
  },
  methods: {
    closeLangMenu() {
      this.isLangMenuOpen = false
      this.$nuxt.$emit('close-nav')
    },
  },
}
</script>
