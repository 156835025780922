var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "relative xl:container" }, [
    _c(
      "div",
      {
        staticClass:
          "flex absolute top-1 left-3 z-[1001] flex-row items-center pt-3 text-xs rounded-b-lg transition-all lg:top-[-5px] lg:right-16 xl:right-0 lg:left-auto lg:px-3 lg:pt-6 lg:pb-3 lg:text-base lg:bg-white/10 quick-menu",
      },
      [
        _c("layout-lang-switcher", { staticClass: "mr-4" }),
        _vm._v(" "),
        _c(
          "nuxt-link",
          {
            staticClass:
              "hidden py-[3px] px-5 text-xs font-medium text-white uppercase rounded-full lg:inline-block bg-primary hover:scale-105 transition-all",
            attrs: { to: _vm.contactLink },
          },
          [_vm._v(_vm._s(_vm.$t("contact")))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }