export { default as T3Form } from '../../node_modules/nuxt-typo3/lib/components/T3Form/T3Form.vue'
export { default as T3CeFormFormframework } from '../../node_modules/nuxt-typo3/lib/components/T3CeFormFormframework/T3CeFormFormframework.vue'
export { default as T3CeFeloginLogin } from '../../node_modules/nuxt-typo3/lib/components/T3CeFeloginLogin/T3CeFeloginLogin.vue'
export { default as LayoutFooterContent } from '../../components/layout/FooterContent.vue'
export { default as LayoutFooterMetaNav } from '../../components/layout/FooterMetaNav.vue'
export { default as LayoutIncidentResponse } from '../../components/layout/IncidentResponse.vue'
export { default as LayoutLangSwitcher } from '../../components/layout/LangSwitcher.vue'
export { default as LayoutMainHeader } from '../../components/layout/MainHeader.vue'
export { default as LayoutQuickMenu } from '../../components/layout/QuickMenu.vue'
export { default as LayoutToTop } from '../../components/layout/ToTop.vue'
export { default as UtilitiesT3HtmlParser } from '../../components/utilities/T3HtmlParser.vue'
export { default as ContentT3Frame } from '../../components/content/T3Frame.js'
export { default as ContentT3MediaFile } from '../../components/content/T3MediaFile.vue'
export { default as ContentT3MediaGallery } from '../../components/content/T3MediaGallery.vue'
export { default as ContentT3Renderer } from '../../components/content/T3Renderer.js'
export { default as LayoutFooterBlock } from '../../components/layout/FooterBlock/FooterBlock.vue'
export { default as LayoutFooterBlockItem } from '../../components/layout/FooterBlock/FooterBlockItem.vue'
export { default as LayoutMainNav } from '../../components/layout/MainNav/MainNav.vue'
export { default as LayoutMainNavItem } from '../../components/layout/MainNav/MainNavItem.vue'
export { default as LayoutNestedMenu } from '../../components/layout/NestedMenu/NestedMenu.vue'
export { default as LayoutNestedMenuPanel } from '../../components/layout/NestedMenu/NestedMenuPanel.vue'
export { default as ContentElementsT3CeHeader } from '../../components/content/elements/T3CeHeader.vue'
export { default as ContentElementsT3CeHtml } from '../../components/content/elements/T3CeHtml.vue'
export { default as ContentElementsT3CeImage } from '../../components/content/elements/T3CeImage.vue'
export { default as ContentElementsT3CeMenuPages } from '../../components/content/elements/T3CeMenuPages.vue'
export { default as ContentElementsT3CeSuresecureAccordion } from '../../components/content/elements/T3CeSuresecureAccordion.vue'
export { default as ContentElementsT3CeSuresecureBenefitCommunication } from '../../components/content/elements/T3CeSuresecureBenefitCommunication.vue'
export { default as ContentElementsT3CeSuresecureBlogList } from '../../components/content/elements/T3CeSuresecureBlogList.vue'
export { default as ContentElementsT3CeSuresecureCarousel } from '../../components/content/elements/T3CeSuresecureCarousel.vue'
export { default as ContentElementsT3CeSuresecureColsEightyTwenty } from '../../components/content/elements/T3CeSuresecureColsEightyTwenty.vue'
export { default as ContentElementsT3CeSuresecureColsTwentyEighty } from '../../components/content/elements/T3CeSuresecureColsTwentyEighty.vue'
export { default as ContentElementsT3CeSuresecureContactPerson } from '../../components/content/elements/T3CeSuresecureContactPerson.vue'
export { default as ContentElementsT3CeSuresecureEmergency } from '../../components/content/elements/T3CeSuresecureEmergency.vue'
export { default as ContentElementsT3CeSuresecureEventList } from '../../components/content/elements/T3CeSuresecureEventList.vue'
export { default as ContentElementsT3CeSuresecureFlow } from '../../components/content/elements/T3CeSuresecureFlow.vue'
export { default as ContentElementsT3CeSuresecureForms } from '../../components/content/elements/T3CeSuresecureForms.vue'
export { default as ContentElementsT3CeSuresecureHero } from '../../components/content/elements/T3CeSuresecureHero.vue'
export { default as ContentElementsT3CeSuresecureNewsList } from '../../components/content/elements/T3CeSuresecureNewsList.vue'
export { default as ContentElementsT3CeSuresecurePdfDownload } from '../../components/content/elements/T3CeSuresecurePdfDownload.vue'
export { default as ContentElementsT3CeSuresecurePodcastList } from '../../components/content/elements/T3CeSuresecurePodcastList.vue'
export { default as ContentElementsT3CeSuresecurePriceBox } from '../../components/content/elements/T3CeSuresecurePriceBox.vue'
export { default as ContentElementsT3CeSuresecureQuote } from '../../components/content/elements/T3CeSuresecureQuote.vue'
export { default as ContentElementsT3CeSuresecureSocialIcon } from '../../components/content/elements/T3CeSuresecureSocialIcon.vue'
export { default as ContentElementsT3CeSuresecureTab } from '../../components/content/elements/T3CeSuresecureTab.vue'
export { default as ContentElementsT3CeSuresecureTeaserBox } from '../../components/content/elements/T3CeSuresecureTeaserBox.vue'
export { default as ContentElementsT3CeSuresecureTeaserSelectedPages } from '../../components/content/elements/T3CeSuresecureTeaserSelectedPages.vue'
export { default as ContentElementsT3CeSuresecureTeaserSubpages } from '../../components/content/elements/T3CeSuresecureTeaserSubpages.vue'
export { default as ContentElementsT3CeSuresecureTechnoStack } from '../../components/content/elements/T3CeSuresecureTechnoStack.vue'
export { default as ContentElementsT3CeSuresecurecareerJoboffercontact } from '../../components/content/elements/T3CeSuresecurecareerJoboffercontact.vue'
export { default as ContentElementsT3CeSuresecurecareerJobofferdetails } from '../../components/content/elements/T3CeSuresecurecareerJobofferdetails.vue'
export { default as ContentElementsT3CeSuresecurecareerJobofferlist } from '../../components/content/elements/T3CeSuresecurecareerJobofferlist.vue'
export { default as ContentElementsT3CeSuresecurequestionaryCatalogdetails } from '../../components/content/elements/T3CeSuresecurequestionaryCatalogdetails.vue'
export { default as ContentElementsT3CeText } from '../../components/content/elements/T3CeText.vue'
export { default as ContentElementsT3CeTextmedia } from '../../components/content/elements/T3CeTextmedia.vue'
export { default as ContentElementsT3CeTextpic } from '../../components/content/elements/T3CeTextpic.vue'
export { default as ContentElementsT3CeUploads } from '../../components/content/elements/T3CeUploads.vue'
export { default as ContentMixinsBaseCe } from '../../components/content/mixins/baseCe.js'
export { default as ContentMixinsBaseCePaginated } from '../../components/content/mixins/baseCePaginated.vue'
export { default as ContentMixinsBaseNews } from '../../components/content/mixins/baseNews.js'
export { default as ContentMixinsShareProps } from '../../components/content/mixins/shareProps.js'
export { default as ContentPartialsAccordionItem } from '../../components/content/partials/AccordionItem.vue'
export { default as ContentPartialsCarouselItem } from '../../components/content/partials/CarouselItem.vue'
export { default as ContentPartialsInlineSvg } from '../../components/content/partials/InlineSvg.vue'
export { default as ContentPartialsPriceBoxItem } from '../../components/content/partials/PriceBoxItem.vue'
export { default as ContentPartialsTabItem } from '../../components/content/partials/TabItem.vue'
export { default as ContentPartialsTeaserBoxItem } from '../../components/content/partials/TeaserBoxItem.vue'
export { default as ContentPartialsFormsConferenceItem } from '../../components/content/partials/Forms/FormsConferenceItem.vue'
export { default as ContentPartialsFormsContactItem } from '../../components/content/partials/Forms/FormsContactItem.vue'
export { default as ContentPartialsFormsDownloadItem } from '../../components/content/partials/Forms/FormsDownloadItem.vue'
export { default as ContentPartialsFormsEventItem } from '../../components/content/partials/Forms/FormsEventItem.vue'
export { default as ContentPartialsFormsNewsletterItem } from '../../components/content/partials/Forms/FormsNewsletterItem.vue'
export { default as ContentPartialsJoboffersCategories } from '../../components/content/partials/Joboffers/Categories.vue'
export { default as ContentPartialsT3MediaAudio } from '../../components/content/partials/T3Media/Audio.vue'
export { default as ContentPartialsT3MediaImage } from '../../components/content/partials/T3Media/Image.vue'
export { default as ContentPartialsT3MediaVideo } from '../../components/content/partials/T3Media/Video.vue'
export { default as ContentPartialsT3MediaVimeo } from '../../components/content/partials/T3Media/Vimeo.vue'
export { default as ContentPartialsT3MediaYoutube } from '../../components/content/partials/T3Media/Youtube.vue'
export { default as ContentPartialsNewsBlogItem } from '../../components/content/partials/News/BlogItem.vue'
export { default as ContentPartialsNewsCategoryBadge } from '../../components/content/partials/News/CategoryBadge.vue'
export { default as ContentPartialsNewsEventItem } from '../../components/content/partials/News/EventItem.vue'
export { default as ContentPartialsNewsCategories } from '../../components/content/partials/News/NewsCategories.vue'
export { default as ContentPartialsNewsPodcastItem } from '../../components/content/partials/News/PodcastItem.vue'
export { default as ContentPartialsNewsPodcastNewsListItem } from '../../components/content/partials/News/PodcastNewsListItem.vue'

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
