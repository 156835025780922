var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "relative" },
        [
          _c(
            "t3-ce-header",
            _vm._b({ staticClass: "mb-20" }, "t3-ce-header", _vm.$props, false)
          ),
          _vm._v(" "),
          _vm.link
            ? _c(
                "a",
                {
                  staticClass:
                    "link link-arrow text-primary absolute lg:right-0 -bottom-8 lg:bottom-0",
                  attrs: { to: _vm.link },
                },
                [
                  _vm.buttonText
                    ? [
                        _vm._v(
                          "\n        " + _vm._s(_vm.buttonText) + "\n      "
                        ),
                      ]
                    : [
                        _vm._v(
                          "\n        " + _vm._s(_vm.$t("readMore")) + "\n      "
                        ),
                      ],
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.window.width < "1024"
        ? [
            _c("div", { staticClass: "text-right mb-3" }, [
              _c(
                "span",
                {
                  staticClass:
                    "cursor-pointer w-10 h-10 inline-block rotate-180 hover:bg-grey/20 rounded-full",
                  on: { click: _vm.swipeLeft },
                },
                [_c("IconArrowForward")],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass:
                    "cursor-pointer w-10 h-10 inline-block hover:bg-grey/20 rounded-full",
                  on: { click: _vm.swipeRight },
                },
                [_c("IconArrowForward")],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                ref: "snap",
                staticClass:
                  "flex gap-5 md:gap-10 pb-12 overflow-x-auto snap-x snap-mandatory hide-scrollbar",
                class: _vm.tabLayout,
                attrs: { role: "tablist" },
              },
              _vm._l(_vm.tabItems, function (tab, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    ref: "items",
                    refInFor: true,
                    staticClass:
                      "rounded-lg bg-boxed flex items-stretch snap-start shrink-0",
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass:
                          "relative text-center px-3 sm:px-5 py-2 sm:py-3 horizontal flex items-center",
                        class: _vm.active_tab === index ? "is-active" : "",
                        attrs: {
                          href: "javascript:void(0);",
                          role: "tab",
                          "aria-selected": _vm.active_tab === index,
                          "aria-controls": "tab-" + _vm.id + "-" + index,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.changeTab(index)
                          },
                        },
                      },
                      [
                        tab.content.backgroundImage[0]
                          ? _c("img", {
                              directives: [
                                { name: "lazy-load", rawName: "v-lazy-load" },
                              ],
                              staticClass:
                                "transition-all duration-300 max-h-[60px] w-auto",
                              class: {
                                "grayscale-0 opacity-100":
                                  _vm.active_tab === index,
                                "grayscale opacity-60": !(
                                  _vm.active_tab === index
                                ),
                              },
                              attrs: {
                                "data-src":
                                  tab.content.backgroundImage[0].publicUrl,
                                alt: tab.content.header,
                                width:
                                  tab.content.backgroundImage[0].properties
                                    .dimensions.width,
                                height:
                                  tab.content.backgroundImage[0].properties
                                    .dimensions.height,
                              },
                            })
                          : _c("t3-html-parser", {
                              staticClass:
                                "mx-auto font-bold text-[14px] leading-[25px] uppercase",
                              attrs: { content: tab.content.header },
                            }),
                      ],
                      1
                    ),
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _vm._l(_vm.tabItems, function (tab, index) {
              return _c("TabItem", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.active_tab === index,
                    expression: "active_tab === index",
                  },
                ],
                key: index,
                staticClass:
                  "bg-white mx-[calc((var(--screen-width)-var(--current-screen))/-2-1rem)] px-[calc((var(--screen-width)-var(--current-screen))/2+.75rem)]",
                attrs: {
                  id: "tab-" + _vm.id + "-" + index,
                  "aria-current": _vm.active_tab === index,
                  tab: tab,
                },
              })
            }),
          ]
        : _vm.tabLayout === "horizontal"
        ? [
            _c(
              "div",
              {
                staticClass: "flex gap-5 md:gap-10 mb-12",
                class: _vm.tabLayout,
                attrs: { role: "tablist" },
              },
              _vm._l(_vm.tabItems, function (tab, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "rounded-lg bg-boxed flex items-stretch",
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass:
                          "relative text-center px-3 sm:px-5 py-2 sm:py-3 horizontal flex items-center",
                        class: _vm.active_tab === index ? "is-active" : "",
                        attrs: {
                          href: "javascript:void(0);",
                          role: "tab",
                          "aria-selected": _vm.active_tab === index,
                          "aria-controls": "tab-" + _vm.id + "-" + index,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.changeTab(index)
                          },
                        },
                      },
                      [
                        tab.content.backgroundImage[0]
                          ? _c("img", {
                              directives: [
                                { name: "lazy-load", rawName: "v-lazy-load" },
                              ],
                              staticClass:
                                "transition-all duration-300 max-h-[100px] w-auto",
                              class: {
                                "grayscale-0 opacity-100":
                                  _vm.active_tab === index,
                                "grayscale opacity-60": !(
                                  _vm.active_tab === index
                                ),
                              },
                              attrs: {
                                "data-src":
                                  tab.content.backgroundImage[0].publicUrl,
                                alt: tab.content.header,
                                width:
                                  tab.content.backgroundImage[0].properties
                                    .dimensions.width,
                                height:
                                  tab.content.backgroundImage[0].properties
                                    .dimensions.height,
                              },
                            })
                          : _c("t3-html-parser", {
                              staticClass:
                                "mx-auto font-bold text-[14px] leading-[25px] uppercase",
                              attrs: { content: tab.content.header },
                            }),
                      ],
                      1
                    ),
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _vm._l(_vm.tabItems, function (tab, index) {
              return _c("TabItem", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.active_tab === index,
                    expression: "active_tab === index",
                  },
                ],
                key: index,
                staticClass:
                  "bg-white mx-[calc((var(--screen-width)-var(--current-screen))/-2-1rem)] px-[calc((var(--screen-width)-var(--current-screen))/2+.75rem)]",
                attrs: {
                  id: "tab-" + _vm.id + "-" + index,
                  "aria-current": _vm.active_tab === index,
                  tab: tab,
                },
              })
            }),
          ]
        : [
            _c(
              "div",
              {
                staticClass: "flex mb-12 gap-4 items-stretch",
                class: _vm.tabLayout,
                attrs: { role: "tablist" },
              },
              [
                _c(
                  "div",
                  { staticClass: "shrink-0 max-w-[120px] lg:max-w-[240px]" },
                  _vm._l(_vm.tabItems, function (tab, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass:
                          "flex items-center mb-4 last:mb-0 rounded-lg bg-boxed",
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass:
                              "relative text-center px-3 sm:px-5 py-2 sm:py-3 block vertical w-full",
                            class: _vm.active_tab === index ? "is-active" : "",
                            attrs: {
                              href: "javascript:void(0);",
                              role: "tab",
                              "aria-selected": _vm.active_tab === index,
                              "aria-controls": "tab-" + _vm.id + "-" + index,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.changeTab(index)
                              },
                            },
                          },
                          [
                            tab.content.backgroundImage[0]
                              ? _c("img", {
                                  directives: [
                                    {
                                      name: "lazy-load",
                                      rawName: "v-lazy-load",
                                    },
                                  ],
                                  staticClass:
                                    "transition-all duration-300 max-h-[100px]",
                                  class: {
                                    "grayscale-0 opacity-100":
                                      _vm.active_tab === index,
                                    "grayscale opacity-60": !(
                                      _vm.active_tab === index
                                    ),
                                  },
                                  attrs: {
                                    "data-src":
                                      tab.content.backgroundImage[0].publicUrl,
                                    alt: tab.content.header,
                                    width:
                                      tab.content.backgroundImage[0].properties
                                        .dimensions.width,
                                    height:
                                      tab.content.backgroundImage[0].properties
                                        .dimensions.height,
                                  },
                                })
                              : _c("t3-html-parser", {
                                  staticClass:
                                    "font-bold text-[14px] leading-[25px] uppercase",
                                  attrs: { content: tab.content.header },
                                }),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "w-full" },
                  _vm._l(_vm.tabItems, function (tab, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        class: _vm.active_tab === index ? "h-full" : "",
                      },
                      [
                        _c("TabItem", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.active_tab === index,
                              expression: "active_tab === index",
                            },
                          ],
                          key: index,
                          staticClass: "bg-white px-8 py-4 rounded-lg h-full",
                          attrs: {
                            id: "tab-" + _vm.id + "-" + index,
                            "aria-current": _vm.active_tab === index,
                            tab: tab,
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }